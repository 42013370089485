/* 
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withLoading } from './../hocs/withLoading.hoc';
import LoginPage from './../../pages/LoginPage';
import RequireAuth from './RequireAuth';
import MainLayout from './../../components/layouts/main/MainLayout/MainLayout';

const AuthLayout = React.lazy(() => import('./../../components/layouts/AuthLayout/AuthLayout'));
/* const AdvancedFormsPage = React.lazy(() => import('./../../pages/AdvancedFormsPage')); 
export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const Logout = React.lazy(() => import('./Logout'));
//import RequireAuth from './../../components/router/RequireAuth';


const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
/* const NftDashboard = withLoading(NftDashboardPage);
const MedicalDashboard = withLoading(MedicalDashboardPage);
const AdvancedForm = withLoading(AdvancedFormsPage); 

export const AppRouter: React.FC = () => {
     const protectedLayout = (
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    ); 
  
    return (
      <>
    
     KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK
     </>
    );
  }; */



import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withLoading } from './../hocs/withLoading.hoc';


import LoginPage from './../../pages/LoginPage';
import SignUpPage from './../../pages/SignUpPage';

import ForgotPasswordPage from './../../pages/ForgotPasswordPage';
import SecurityCodePage from './../../pages/SecurityCodePage';
import NewPasswordPage from './../../pages/NewPasswordPage';
import LockPage from './../../pages/LockPage';

//import SignUpPage from './../../pages/SignUpPage';
import RequireAuth from './RequireAuth';
import MainLayout from './../../components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from './../../components/profile/ProfileLayout';

import NftDashboardPage from './../../pages/DashboardPages/NftDashboardPage';
import PersonalInfoPage from '../../pages/PersonalInfoPage';
//import ServiceCardListPage from '../../pages/ServiceCardListPage';
import SecuritySettingsPage from '../../pages/SecuritySettingsPage';
import NotificationsPage from '../../pages/NotificationsPage';
import PaymentsPage from '../../pages/PaymentsPage';

import ServiceVendorList from '../../pages/ServicevendorList';

import Vendor from '../../pages/vendor';

import EventDealList from '../../pages/EventPages/EventDealList';
import { PrivacyForm } from '../PrivacyAgree/PrivacyForm';
import DealDashboardPage from '../../pages/DashboardPages/DealDashboardPage';
import SettingLayout from '../setting/SettingLayout';
import DiscountInputPage from '../../pages/DiscountInputPage';
import DiscountListPage from '../../pages/DiscountListPage';


// import NftDashboardPage from './../../pages/DashboardPages/NftDashboardPage';
//import MedicalDashboardPage from './../../pages/DashboardPages/MedicalDashboardPage'; 

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('./../../components/layouts/AuthLayout/AuthLayout'));
/* const AdvancedFormsPage = React.lazy(() => import('./../../pages/AdvancedFormsPage')); */
export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const Logout = React.lazy(() => import('./Logout'));
//import RequireAuth from './../../components/router/RequireAuth';


const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
const NftDashboard = withLoading(NftDashboardPage);

const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

/* 
const MedicalDashboard = withLoading(MedicalDashboardPage);
const AdvancedForm = withLoading(AdvancedFormsPage); */

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <>

      <BrowserRouter>
        <Routes>


          <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>           
            <Route index element={<NftDashboard />} />

            {/* <Route path="deals/:type" element={<DealDashboardPage />} /> */}


            <Route path="deals" >
               <Route  path="deal-resturant/:type" element={<ServiceVendorList />} /> 
            </Route>


             <Route path="profile" element={<ProfileLayout />}>
              <Route path="personal-info" element={<PersonalInfoPage />} />
              <Route path="security-settings" element={<SecuritySettings />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="payments" element={<Payments />} />
            </Route>


            <Route path="setting" element={<SettingLayout />}>
              <Route path="discount-info" element={<DiscountInputPage />} />
              <Route path="discount-list" element={<DiscountListPage />} />              
            </Route>
          

            <Route path="households" >
               <Route  path="landscaping/:type" element={<ServiceVendorList />} />
               <Route  path="housecleaning/:type" element={<ServiceVendorList />} />
               <Route  path="painting/:type" element={<ServiceVendorList />} />
               <Route  path="roofing/:type" element={<ServiceVendorList />} />
               <Route  path="electrician/:type" element={<ServiceVendorList />} />
               <Route  path="hvacheatingcooling/:type" element={<ServiceVendorList />} />
               <Route  path="plumbing/:type" element={<ServiceVendorList />} />
               <Route  path="generalcontractor/:type" element={<ServiceVendorList />} />   
               <Route  path="holidaylights/:type" element={<ServiceVendorList />} />   
               <Route  path="hardwoodfloors/:type" element={<ServiceVendorList />} />   
               <Route  path="fenceinstallrepair/:type" element={<ServiceVendorList />} />
               <Route  path="window/:type" element={<ServiceVendorList />} />
               <Route  path="handyman/:type" element={<ServiceVendorList />} />
               <Route  path="pestcontrol/:type" element={<ServiceVendorList />} />
               <Route  path="garagedoorrepair/:type" element={<ServiceVendorList />} />  
               <Route  path="rosoftener/:type" element={<ServiceVendorList />} /> 
               <Route  path="homeinspection/:type" element={<ServiceVendorList />} /> 
               <Route  path="waterfilter/:type" element={<ServiceVendorList />} /> 
               <Route  path="appliances/:type" element={<ServiceVendorList />} /> 
               <Route  path="treecutting/:type" element={<ServiceVendorList />} /> 
               <Route  path="locksmith/:type" element={<ServiceVendorList />} /> 
               <Route  path="movers/:type" element={<ServiceVendorList />} /> 
               <Route  path="blinds/:type" element={<ServiceVendorList />} /> 
               <Route  path="accentwall/:type" element={<ServiceVendorList />} />     
               <Route  path="walldecors/:type" element={<ServiceVendorList />} /> 
                          
            </Route>
            
            
            <Route path="insurance" >
            <Route  path="homeinsurance/:type" element={<ServiceVendorList />} /> 
            <Route  path="petinsurance/:type" element={<ServiceVendorList />} /> 
            <Route  path="lifeinsurance/:type" element={<ServiceVendorList />} /> 
            <Route  path="autoinsurance/:type" element={<ServiceVendorList />} /> 
            <Route  path="visitorinsurance/:type" element={<ServiceVendorList />} />
            </Route>


            <Route path="restaurants" >
            <Route  path="banquet/:type" element={<ServiceVendorList />} /> 
            <Route  path="catering/:type" element={<ServiceVendorList />} />
            <Route  path="indian/:type" element={<ServiceVendorList />} />
            <Route  path="thai/:type" element={<ServiceVendorList />} />
            </Route>

            
            <Route path="legal" >
            <Route  path="lawyer/:type" element={<ServiceVendorList />} /> 
            <Route  path="notary/:type" element={<ServiceVendorList />} />   
            <Route  path="cpa/:type" element={<ServiceVendorList />} />    
           
            </Route>


            <Route path="auto" >
              <Route  path="repairservice/:type" element={<ServiceVendorList />} />             
            </Route>

            
            <Route path="healthcare" >
               <Route  path="primarycare/:type" element={<ServiceVendorList />} />
               <Route  path="urgentcare/:type" element={<ServiceVendorList />} />
               <Route  path="pediatrician/:type" element={<ServiceVendorList />} />
               <Route  path="dentalcare/:type" element={<ServiceVendorList />} />
               <Route  path="eyecare/:type" element={<ServiceVendorList />} />
               <Route  path="cyropatic/:type" element={<ServiceVendorList />} />
               <Route  path="gynecologist/:type" element={<ServiceVendorList />} />
               <Route  path="petcare/:type" element={<ServiceVendorList />} />
               <Route  path="generalphysician/:type" element={<ServiceVendorList />} />
               <Route  path="orthopedic/:type" element={<ServiceVendorList />} />
            </Route>

            <Route path="sports" >
               <Route  path="tennis/:type" element={<ServiceVendorList />} />
               <Route  path="badminton/:type" element={<ServiceVendorList />} />
               <Route  path="soccer/:type" element={<ServiceVendorList />} />
          
            </Route>

            <Route path="entertainment" >
               <Route  path="iptv/:type" element={<ServiceVendorList />} />
               <Route  path="mediaroom/:type" element={<ServiceVendorList />} />
               <Route  path="soccer/:type" element={<ServiceVendorList />} />          
            </Route>
            
            <Route path="internet" > 
               <Route  path="spectrun/:type" element={<ServiceVendorList />} />
               <Route  path="att/:type" element={<ServiceVendorList />} />         
            </Route>

            <Route path="beautyspa" > 
               <Route  path="beauty/:type" element={<ServiceVendorList />} />
               <Route  path="spa/:type" element={<ServiceVendorList />} />         
            </Route>

            <Route path="warranty" >
               <Route  path="list/:type" element={<ServiceVendorList />} />        
            </Route>

            <Route path="privatelessons" >
               <Route  path="music/:type" element={<ServiceVendorList />} />     
               <Route  path="dance/:type" element={<ServiceVendorList />} />    
               <Route  path="swimming/:type" element={<ServiceVendorList />} /> 
            
            </Route>

            <Route path="events" >
               <Route  path="events/:type" element={<EventDealList />} />     
               <Route  path="eventtickets/:type" element={<EventDealList />} />    
               <Route  path="djlist/:type" element={<EventDealList />} />  
               <Route  path="partydecorators/:type" element={<ServiceVendorList />} />
               <Route  path="preist/:type" element={<ServiceVendorList />} />
              
            </Route>


            <Route path="realestate" >
               <Route  path="realtor/:type" element={<ServiceVendorList />} />     
            </Route>

            <Route path="homedelivery" >
               <Route  path="grocery/:type" element={<ServiceVendorList />} />     
            </Route>

            
            <Route path="deals" >
               <Route  path="lates/:type" element={<ServiceVendorList />} />     
   
            </Route>
            
            
          </Route>


          <Route path="/auth" element={ <AuthLayoutFallback />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="new-password/:token/:email" element={<NewPasswordPage />} />
            <Route
              path="lock"
              element={
                <RequireAuth>
                  <LockPage />
                </RequireAuth>
              }
            />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="security-code" element={<SecurityCodePage />} />
           {/*  <Route path="new-password" element={<NewPasswordPage />} />
            */}
          </Route>


          <Route path="/vendor" element={ <AuthLayoutFallback />}>
          <Route  path="vendorform" element={<Vendor />} />  
          <Route  path="privacy" element={<PrivacyForm />} />          
          </Route>

          <Route path="/privacy" element={ <AuthLayoutFallback />}>
          <Route  path="privacyAgree" element={<PrivacyForm />} />          
          </Route>

          

          <Route path="/logout" element={<LogoutFallback />} />
        </Routes>


         

        {/*  <Routes> 
          <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
            <Route index element={<NftDashboard />} />
            <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />

          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>

          
          </Route>

         

          <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
           {/*  <Route path="sign-up" element={<SignUpPage />} />
            <Route
              path="lock"
              element={
                <RequireAuth>
                  <LockPage />
                </RequireAuth>
              }
            />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="security-code" element={<SecurityCodePage />} />
            <Route path="new-password" element={<NewPasswordPage />} /> */}
        {/*   </Route>
          <Route path="/logout" element={<LogoutFallback />} /> */}

        {/*  </Routes>  */}
      </BrowserRouter>
    </>
  );
};