import React, { useEffect, useMemo, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '../../common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { doGuestLogin } from '../../../store/slices/authSlice';
import { notificationController } from '../../../controllers/notificationController';
import { ReactComponent as GoogleIcon } from './../../../assets/icons/google.svg';
import { ReactComponent as FacebookIcon } from './../../../assets/icons/facebook.svg';
import * as Auth from '../../layouts/AuthLayout/AuthLayout.styles';
import * as S from './GuestLoginForm.styles';
import { CommunityModel } from '../../../domain/CcommunityModel';
import { getCitiesData, getCommunityData } from '../../../api/community.api';

import { BaseSelect, Option } from '../../common/selects/BaseSelect/BaseSelect';
import { languages } from '../../../constants/languages';
import { BaseSpace } from '../../common/BaseSpace/BaseSpace';
import { getStateNames } from '../../../api/states.api';
import { getCitiNames } from '../../../api/cities.api';

interface GuestLoginFormData {
  city: string;
  state: string;
  otherCommunityName: string;
  communityCode: string;
 
}

const initValues = {

  communityCode: '',
  city: '',
  state: '',
  otherCommunityName: '',
 
 
};

export const GuestLoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const [inputValue, setInputValue] = React.useState('');
  const [codeValue, setCodeValue] = React.useState('');
  const [data, setData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [cityValue, setCityValue] = React.useState('');

  const [stateNamesData, setStateNamesData] = useState([]);
  const [stateValue, setStateValue] = React.useState('');

  const onCityChange = (value: any) => {
    setCityValue(value || '')
  }


  const onStateChange = (value: any) => {
    console.log(value)
    setStateValue(value || '')
  }

  const onTagsChange = (event: any, values: any) => {
    if (values === null) {
      // persistCommunityCode(null)
    } else {

      setInputValue(values.code);
      setCodeValue(values.code)
      //  persistCommunityCode({code: values.code, name: values.name})
    }

  }



  const { t } = useTranslation();
  const location = useLocation();

  // Get a specific query parameter
  const urlLink = new URLSearchParams(location.search).get('urlLink');
  const redirectUrl = urlLink ? ("/auth/login?urlLink="+urlLink):("/auth/login");


  const handleSubmit = (values: GuestLoginFormData) => {
    setLoading(true);
    dispatch(doGuestLogin(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.guestSuccessMessage'),
          description: t('auth.guestSuccessDescription'),
        });
        navigate(location.pathname);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      }); 
  };


  useEffect(() => {
    getStateNames()
      .then((res) => setStateNamesData(res))
      .catch((e) => notificationController.error({ message: e.message }));
  }, [stateValue]);


  useEffect(() => {
    cityValue && getCommunityData(cityValue)
      .then((res) => setData(res))
      .catch((e) => {
        console.log("empty")
      });
  }, [cityValue]);


  useEffect(() => {
    stateValue && getCitiNames(stateValue)
      .then((res) => setCityData(res))
      .catch((e) => notificationController.error({ message: e.message }));
  }, [stateValue]);



  const commDataOptions = useMemo(
    () =>
      data.map((commData: any) => {

        return (
          <Option key={commData.code} value={commData.code}>
            {t(commData?.name || '')}
          </Option>
        );
      }),
    [data, t],
  );


  return data ? (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t('Guest')}</S.Title>
        
        <Auth.FormItem
          name="state"
          label={'Select State'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormBaseSelect onChange={(e) => onStateChange(e)} >

            {stateNamesData && stateNamesData.length > 0 && stateNamesData.map((stateval: any) => (
              <Option key={stateval.stateid} value={stateval.stateid}>
                {t(stateval.statename || '')}
              </Option>
            ))}

          </Auth.FormBaseSelect>
        </Auth.FormItem>



        <Auth.FormItem
          name="city"
          label={'Select City'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormBaseSelect onChange={(e) => onCityChange(e)} >
            {cityData && cityData.length > 0 && cityData.map((cityval: any) => (
              <Option key={cityval.cityascii} value={cityval.cityascii}>
                {t(cityval.city || '')}
              </Option>
            ))}
          </Auth.FormBaseSelect>
        </Auth.FormItem>


        <Auth.FormItem
          name="communityCode"
          label={'Community'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormBaseSelect >{commDataOptions}</Auth.FormBaseSelect>
        </Auth.FormItem>

        <Auth.FormItem
          name="otherCommunityName"
          label={t('Enter your community name if you selected "Other".')}
          rules={[
            {
              required: false,
              message: t('common.requiredField'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                
                if (value === '' && getFieldValue('communityCode') === 'other-000') {
                  return Promise.reject(new Error(t('profile.nav.securitySettings.otherCommunityCode')));
                }else{
                  return Promise.resolve();
                }
                
              },
            }),
          ]}>
          <Auth.FormInput />
        </Auth.FormItem>


       
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Guest Login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
       
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('signup.alreadyHaveAccountNo')}{' '}
            <Link to="/auth/login">
              
              <Auth.SubmitButton type="primary" htmlType="button" loading={isLoading}>
               {t('common.login')} {t('common.here')}
               </Auth.SubmitButton>
            </Link>
          </Auth.Text>

         
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  ) : <></>;
};
