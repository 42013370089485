import React, { Suspense } from 'react';
//import { Loading } from './../components/common/Loading/Loading';

type ReturnType<T> = (props: T) => JSX.Element;

// eslint-disable-next-line @typescript-eslint/ban-types
//<Suspense fallback={<Loading />}>
export const withLoading = <T extends object>(Component: React.ComponentType<T>): ReturnType<T> => {
  return (props: T) => (
    <Suspense >
      <Component {...props} />
    </Suspense>
  );
};
