import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PageTitle } from './../../components/common/PageTitle/PageTitle';
import { References } from './../../components/common/References/References';
import { useResponsive } from './../../hooks/useResponsive';
//import { TrendingCreators } from './../../components/nft-dashboard/trending-creators/TrendingCreators';
import { RecentlyAddedNft } from './../../components/nft-dashboard/recently-added/RecentlyAddedNft';
//import { TrendingCollections } from './../../components/nft-dashboard/trending-collections/TrendingCollections';
import { Balance } from './../../components/nft-dashboard/Balance/Balance';
import { TotalEarning } from './../../components/nft-dashboard/totalEarning/TotalEarning';
import { ActivityStory } from './../../components/nft-dashboard/activityStory/ActivityStory';
import { RecentActivity } from './../../components/nft-dashboard/recentActivity/RecentActivity';
import * as S from './DashboardPage.styles';
import * as Z from './TrendingCollections.styles';
import { BaseRow } from './../../components/common/BaseRow/BaseRow';
import { BaseCol } from './../../components/common/BaseCol/BaseCol';
import { S3Client } from "@aws-sdk/client-s3";
import { ListBucketsCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { TrendingCollections } from '../../components/nft-dashboard/trending-collections/TrendingCollections';
import VerticalCarousel from '../../components/VerticalCarousel/VerticalCarousel';
import { BaseCarousel } from '../../components/common/BaseCarousel/Carousel';
import Slider from 'react-slick';
import { TrendingActivity, getEventsAndDeals, getTrendingActivities } from '../../api/activity.api';

import  british  from './../../assets/british.jpeg';
import { BaseModal } from '../../components/common/BaseModal/BaseModal';
import { Typography } from 'antd';

const initial = {
  file_name:"",
  location:""};

const MedicalDashboardPage: React.FC = () => {
  const { isDesktop, isMobile } = useResponsive();
  const [isMiddleModalOpen, setIsMiddleModalOpen] = useState<boolean>(false);
  const [selectDeatils, setSelectDeatils] = useState(initial);
  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);


  
  const sliderRef = useRef<Slider>(null);
  const [trending, setTrending] = useState<TrendingActivity[]>([]);
  useEffect(() => {
    getTrendingActivities().then((res) => setTrending(res));
  }, []);

  const arrList = ['http://localhost:3000/static/media/dfwdiwali.8fe7b1cb045c79bc69e0.jpg', 'https://www.servicetoget.com/static/media/dfwpongal.c0bcd0a9517ca710c63e.jpg', 'http://localhost:3000/static/media/batminton.9966b86aa87174e468a5.jpg']

  const onclickVal = (item: any) => {
    setSelectDeatils(item);
    setIsMiddleModalOpen(true);
  }

  const onclickValNew = (item: any) => {
    setSelectDeatils(item);
    setIsMiddleModalOpen(true);
  }

  const [eventsDeals, setEventsDeals] = useState<any[]>([]);

  useEffect(() => {
    getEventsAndDeals('events').then((res) => setEventsDeals(res));
  }, []);









  return (
    <>
      <PageTitle>ServiceToGet Dashboard</PageTitle>
<h1></h1>
<Typography variant="h1" component="div">
Latest Service Providers
      </Typography>
      {isDesktop && eventsDeals && eventsDeals.length > 0 && (
        <BaseRow>
          <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
            <BaseRow gutter={[60, 60]}>
              <BaseCol span={24}>
                <TrendingCollections />
              </BaseCol>
            </BaseRow>

          </S.LeftSideCol>

          <S.RightSideCol xl={8} xxl={7}>



          </S.RightSideCol>

        </BaseRow>

      )}


      {!isDesktop && eventsDeals && eventsDeals.length > 0 && (
       <div>
 <BaseCarousel
          slidesToShow={1}
          autoplay
          autoplaySpeed={2500}
          responsive={[

          ]}
        >
           <Z.CardWrapper> 
            <div onClick={() => setIsNewModalOpen(true)} style={{ marginLeft: '10px', borderRadius: '30px' }} >
              <img src={british} alt="Image 2" />
            </div> 
           </Z.CardWrapper>

           

          {eventsDeals.map((item, index) => (

            <Z.CardWrapper> <div style={{ marginLeft: '10px', borderRadius: '30px' }} onClick={() => onclickVal(item)}><img src={'https://sasvcgetprod.blob.core.windows.net/' + item.location + '/' + item.file_name} alt="Image 2" />
            </div> </Z.CardWrapper>

          ))


          }
        </BaseCarousel>


        


       </div>
 

        
      
      )}


      {/*     {isDesktop ? desktopLayout : mobileAndTabletLayout} */}

      <BaseModal
            title={'Details'}
            centered
            open={isMiddleModalOpen}
            onOk={() => setIsMiddleModalOpen(false)}
            onCancel={() => setIsMiddleModalOpen(false)}
            size="medium"
          >
       
            <p><img src={'https://sasvcgetprod.blob.core.windows.net/' + selectDeatils?.location + '/' + selectDeatils.file_name} /></p>
          </BaseModal>


          <BaseModal
            title={'Details'}
            centered
            open={isNewModalOpen}
            onOk={() => setIsNewModalOpen(false)}
            onCancel={() => setIsNewModalOpen(false)}
            size="medium"
          >
       
            <p><img src={british} /></p>
          </BaseModal>


         
    </>
  );
};

export default MedicalDashboardPage;