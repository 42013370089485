import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { BaseCarousel } from './../../components/common/BaseCarousel/Carousel';
import { ViewAll } from './../../components/nft-dashboard/common/ViewAll/ViewAll';
import { NFTCardHeader } from './../../components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { EventCard, EventCardMobile } from './EventCard';
import { useResponsive } from './../../hooks/useResponsive';
import { getEventsAndDeals, getTrendingActivities, TrendingActivity } from './../../api/activity.api';
import * as S from './TrendingCollections.styles';
import { BaseRow } from './../../components/common/BaseRow/BaseRow';
import { BaseCol } from './../../components/common/BaseCol/BaseCol';
import { BaseModal } from '../common/BaseModal/BaseModal';
import { Link, useLocation, useParams } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Paper from "@mui/material/Paper";


const initial = {
  name: "",
  file_name: "",
  category: "",
  sub_category: "",
  file_order: 0,  
  location: "",
};


export const EventCollections: React.FC = () => {
  const [trending, setTrending] = useState<TrendingActivity[]>([]);

  const [eventsDeals, setEventsDeals] = useState<any[]>([]);

  const { mobileOnly, isTablet: isTabletOrHigher } = useResponsive();
  const [isMiddleModalOpen, setIsMiddleModalOpen] = useState<boolean>(false);
  const [selectDeatils, setSelectDeatils] = useState(initial);

  const { type } = useParams() || ''; 

  const valueType = type === 'events'?'events':(type === 'deals' ?'deals':'tickets');

  useEffect(() => {
    getEventsAndDeals(valueType).then((res) => setEventsDeals(res));
  }, [valueType]);

  const { t } = useTranslation();

  const onclickVal = (item: any) => {
    setSelectDeatils(item);
    setIsMiddleModalOpen(true);
  }

  const trendingList = useMemo(() => {
    return {
      //  mobile: trending.map((item, index) => <TrendingCollection key={index} {...item} />).slice(0, 3),
      mobile: eventsDeals.map((item, index) => <div onClick={() => onclickVal(item)}><EventCardMobile key={index} {...item} />
        
        </div>),
      tablet: eventsDeals.map((item, index) => (
        <div onClick={() => onclickVal(item)} key={index}>


        </div>
      )),
    };
  }, [eventsDeals]);

  const sliderRef = useRef<Slider>(null);

  return (
    <>


      <S.SectionWrapper>
        {mobileOnly && trendingList.mobile}

        {isTabletOrHigher && (
          <div>
            <Grid item xs={12}>
              <Grid container  spacing={1}>

               


                {eventsDeals.map((item, index) => (
                  <div onClick={() => onclickVal(item)} key={index}>
                    <Grid style={{ marginBottom: '20px' }} key={2} item>
                      <S.CardWrapper>
                        <EventCard {...item} />
                      </S.CardWrapper>
                    </Grid>

                  </div>
                ))}

             




              </Grid>
            </Grid>


          </div>

        )}
      </S.SectionWrapper>

      {true && (
        <S.ViewAllWrapper>
          <BaseModal
            title={'Details'}
            centered
            open={isMiddleModalOpen}
            onOk={() => setIsMiddleModalOpen(false)}
            onCancel={() => setIsMiddleModalOpen(false)}
            size="medium"
          >

            <p><img src={ 'https://sasvcgetprod.blob.core.windows.net/'+selectDeatils?.location+'/'+selectDeatils?.file_name  } /></p>
          </BaseModal>
          {/*  <ViewAll /> */}
        </S.ViewAllWrapper>
      )}
    </>
  );
};