import React from "react";
import { render } from "react-dom";
import Styles from "./../formcomponents/styles";
import { Form, Field } from "react-final-form";
import CheckboxInput from "./../formcomponents/checkboxInput";
import RadioInput from "./../formcomponents/radioInput";
import TextInput from "./../formcomponents/textInput";
import NumberInput from "./../formcomponents/numberInput";
import TextAreaInput from "./../formcomponents/textAreaInput";
import SelectInput from "./../formcomponents/selectInput";
import MultiSelectInput from "./../formcomponents/multiSelectInput";
import MultiCheckboxInput from "./../formcomponents/multiCheckboxInput";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from './../assets/logogo.png';
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

type Stooge = "larry" | "moe" | "curly";
type StoogeProvider = "company" | "single" ;
interface Values {
  firstName?: string;
  lastName?: string;
  employed: boolean;
  favoriteColor?: string;
  toppings?: string[];
  sauces?: string[];
  stooge: Stooge;
  notes?: string;
}

const onSubmit = async (values: Values) => {
  await sleep(300);
  window.alert(JSON.stringify(values, undefined, 2));
};

const VendorForm: React.FC = (props) => (
  <div style={{width: '700px', marginTop:'400px', marginBottom:'100px'}}>
    
<Styles>
  <Box><Typography gutterBottom component="div" align="center" variant="h6">
  <img src={logo} width={150} />
                                            </Typography></Box>
  
  <div style={{margin : 'auto', display:'block'}}>         </div>
    <h1> <div style={{ fontSize: '25px', color: '#01a185', 'textDecoration':'none'}}>
            <span style={{color:'#59595b'}}>Vendor</span>Form
			</div></h1>
   {/*  <h1><img src="https://avvaitamil.org/wp-content/uploads/2020/07/cropped-avvai-logo-x250-100x96.png" /></h1> */}
   

    
    <Form
      onSubmit={onSubmit}
      initialValues={{ stooge: "larry", employed: false, submitting: false }}
      render={({ handleSubmit, form,  submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Provider Code</label>
            <Field<string>
              name="providerCode"
              component={TextInput}
              placeholder="Provider Code"
            />
          </div>
          <div>
            <label>Provider Name</label>
            <Field<string>
              name="providerName"
              component={TextInput}
              placeholder="Provider Name"
            />
          </div>
          <div>
            <label>Contact Person</label>
            <Field<string>
              name="contactPerson"
              component={TextInput}
              placeholder="Contact Person"
            />
          </div>
          <div>
            <label>Provider Type</label>
            <div>
              <label>
                <Field<StoogeProvider>
                  name="providertype"
                  component={RadioInput}
                  type="radio"
                  value="company"
                />{" "}
                Company
              </label>
              <label>
                <Field<StoogeProvider>
                  name="providertype"
                  component={RadioInput}
                  type="radio"
                  value="single"
                />{" "}
                Individual
              </label>
             
            </div>
          </div>
          <div>
            <label>Age</label>
            <Field<number>
              name="age"
              component={NumberInput}
              placeholder="Age"
            />
          </div>
          <div>
            <label>Employed</label>
            <Field<boolean>
              name="employed"
              type="checkbox"
              component={CheckboxInput}
            />
          </div>
          <div>
            <label>Favorite Color</label>
            <Field<string> name="favoriteColor" component={SelectInput}>
              <option />
              <option value="#ff0000">❤️ Red</option>
              <option value="#00ff00">💚 Green</option>
              <option value="#0000ff">💙 Blue</option>
            </Field>
          </div>
          <div>
            <label>Toppings</label>
            <Field<string[]> name="toppings" component={MultiSelectInput}>
              <option value="chicken">🐓 Chicken</option>
              <option value="ham">🐷 Ham</option>
              <option value="mushrooms">🍄 Mushrooms</option>
              <option value="cheese">🧀 Cheese</option>
              <option value="tuna">🐟 Tuna</option>
              <option value="pineapple">🍍 Pineapple</option>
            </Field>
          </div>
          <div>
            <label>Sauces</label>
            <div>
              <label>
                <Field<string>
                  name="sauces"
                  component={MultiCheckboxInput}
                  type="checkbox"
                  value="ketchup"
                />{" "}
                Ketchup
              </label>
              <label>
                <Field<string>
                  name="sauces"
                  component="input"
                  type="checkbox"
                  value="mustard"
                />{" "}
                Mustard
              </label>
              <label>
                <Field<string>
                  name="sauces"
                  component="input"
                  type="checkbox"
                  value="mayonnaise"
                />{" "}
                Mayonnaise
              </label>
              <label>
                <Field<string>
                  name="sauces"
                  component="input"
                  type="checkbox"
                  value="guacamole"
                />{" "}
                Guacamole 🥑
              </label>
            </div>
          </div>
          <div>
            <label>Best Stooge</label>
            <div>
              <label>
                <Field<Stooge>
                  name="stooge"
                  component={RadioInput}
                  type="radio"
                  value="larry"
                />{" "}
                Larry
              </label>
              <label>
                <Field<Stooge>
                  name="stooge"
                  component={RadioInput}
                  type="radio"
                  value="moe"
                />{" "}
                Moe
              </label>
              <label>
                <Field<Stooge>
                  name="stooge"
                  component={RadioInput}
                  type="radio"
                  value="curly"
                />{" "}
                Curly
              </label>
            </div>
          </div>
          <div>
            <label>Notes</label>
            <Field name="notes" component={TextAreaInput} placeholder="Notes" />
          </div>
          <div className="buttons">
            <button type="submit" >
              Submit
            </button>
            <button
              type="button"
              onClick={() => {
                form.reset();
                //this.setState({"reset": true});
            }}
              disabled={submitting || pristine}
            >
              Reset
            </button>
          </div>
            <pre>{JSON.stringify(values, undefined, 2)}</pre> 
        </form>
      )}
    />
  </Styles></div>)
  
 
export default VendorForm; 