import React, { useEffect, useMemo, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { persistCommunityCode, readCommunityCode } from './../services/localStorage.service';

import { CommunityModel } from './../domain/CcommunityModel';//[//'../../domain/CcommunityModel';
import { getCitiesData, getCommunityData } from './../api/community.api';
import { notificationController } from './../controllers/notificationController';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseSpace } from './common/BaseSpace/BaseSpace';

const { Option } = Select;
export default function CommunityList() {
    

  const [inputValue, setInputValue] = React.useState('');
  
  const  onTagsChange = (event:any, values:any) => {
    if(values === null){ 
        persistCommunityCode(null)
    }else{
        persistCommunityCode({code: values.code, name: values.name})
    }

  }


  const [data, setData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [cityValue, setCityValue] = React.useState('');
  const { t } = useTranslation();


  useEffect(() => {
    getCommunityData(cityValue)
      .then((res) => setData(res))
      .catch((e) => notificationController.error({ message: e.message }));
  }, [cityValue]);

  useEffect(() => {
    getCitiesData()
      .then((res) => setCityData(res))
      .catch((e) => notificationController.error({ message: e.message }));
  }, []);

  const commDataOptions = useMemo(
    () =>
      data.map((commData: any) => {

        return (
          <Option key={commData.code} value={commData.code}>
            {t(commData?.name || '')}
          </Option>
        );
      }),
    [data, t],
  );


  const cityOptions = useMemo(
    () =>
      cityData && cityData.map((cityval: any) => {

        return (
          <Option key={cityval.city} value={cityval.city}>
            {t(cityval?.city || '')}
          </Option>
        );
      }),
    [data, t],
  );

  function onChange(value:any) {
    console.log(`selected ${value}`);
    persistCommunityCode({code: value, name: value})
  }
  function onSearch(val:any) {
    console.log('search:', val);

  }

  function onChangeCity(value:any) {
    console.log(`selected city ${value}`);
    setCityValue(value);
  }
  function onSearchCity(val:any) {
    console.log('search city:', val);
  }


  return (
    <div>


{data ? (<div><label>Select a City</label><Select
    showSearch
    placeholder="Select a City"
    optionFilterProp="children"
    onChange={onChangeCity}
    onSearch={onSearchCity}
    style={{width:'100%'}}
    filterOption={(input, option:any) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    { cityOptions }
  </Select></div>) :<></>}

<BaseSpace>&nbsp;
</BaseSpace>

      {data ? (
      <div><label>Select a Community</label>
      <Select
    showSearch
    placeholder="Select a Community"
    
    optionFilterProp="children"
    onChange={onChange}
    onSearch={onSearch}
    style={{width:'100%'}}
    filterOption={(input, option:any) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    { commDataOptions }
  </Select></div>) :<></>}
    
     
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [{"id":"1","code":"auburn-mckinney-tx","name":"Auburn Hill Community, McKinney, TX, 75071","createdAt":"2023-08-06T15:36:30.586Z","updatedAt":"2023-08-06T15:36:30.586Z","address":"Auburn Hill dr","address1":"","city":"McKinney","state":"TX","postalcode":"75071"}];
