import React from 'react';
//import logo from './logo.svg';
import logo192 from './logo192.png'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import "./../../style.css";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AccountCircleSharp } from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import photographer from './../../images/photographer.png';
import decorator from './../../images/decorator.png';
import flowersshopico from './../../images/flowers-shop-ico.png';
import djsico from './../../images/djsico.png';
import hallico from './../../images/hallico.png';
import cateringico from './../../images/cateringico.png';
import Demo from '../../components/header';
import Card from '../../components/cards';
import SearchBar from '../../components/searchbar';
import Footer from '../../components/guestfooter';
import Avatar from '@mui/material/Avatar';
import Items from '../../components/items';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ResponsiveDialog from '../../components/responsivedialog';

import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import DateTimePickerValue from '../../components/datetime';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import  VendorForm  from './../../components/vendorform';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const top100Films = [
    { label: 'Birthday', year: 1994 },
    { label: 'House Warming', year: 1972 },
    { label: 'Marriage', year: 1974 }
];

const countryList = [
    { label: 'United Status', code: 1 },
    { label: 'Canada', code: 2 },
    { label: 'India', code: 4 }
];


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'green',
    },
    '& label.Mui-root': {
        color: 'blue',
    },
  });


export function Vendor() {

   
    return (
        <React.Fragment>
         <div><VendorForm /></div>
        
        </React.Fragment>
    );
}

export default Vendor;
