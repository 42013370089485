import React, { useEffect, useMemo, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from './../../../components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from './../../../hooks/reduxHooks';
import { doSignUp } from './../../../store/slices/authSlice';
import { notificationController } from './../../../controllers/notificationController';
import { ReactComponent as GoogleIcon } from './../../../assets/icons/google.svg';
import { ReactComponent as FacebookIcon } from './../../../assets/icons/facebook.svg';
import * as Auth from './../../../components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { CommunityModel } from '../../../domain/CcommunityModel';
import { getCitiesData, getCommunityData } from './../../../api/community.api';

import { BaseSelect, Option } from './../../../components/common/selects/BaseSelect/BaseSelect';
import { languages } from './../../../constants/languages';
import { BaseSpace } from './../../../components/common/BaseSpace/BaseSpace';
import { getStateNames } from '../../../api/states.api';
import { getCitiNames } from '../../../api/cities.api';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  communityCode: string;
  termOfUse: boolean;
  userTypecode: string;
  isActive: boolean;
  latitude: number;
  longitude: number;
  timeZone: string;
  city: string;
  state: string;
  otherCommunityName: string;

}

const initValues = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  confirmPassword: '',
  communityCode: '',
  userTypecode: 'resident',
  termOfUse: true,
  isActive: true,
  city: '',
  state: '',
  otherCommunityName: '',
};

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const [inputValue, setInputValue] = React.useState('');
  const [codeValue, setCodeValue] = React.useState('');
  const [data, setData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [cityValue, setCityValue] = React.useState('');

  const [stateNamesData, setStateNamesData] = useState([]);
  const [stateValue, setStateValue] = React.useState('');

  const onCityChange = (value: any) => {
    setCityValue(value || '')
  }


  const onStateChange = (value: any) => {
    console.log(value)
    setStateValue(value || '')
  }

  const onTagsChange = (event: any, values: any) => {
    if (values === null) {
      // persistCommunityCode(null)
    } else {

      setInputValue(values.code);
      setCodeValue(values.code)
      //  persistCommunityCode({code: values.code, name: values.name})
    }

  }



  const { t } = useTranslation();
  const location = useLocation();

  // Get a specific query parameter
  const urlLink = new URLSearchParams(location.search).get('urlLink');
  const redirectUrl = urlLink ? ("/auth/login?urlLink="+urlLink):("/auth/login");


  const handleSubmit = (values: SignUpFormData) => {
    values.termOfUse = true;
    values.userTypecode = "resident";
    values.latitude = 0;
    values.longitude = 0;
    values.timeZone = "CST";
    values.username = values.username.toString().toLowerCase();
    setLoading(true);
    dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.signUpSuccessMessage'),
          description: t('auth.signUpSuccessDescription'),
        });
        navigate(redirectUrl);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };


  useEffect(() => {
    getStateNames()
      .then((res) => setStateNamesData(res))
      .catch((e) => notificationController.error({ message: e.message }));
  }, [stateValue]);


  useEffect(() => {
    cityValue && getCommunityData(cityValue)
      .then((res) => setData(res))
      .catch((e) => {
        console.log("empty")
      });
  }, [cityValue]);


  useEffect(() => {
    stateValue && getCitiNames(stateValue)
      .then((res) => setCityData(res))
      .catch((e) => notificationController.error({ message: e.message }));
  }, [stateValue]);



  const commDataOptions = useMemo(
    () =>
      data.map((commData: any) => {

        return (
          <Option key={commData.code} value={commData.code}>
            {t(commData?.name || '')}
          </Option>
        );
      }),
    [data, t],
  );



 /*  const stateOptions = useMemo(
    () =>
      stateNamesData && stateNamesData.map((stateval: any) => {

        return (
          <Option key={stateval.key} value={stateval.key}>
            {t(stateval.name || '')}
          </Option>
        );
      }),
    [data, t],
  );


  const cityOptions = useMemo(
    () =>
      cityData && cityData.map((cityval: any) => {

        return (
          <Option key={cityval} value={cityval}>
            {t(cityval || '')}
          </Option>
        );
      }),
    [data, t],
  );
 */

  return data ? (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t('common.signUp')}</S.Title>
        {/*   <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={data}
                getOptionLabel={(option:any) => (option.name+' '+option.city+' '+option.state+' '+option.postalcode)}
                onChange={onTagsChange}
                inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
            
                renderInput={(params) => <TextField   {...params} label={'Community'} />}
    /> */}


        <Auth.FormItem
          name="state"
          label={'Select State'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormBaseSelect onChange={(e) => onStateChange(e)} >

            {stateNamesData && stateNamesData.length > 0 && stateNamesData.map((stateval: any) => (
              <Option key={stateval.stateid} value={stateval.stateid}>
                {t(stateval.statename || '')}
              </Option>
            ))}

          </Auth.FormBaseSelect>
        </Auth.FormItem>



        <Auth.FormItem
          name="city"
          label={'Select City'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormBaseSelect onChange={(e) => onCityChange(e)} >
            {cityData && cityData.length > 0 && cityData.map((cityval: any) => (
              <Option key={cityval.cityascii} value={cityval.cityascii}>
                {t(cityval.city || '')}
              </Option>
            ))}
          </Auth.FormBaseSelect>
        </Auth.FormItem>


        <Auth.FormItem
          name="communityCode"
          label={'Community'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormBaseSelect >{commDataOptions}</Auth.FormBaseSelect>
        </Auth.FormItem>

        <Auth.FormItem
          name="otherCommunityName"
          label={t('Enter your community name if you selected "Other".')}
          rules={[
            {
              required: false,
              message: t('common.requiredField'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                
                if (value === '' && getFieldValue('communityCode') === 'other-000') {
                  return Promise.reject(new Error(t('profile.nav.securitySettings.otherCommunityCode')));
                }else{
                  return Promise.resolve();
                }
                
              },
            }),
          ]}>
          <Auth.FormInput />
        </Auth.FormItem>


        <Auth.FormItem
          name="firstName"
          label={t('common.firstName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.firstName')} />
        </Auth.FormItem>



        <Auth.FormItem
          name="lastName"
          label={t('common.lastName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.lastName')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="username"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput autoComplete="off" placeholder={t('common.email')} />
        </Auth.FormItem>



        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.confirmPassword')}
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <Auth.Text>
                {t('signup.agree')}{' '}
                <Link to="/privacy/privacyAgree" target={'_blank'}>
                  <Auth.LinkText>{t('signup.termOfUse')}</Auth.LinkText>
                </Link>{' '}
                and{' '}
                <Link to="/privacy/privacyAgree" target={'_blank'}>
                  <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>
                </Link>
              </Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.signUp')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        {/*  <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t('signup.googleLink')}
          </Auth.SocialButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <FacebookIcon />
            </Auth.SocialIconWrapper>
            {t('signup.facebookLink')}
          </Auth.SocialButton>
        </BaseForm.Item> */}
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('signup.alreadyHaveAccount')}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  ) : <></>;
};
