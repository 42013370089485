import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonalInfo } from './../components/profile/profileCard/profileFormNav/nav/PersonalInfo/PersonalInfo';///Profile/profileCard/profileFormNav/nav/PersonalInfo/PersonalInfo';
import { PageTitle } from './../components/common/PageTitle/PageTitle';
import { SignUpForm } from '../components/auth/SignUpForm/SignUpForm';
import { DiscountInput } from '../components/setting/settingCard/settingFormNav/nav/DiscountInput/DiscountInput';
import { getServiceTypeIds } from '../api/serviceprovider.api';
import { useAppSelector } from '../hooks/reduxHooks';

const DiscountInputPage: React.FC = () => {
  const { t } = useTranslation();
 

  return (
    <>
      <PageTitle>{t('profile.nav.personalInfo.title')}</PageTitle>
      <DiscountInput  />
    </>
  );
};

export default DiscountInputPage;
