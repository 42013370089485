import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from './../../../../domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from './../../../../components/common/BaseAvatar/BaseAvatar';
import  banner  from './../../../../assets/banner.png';
interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(90);

  const { t } = useTranslation();

  return profileData ? (
    <S.Wrapper>
       <S.ImgWrapper>
       {/*  <BaseAvatar shape="circle" src={banner} alt="Profile" /> */}
      </S.ImgWrapper> 
      <S.Title>{'My Profile'}</S.Title>
      <S.Subtitle>{profileData?.username}</S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper> */}
     {/*  <S.Text>{t('profile.fullness')}</S.Text> */}
    </S.Wrapper>
  ) : null;
};
