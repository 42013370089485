import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from './../../../../../hooks/reduxHooks';
import { useResponsive } from './../../../../../hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from './../../../../../components/common/BasePopover/BasePopover';
import { BaseCol } from './../../../../../components/common/BaseCol/BaseCol';
import { BaseRow } from './../../../../../components/common/BaseRow/BaseRow';
import { BaseAvatar } from './../../../../../components/common/BaseAvatar/BaseAvatar';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';


export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const user = useAppSelector((state:any) => state.user.user);
  
  

  //console.log(JSON.stringify(user))

  return user?.firstName ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>          
          <AccountCircleOutlinedIcon style={{color:'#cd1f28'}} sx={{ fontSize: 40 }}  />
        </BaseCol>
        {isTablet && (
          <BaseCol>
             <span style={{color:'#022366'}}>{`${user?.firstName} ${user?.lastName[0]}`}</span>            
          </BaseCol>
        )}
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : <Link to="/auth/login">
    <Tooltip title="Login" ><LoginRoundedIcon  sx={{ color: 'red', fontSize: 35 }}  /></Tooltip>
    <div style={{marginTop:'-6px'}}>Login</div>
</Link>;
};
