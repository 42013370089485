import React, { useEffect, useMemo, useState } from 'react';
import { PageTitle } from '././../../components/common/PageTitle/PageTitle';

import { useResponsive } from '././../../hooks/useResponsive';

import { RecentActivity } from '././../../components/nft-dashboard/recentActivity/RecentActivity';
import * as S from './../../pages/DashboardPages/DashboardPage.styles';//DashboardPage.styles';
import { BaseRow } from '././../../components/common/BaseRow/BaseRow';
import { BaseCol } from '././../../components/common/BaseCol/BaseCol';
import { PersonalInfo } from './../../components/servicelist/PersonalInfo';
import MediaControlCard from './../../components/ServiceProviderList/ServiceCard';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getServiceProviderData } from './../../api/serviceprovider.api';
import { notificationController } from './../../controllers/notificationController';
import { useAppDispatch } from './../../hooks/reduxHooks';
import { TrendingCollections } from './../../components/nft-dashboard/trending-collections/TrendingCollections';
import { EventCollections } from './../../components/EventsList/EventCollections';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EventDealList: React.FC = () => {
  const { isDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const { type } = useParams() || ''; 
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const desktopLayout = (
   /*  <BaseRow>


      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <BaseRow >          
          <BaseCol span={24}>
          <PersonalInfo />
          </BaseCol>
        </BaseRow>
        <References />
      </S.LeftSideCol>

     
    </BaseRow> */




<BaseRow>



<S.LeftSideCol  xl={16} xxl={17}>
            
  <BaseRow gutter={[30, 30]}>
    <BaseCol span={24}>
      {type && (<> <EventCollections />  </>  )}
        
    </BaseCol>

    
  </BaseRow>
 {/*  <References /> */}
</S.LeftSideCol>

<S.RightSideCol  xl={8} xxl={7}>

  {/* <div id="blood-screening">
    <BloodScreeningCard />
  </div>
  <S.Space />
  <S.ScrollWrapper id="patient-timeline">
    <PatientResultsCard />
  </S.ScrollWrapper> */}
</S.RightSideCol>
</BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
      {type && (<> <EventCollections />  </>  )}
      </BaseCol>

      <BaseCol span={24}>
       {/*  <RecentlyAddedNft /> */}
      </BaseCol>

      <BaseCol span={24}>
      {/*   <TrendingCollections /> */}
      </BaseCol>

      <BaseCol span={24}>
        {/* <RecentActivity /> */}
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
     
     <PageTitle>ServiceToGet App</PageTitle>
     <Typography gutterBottom component="div" variant="h6">
                <>{t('common.' + type)} - DFW</>

            </Typography>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default EventDealList;
