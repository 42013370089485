import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from './../../../../domain/UserModel';
import * as S from './SettingInfo.styles';
import { BaseAvatar } from './../../../../components/common/BaseAvatar/BaseAvatar';
import  banner  from './../../../../assets/banner.png';
interface SettingInfoProps {
  settingData: UserModel | null;
}

export const SettingInfo: React.FC<SettingInfoProps> = ({ settingData }) => {
  const [fullness] = useState(90);

  const { t } = useTranslation();

  return settingData ? (
    <S.Wrapper>
       <S.ImgWrapper>
         {/* <BaseAvatar shape="circle" src={banner} alt="Profile" />  */}
      </S.ImgWrapper> 
      <S.Title>{'Setting'}</S.Title>
      <S.Subtitle>{settingData?.username}</S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper> */}
     {/*  <S.Text>{t('profile.fullness')}</S.Text> */}
    </S.Wrapper>
  ) : null;
};
