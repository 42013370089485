import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { BaseCarousel } from './../../../components/common/BaseCarousel/Carousel';
import { ViewAll } from './../../../components/nft-dashboard/common/ViewAll/ViewAll';
import { NFTCardHeader } from './../../../components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { TrendingCollection } from './collection/TrendingCollection';
import { useResponsive } from './../../../hooks/useResponsive';
import { getTrendingActivities, TrendingActivity } from './../../../api/activity.api';
import * as S from './TrendingCollections.styles';
import { BaseRow } from './../../../components/common/BaseRow/BaseRow';
import { BaseCol } from './../../../components/common/BaseCol/BaseCol';
import { BaseModal } from '../../common/BaseModal/BaseModal';

import  british  from './../../../assets/british.jpeg';

const initial = {
   title:"",
   owner:"",
   image:"",
   avatar:"",
   usd_value:0};

   const newinitial = {
    title:"",
    owner:"",
    image:british,
    avatar:"",
    usd_value:0}; 


export const TrendingCollections: React.FC = () => {
  

  const { mobileOnly, isTablet: isTabletOrHigher } = useResponsive();
  const [isMiddleModalOpen, setIsMiddleModalOpen] = useState<boolean>(false);
  const [selectDeatils, setSelectDeatils] = useState(initial);
  const [trending, setTrending] = useState<TrendingActivity[]>([]);
  useEffect(() => {
    getTrendingActivities().then((res) => { 
      res.push(newinitial);
      setTrending(res)
    } );

  }, []);

  const { t } = useTranslation();

  const onclickVal = (item:any) => {
    setSelectDeatils(item);
    setIsMiddleModalOpen(true);
  }

  const trendingList = useMemo(() => {
    return {
    //  mobile: trending.map((item, index) => <TrendingCollection key={index} {...item} />).slice(0, 3),
      mobile: trending.map((item, index) => <div onClick={() => onclickVal(item)}><TrendingCollection   key={index} {...item} />
      <BaseModal
      title={t('modals.middleTitle')}
      centered
      open={isMiddleModalOpen}
      onOk={() => setIsMiddleModalOpen(false)}
      onCancel={() => setIsMiddleModalOpen(false)}
      size="medium"
    >
      <p>{t('modals.someContent')}</p>
      <p>{t('modals.someContent')}</p>
      <p>{t('modals.someContent')}</p>
    </BaseModal></div>),
      tablet: trending.map((item, index) => (
        <div onClick={() => onclickVal(item)} key={index}>
          <S.CardWrapper>
            <TrendingCollection {...item} />
          </S.CardWrapper>
          
        </div>
      )),
    };
  }, [trending]);

  const sliderRef = useRef<Slider>(null);

  return (
    <>
      <NFTCardHeader title={''}>
        {isTabletOrHigher && (
          <BaseRow align="middle">
            <BaseCol>
             {/*  <ViewAll bordered={false} /> */}
            </BaseCol>

            <BaseCol>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickPrev()}>
                <LeftOutlined />
              </S.ArrowBtn>
            </BaseCol>

            <BaseCol>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickNext()}>
                <RightOutlined />
              </S.ArrowBtn>
            </BaseCol>
          </BaseRow>
        )}
      </NFTCardHeader>

      <S.SectionWrapper>
        {mobileOnly && trendingList.mobile}

        {isTabletOrHigher && trending.length > 0 && (
          <BaseCarousel
            ref={sliderRef}
            slidesToShow={3}
            autoplay
            autoplaySpeed={2500}
            responsive={[
              {
                breakpoint: 1900,
                settings: {
                  slidesToShow: 2,
                },
              },
            ]}
          >
            {trendingList.tablet}
          </BaseCarousel>
        )}
      </S.SectionWrapper>

      {true && (
        <S.ViewAllWrapper>
          <BaseModal
            title={'Details'}
            centered
            open={isMiddleModalOpen}
            onOk={() => setIsMiddleModalOpen(false)}
            onCancel={() => setIsMiddleModalOpen(false)}
            size="medium"
          >
       
            <p><img src={selectDeatils?.image} /></p>
          </BaseModal>
         {/*  <ViewAll /> */}
        </S.ViewAllWrapper>
      )}
    </>
  );
};