import React, { useEffect } from 'react';
import { DesktopHeader } from './layouts/DesktopHeader';
import { MobileHeader } from './layouts/MobileHeader';
import { useResponsive } from './../../hooks/useResponsive';
import { persistClientIp } from '../../services/localStorage.service';

interface HeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  isTwoColumnsLayout: boolean;
}

export const Header: React.FC<HeaderProps> = ({ toggleSider, isSiderOpened, isTwoColumnsLayout }) => {
  const { isTablet } = useResponsive();

  /* const getData = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json()
    persistClientIp(data);
  };

  useEffect(() => {
    getData();
  }, []); */

  return isTablet ? (
    <DesktopHeader isTwoColumnsLayout={isTwoColumnsLayout} />
  ) : (
    <MobileHeader toggleSider={toggleSider} isSiderOpened={isSiderOpened} />
  );
};
