import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation } from 'react-router-dom';
import { MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH } from './../../../../components/router/AppRouter';
import { useResponsive } from './../../../../hooks/useResponsive';
import { useAppSelector } from './../../../../hooks/reduxHooks';
import { References } from './../../../../components/common/References/References';//'./components/common/References/References';



const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const user = useAppSelector((state: any) => state.user.user);
 



  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH].includes(location.pathname) && isDesktop);
    
    
  }, [location.pathname, isDesktop]);

 

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
          
        </MainHeader>
        <MainContent style={{ background: '#FFF', borderStyle: '2px solid #025fb5' }} id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div >
            <Outlet />
            
          </div>
          
          {!isTwoColumnsLayout && <div ><References  /></div>}
       
        </MainContent>
       
      </S.LayoutMain>
      

    </S.LayoutMaster>
  );
};

export default MainLayout;
