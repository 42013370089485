import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router}  from "react-router-dom";
import { Provider } from 'react-redux';
import './i18n';
import './config/config';
import { createRoot } from 'react-dom/client';
import { store } from './store/store';
import ReactGA from "react-ga4";

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
ReactGA.initialize("G-B2NZRB1D9J");

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
