import React from 'react';
import {  BaseButtonProps , BaseButton} from './../../../BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { BaseRow } from './../../../BaseRow/BaseRow';
import { BaseCol } from './../../../BaseCol/BaseCol';
import Button from '@mui/material/Button';

interface BaseButtonsGroupProps extends BaseButtonProps {
  className?: string;
  onCancel: () => void;
  loading?: boolean;
}

export const BaseButtonsGroup: React.FC<BaseButtonsGroupProps> = ({ className, onCancel, loading, ...props }) => {
  const { t } = useTranslation();

  return (
    <BaseRow className={className} gutter={[10, 10]} wrap={false}>
      <BaseCol span={12}>
      <Button size="medium"> {t('common.cancel')}</Button>
         <BaseButton block type="ghost" onClick={onCancel} {...props}>
          {t('common.cancel')}
        </BaseButton> 
      </BaseCol>
      <BaseCol span={12}>
      <Button size="medium"> {t('common.save')}</Button>
         <BaseButton block type="primary" loading={loading} htmlType="submit" {...props}>
          {t('common.save')}
        </BaseButton> 
      </BaseCol>
    </BaseRow>
  );
};
