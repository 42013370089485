import React, { useEffect, useMemo, useState, useRef, } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '../common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '../../hooks/reduxHooks';
import * as Auth from './PrivacyLayout.styles';
import * as S from './PrivacyForm.styles';




//export const PrivacyForm: React.FC<any> = (props:any) => {
export const PrivacyForm = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);




  const { t } = useTranslation();



  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional" >
        <S.Title>{'Terms of Use and Privacy Policy'}</S.Title>

        <Auth.FormItem>
          <p>
            This privacy policy explains how <a href="https://www.servicetoget.com">www.servicetoget.com</a> (“we”, “us”, or “our”) collects, uses, shares, and protects your personal information when you use our website, mobile app, or other services (collectively, the “Services”). It also describes your choices and rights regarding your personal information.
          </p>
        </Auth.FormItem>

        <Auth.FormItem>
          <p>Please read this privacy policy carefully before using our Services. By using our Services, you agree to the collection and use of your personal information as described in this privacy policy. If you do not agree with this privacy policy, please do not use our Services.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h1>
            What Information We Collect
          </h1>
        </Auth.FormItem>

        <Auth.FormItem>
          <p>
            Collect the following types of personal information from you when you use our Services:<br />
            We may collect the following types of personal information from you when you use our Services:
          </p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Account information:</h3>
          <p> When you create an account with us, we may collect your name, email address, phone number, password, and other information you choose to provide.</p>
        </Auth.FormItem>


        <Auth.FormItem>
          <h3>Profile information:</h3>
          <p> When you create or update your profile on our Services, we may collect your photo, location, preferences, skills, availability, ratings, reviews, and other information you choose to provide.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Service information:</h3>
          <p> When you request or provide a service through our Services, we may collect information about the service, such as the type, date, time, duration, price, location, and other details.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Payment information:</h3>
          <p>  When you make or receive payments through our Services, we may collect your payment method details (such as credit card number or bank account number), billing address, transaction history, and other information necessary to process the payments. We use third-party payment processors to handle the payments and do not store your payment information on our servers.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Communication information:</h3>
          <p> When you communicate with us or other users through our Services, we may collect the content and metadata of your messages, such as the sender, recipient, date, time, and subject.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Device and usage information:</h3>
          <p> When you access or use our Services, we may automatically collect information about your device (such as device type, model, operating system, browser type and version) and your usage (such as pages visited, features used, duration of use, IP address, cookies, and other identifiers).</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Device and usage information:</h3>
          <p> When you access or use our Services, we may automatically collect information about your device (such as device type, model, operating system, browser type and version) and your usage (such as pages visited, features used, duration of use, IP address, cookies, and other identifiers).</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3> How We Use Your Information</h3>
          <h3> We may use your personal information for the following purposes:</h3>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>To provide and improve our Services:</h3>
          <p>We use your personal information to provide and improve our Services, such as to create and manage your account, to match you with suitable service providers or seekers, to facilitate communication and transactions, to provide customer support, to monitor and analyze the performance and usage of our Services, to troubleshoot and resolve issues, to update and maintain our Services, and to develop new features and functionalities.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>To personalize your experience:</h3>
          <p> We use your personal information to personalize your experience on our Services, such as to show you relevant content, ads, offers, and recommendations based on your preferences, interests, and behavior.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>To communicate with you:</h3>
          <p> We use your personal information to communicate with you about our Services, such as to send you notifications, alerts, reminders, confirmations, surveys, newsletters, and other messages. You can manage your communication preferences in your account settings or by following the instructions in the messages.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>To market and promote our Services:</h3>
          <p> We use your personal information to market and promote our Services, such as to send you promotional emails, SMS messages, or push notifications about our products, services, events, or offers. You can opt out of receiving marketing communications from us at any time by following the instructions in the messages or contacting us at <b>info@servicetoget.com</b>.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>To comply with legal obligations:</h3>
          <p> We use your personal information to comply with legal obligations, such as to respond to lawful requests from authorities, to prevent fraud and abuse, to enforce our terms and conditions, and to protect our rights and interests.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>How We Share Your Information</h3>
          <p>We may share your personal information with the following parties for the following purposes:</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Other users:</h3>
          <p>We share your personal information with other users when you use our Services to request or provide a service. For example, we share your name, photo, location, ratings, reviews, and other profile information with potential service providers or seekers. We also share your contact details and communication information with the users you communicate or transact with through our Services. You can control what information you share with other users in your account settings or by adjusting your privacy settings.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Service providers:</h3>
          <p> We share your personal information with third-party service providers that help us with various aspects of our Services, such as hosting, storage, payment processing, analytics, marketing, communication, and customer support. We require these service providers to use your personal information only for the purposes we authorize and to protect it in accordance with applicable laws and standards.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Affiliates:</h3>
          <p>We share your personal information with our affiliates, which are companies that are owned or controlled by us or that own or control us. We require our affiliates to use your personal information only for the purposes we authorize and to protect it in accordance with applicable laws and standards.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Business partners:</h3>
          <p>We share your personal information with our business partners, which are companies that we collaborate with to offer you products, services, or promotions that may be of interest to you. We require our business partners to use your personal information only for the purposes we authorize and to protect it in accordance with applicable laws and standards.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Advertisers:</h3>
          <p>We share your personal information with advertisers, which are companies that display ads on our Services or on other platforms. We may share aggregated or anonymized information that does not directly identify you, such as your device and usage information, or we may share pseudonymized information, such as a unique identifier that is not linked to your name or contact details. We do not share your name, email address, phone number, or payment information with advertisers without your consent.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>Authorities:</h3>
          <p> We share your personal information with authorities, such as law enforcement, courts, regulators, or government agencies, when we are required or permitted by law to do so, or when we believe it is necessary to protect our rights and interests or the rights and interests of others.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>How We Protect Your Information</h3>
          <p>We take reasonable measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. These measures include:</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <p>Using encryption and other security technologies to protect your personal information during transmission and storage
            <br />Limiting access to your personal information to authorized personnel who need it to perform their duties
            <br />Providing training and guidance to our personnel on data protection and privacy practices
            <br />Implementing policies and procedures to detect, prevent, and respond to security incidents
            <br />However, no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your personal information. You are responsible for maintaining the confidentiality of your account password and for any activities that occur under your account. If you suspect any unauthorized access or use of your account or personal information, please contact us immediately at <b>info@servicetoget.com</b>.

          </p>
        </Auth.FormItem>


        <Auth.FormItem>
          <h3>How Long We Keep Your Information</h3>
          <p>We keep your personal information for as long as necessary to provide and improve our Services, to comply with legal obligations, to resolve disputes, and to enforce our agreements. The retention period may vary depending on the type and purpose of the information. When we no longer need your personal information, we will delete it from our systems or anonymize it so that it cannot be linked back to you.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>What Rights You Have Over Your Information</h3>
          <p>Depending on the applicable laws and regulations in your jurisdiction, you may have certain rights over your personal information. These rights may include:
            <br />Access: You have the right to request access to your personal information that we hold and receive a copy of it.
            <br />Correction: You have the right to request correction of any inaccurate or incomplete personal information that we hold about you.
            <br />Deletion: You have the right to request deletion of your personal information that we hold about you, unless we have a legal reason or obligation to keep it.
            <br />Restriction: You have the right to request restriction of the processing of your personal information in certain circumstances, such as when you contest the accuracy or lawfulness of the processing.

            <br />Objection: You have the right to object to the processing of your personal information for certain purposes, such as direct marketing or profiling.
            <br />Portability: You have the right to request transfer of your personal information that we hold about you in a structured, commonly used, and machine-readable format to another party.
            <br />Withdrawal of consent: You have the right to withdraw your consent for the processing of your personal information at any time, without affecting the lawfulness of the processing based on consent before its withdrawal.
            <br />Complaint: You have the right to lodge a complaint with a supervisory authority if you believe that our processing of your personal information violates applicable laws and regulations.
            <br />To exercise any of these rights, please contact us at <b>info@servicetoget.com</b> with a clear description of your request. We may ask you to provide proof of identity before fulfilling your request. We will respond to your request within a reasonable time frame or as required by law.
          </p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>How We Use Cookies</h3>
          <p>We use cookies and similar technologies to enhance your experience on our Services, to remember your preferences, to provide security, to measure and analyze the performance and usage of our Services, and to deliver relevant ads. Cookies are small text files that are stored on your device when you visit a website. You can manage your cookie preferences in your browser settings or by contacting us at <b>info@servicetoget.com</b>. For more information about how we use cookies, please see our [cookie policy].</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>How We Update Our Privacy Policy</h3>
          <p>We may update our privacy policy from time to time to reflect changes in our practices, laws, or regulations. We will notify you of any material changes by posting a notice on our Services or by sending you an email. The updated privacy policy will take effect as soon as it is posted or as otherwise stated. Your continued use of our Services after the update will constitute your acceptance of the updated privacy policy. We encourage you to review our privacy policy periodically to stay informed about how we protect your personal information.</p>
        </Auth.FormItem>

        <Auth.FormItem>
          <h3>How To Contact Us</h3>
          <p>If you have any questions, comments, or concerns about our privacy policy or practices, please contact us at <b>info@servicetoget.com</b>. We will respond to your inquiry as soon as possible. </p>
        </Auth.FormItem>

      </BaseForm>
    </Auth.FormWrapper>
  );
};
