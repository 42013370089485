import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberWithCommas, getCurrencyPrice } from './../../utils/utils';
import { TrendingActivity } from './../../api/activity.api';
import { CurrencyTypeEnum } from './../../interfaces/interfaces';
import { BaseAvatar } from './../../components/common/BaseAvatar/BaseAvatar';
import * as S from './EventCollection.styles';

export const EventCard: React.FC<any> = ({ name, owner, location, image, file_name }) => {
  const { t } = useTranslation();

  const imageUrl = 'https://sasvcgetprod.blob.core.windows.net/'+location+'/'+file_name;
  const title = name+' '+location;
  return (
    <S.Card style={{maxWidth:'160px', minWidth:'160px', border:'1px solid #022366', maxHeight:'180px', marginLeft:'-7px'}} padding={0} $img={imageUrl}>
      <S.CollectionImage style={{maxWidth:'165px'}} src={imageUrl} alt="nft" />
      <S.BidButton type="ghost">{'Details'}</S.BidButton>
      <S.NftCollectionInfo>
       {/*  <S.AuthorAvatarWrapper>
          <BaseAvatar shape="circle" size={60} src={'avatar'} alt={owner} >Very Soon</BaseAvatar>
        </S.AuthorAvatarWrapper> */}
        <S.InfoRow style={{marginTop:'-20px'}}>
          <S.Title  level={5}>{name.toString().toUpperCase()} </S.Title>
         {/*  <S.OwnerText style={{minWidth:'160px'}}>
            {'@'} {location+' Area'}
          </S.OwnerText> */}
        </S.InfoRow>
        {/* <S.InfoRow>
          <S.OwnerText style={{minWidth:'160px'}}>
            {'@'} {location+' Area'}
          </S.OwnerText>
        </S.InfoRow> */}
      </S.NftCollectionInfo>
    </S.Card>
  );
};



export const EventCardMobile: React.FC<any> = ({ name, owner, location, image, file_name }) => {
  const { t } = useTranslation();

  const imageUrl = 'https://sasvcgetprod.blob.core.windows.net/'+location+'/'+file_name;
  const title = name+' @'+location.toString().toUpperCase()+' Area';
  return (
    <S.Card style={{ minWidth:'170px', border:'1px solid #022366', maxHeight:'200px'}} padding={0} $img={imageUrl}>
      <S.CollectionImage src={imageUrl} alt="nft" />
      <S.BidButton type="ghost">{'Details'}</S.BidButton>
      <S.NftCollectionInfo>
        {/* <S.AuthorAvatarWrapper>
          <BaseAvatar shape="circle" size={60} src={'avatar'} alt={owner} >Very Soon</BaseAvatar>
        </S.AuthorAvatarWrapper> */}
        <S.InfoRow >
          <S.Title level={5}>{title}</S.Title>
        </S.InfoRow>
    
      </S.NftCollectionInfo>
    </S.Card>
  );
};