import { httpApi } from './http.api';
//import './mocks/auth.api.mock';
import { UserModel } from './../domain/UserModel';

export interface AuthData {
  username: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  communityCode: string;
  termOfUse: boolean;
  userTypecode: string;
  isActive: boolean;
  latitude: number;
  longitude: number;
  timeZone: string;
}

export interface ResetPasswordRequest {
  username: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
  username: string;
  token: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('auth/login', { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('auth/signUp', { ...signUpData }).then(({ data }) => data);

export const guestLogin = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('auth/guestLogin', { ...signUpData }).then(({ data }) => data);

export const discountSave = (signUpData: any): Promise<undefined> =>
  httpApi.post<undefined>('discounts/discountsave', { ...signUpData }).then(({ data }) => data);

export const removeDiscountId = (signUpData: any): Promise<undefined> =>
  httpApi.post<undefined>('discounts/removeId', { ...signUpData }).then(({ data }) => data);

  export const promoCodeSave = (signUpData: any): Promise<undefined> =>
  httpApi.post<undefined>('promocode/createPromoCode', { ...signUpData }).then(({ data }) => data);  
  

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('auth/forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('auth/verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<any>('auth/setNewPassword', { ...newPasswordData }).then(({ data }) => data);

export const clientDetail = (): Promise<any> =>
  httpApi.get<any>('https://ipapi.co/json/').then(({ data }) => data);  
