import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '../../../../../common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '../../../../../common/BaseCard/BaseCard';

import { useAppSelector, useAppDispatch } from '../../../../../../hooks/reduxHooks';

import { Dates } from '../../../../../../constants/Dates';
import { notificationController } from '../../../../../../controllers/notificationController';

import { BaseRow } from '../../../../../common/BaseRow/BaseRow';
import { BaseCol } from '../../../../../common/BaseCol/BaseCol';
import { BaseSelect, Option } from '../../../../../common/selects/BaseSelect/BaseSelect';
import { BaseInput } from '../../../../../common/inputs/BaseInput/BaseInput';
import { Calendar } from 'antd';
import type { DatePickerProps } from 'antd';
import { DatePicker, Space } from 'antd';
import { BaseForm } from '../../../../../common/forms/BaseForm/BaseForm';
import { BaseButton } from '../../../../../common/BaseButton/BaseButton';
import { doDiscountSave } from '../../../../../../store/slices/authSlice';
import { getServiceTypeIds } from '../../../../../../api/serviceprovider.api';
import { useNavigate } from 'react-router-dom';



const { RangePicker } = DatePicker;

interface DiscountInputFormValues {
  vendorcode?: string;
  vendoremail?: string;
  discounttype: string;
  servicetype?: string;
  discountvalue: string;
  description?: string;
  start_date: string;
  end_date: string;
 
}

const initialDiscountInputValues: DiscountInputFormValues = {
  vendorcode: '',
  vendoremail: '',
  discounttype: '',
  servicetype: '',
  discountvalue: '',
  description: '',
  start_date: '',
  end_date: '',
 
};

export let DiscountInput: React.FC = (props:any) => {
  const user:any = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  
  useEffect(() => {
    getServiceTypeIds(user.vendorcode).then((res) => {
      console.log('success', JSON.stringify(res));
      setData(res);
    });
  }, [user.vendorcode]);

  /*  const userFormValues = useMemo(
    () =>
      initialDiscountInputValues,
    [],
  ); */
 
  const [form] = BaseButtonsForm.useForm();

  const { t } = useTranslation();

  const onFinish = useCallback(
    (obj: DiscountInputFormValues) => {
      // todo dispatch an action here
      const arrStr:any = obj.servicetype?.split('$');
      console.log(arrStr[0])
      obj.vendorcode = arrStr[0];
      obj.vendoremail = user?.username;
      obj.servicetype = arrStr[1];
      console.log(JSON.stringify(obj));
      setLoading(true);
      dispatch(doDiscountSave(obj))
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({ message: t('common.success') }); 
        navigate('/setting/discount-list')      
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });

/* 


      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setFieldsChanged(false);
        notificationController.success({ message: t('common.success') });
        
      }, 1000); */
    },
    [t],
  );


  const onChangeStart: DatePickerProps['onChange'] = (date, dateString) => {
    console.log('date', date);
    console.log('dateString', dateString);
  };

  const onChangeEnd: DatePickerProps['onChange'] = (date, dateString) => {
    console.log('date', date);
    console.log('dateString', dateString);
  };


  return (
    <BaseCard>
    <BaseForm layout="vertical" onFinish={onFinish} requiredMark="optional" >

   
      
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{t('Discount Input')}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
          <BaseForm.Item
            name="servicetype"
            label={t('Service Type')}
            hasFeedback
            rules={[{ required: true, message: t('Please select service type') }]}
          >
              <BaseSelect placeholder={t('Select Service Type')}>
                
                {data && data.length > 0 && data.map((obj: any) => (
                  <Option key={obj.id} value={obj.id+'$'+obj.serviceType}>
                    {t(obj.serviceType || '')}
                  </Option>
                ))}
              </BaseSelect>
          </BaseForm.Item>

          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseForm.Item
              name="discounttype"
              label={t('Discount Type')}
              hasFeedback
              rules={[{ required: true, message: t('Please select discount type') }]}
            >
                <BaseSelect placeholder={t('Select discount type')}>
                  <Option value="percent">{t('Percentage(%)')}</Option>
                  <Option value="value">{t('Value($)')}</Option>
                </BaseSelect>
            </BaseForm.Item>
          </BaseCol>


         

          <BaseCol xs={24} md={12}>
          <BaseForm.Item
              name="start_date"
              label={t('Start Date')}
              hasFeedback
              rules={[{ required: true, message: t('Please select start date') }]}
            >
              <DatePicker onChange={onChangeStart} />
          </BaseForm.Item>
          
          </BaseCol>


          <BaseCol xs={24} md={12}>
          <BaseForm.Item
              name="end_date"
              label={t('End Date')}
              hasFeedback
              rules={[{ required: true, message: t('Please select end date') }]}
            >
             <DatePicker onChange={onChangeEnd} />
          </BaseForm.Item>
          
          </BaseCol>


          <BaseCol xs={24} md={12}>
            <BaseForm.Item
              name="discountvalue"
              label={t('Value')}
              hasFeedback
              rules={[{ required: false, message: t('Please enter discount value') }]}
            >
            
            <BaseInput placeholder={t('Value')} />
            </BaseForm.Item>
          </BaseCol>


          <BaseCol xs={24} md={12}>
            <BaseForm.Item
              name="description"
              label={t('Terms and conditions')}
              hasFeedback
              rules={[{ required: false, message: t('Please enter terms and conditions') }]}
            >
            <BaseInput.TextArea  rows={4} />
            </BaseForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
          <BaseForm.Item noStyle>
            
          <BaseButton type="primary" htmlType="submit">{t('Save')}</BaseButton>
         
           </BaseForm.Item>
          </BaseCol>

          
        {/*   <BaseCol xs={24} md={12}>
            <NicknameItem />
              <EmailItem  />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <SexItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BirthdayItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <LanguageItem />
          </BaseCol> */}

         

          {/* <BaseCol xs={24} md={12}>
            <PhoneItem verified={user?.phone.verified} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <EmailItem verified={user?.email.verified} />
          </BaseCol> */}

          {/* <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{t('common.address')}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <CountriesItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <CitiesItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <AddressItem number={1} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <AddressItem number={2} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <ZipcodeItem />
          </BaseCol>

          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{t('profile.nav.DiscountInput.otherInfo')}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <WebsiteItem />
          </BaseCol>

          <BaseCol span={24}>
            <SocialLinksItem />
          </BaseCol> */}
        </BaseRow>
        </BaseForm>
    </BaseCard>
  );
};
