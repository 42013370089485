import { UserModel } from './../domain/UserModel';
const avatarImg = '';//process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';



export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || 'bearerToken';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : null;
};


export const persistCommunityCode = (token: any): void => {
  localStorage.setItem('communityCode', JSON.stringify(token));
};

export const readCommunityCode = (): any => {
  const communityCodeStr = localStorage.getItem('communityCode');
  return communityCodeStr ? JSON.parse(communityCodeStr) : null;
};
export const persistClientIp = (token: any): void => {
  localStorage.setItem('clientIp', JSON.stringify(token));
};

export const readClientIp = (): any => {
  const clientIp = localStorage.getItem('clientIp');
  return clientIp ? JSON.parse(clientIp) : null;

 // return localStorage.getItem('clientIp') || '';
};


export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
export const deleteCommunityCode = (): void => localStorage.removeItem('communityCode');
export const deleteClientIp = (): void => localStorage.removeItem('clientIp');
