import React from 'react';
import { Dates } from './../../../constants/Dates';
import { BaseHashTag, IHashTag } from '../BaseHashTag/BaseHashTag';
import { BaseImage } from '../BaseImage/BaseImage';
import { BaseAvatar } from '../BaseAvatar/BaseAvatar';
import * as S from './BaseArticle.styles';
import { BaseRate } from '../BaseRate/BaseRate';

export interface BaseArticleProps {
  author?: React.ReactNode;
  imgUrl: string;
  title: string;
  date: number;
  description: string;
  avatar?: string;
  tags?: IHashTag[];
  className?: string;
  rating: number
}

export const BaseArticle: React.FC<BaseArticleProps> = ({
  imgUrl,
  title,
  date,
  description,
  author,
  avatar,
  tags,
  className,
  rating
}) => {
  return (
    <S.Wrapper className={className}>
      <S.Header>
         {!!avatar && <BaseAvatar src={avatar} alt="author" size={43} />} 
        <S.AuthorWrapper>
          {author && <S.Author>{author}</S.Author>}
          <S.DateTime>{Dates.format(date, 'L')}</S.DateTime>
          <p><BaseRate allowHalf disabled defaultValue={Number(rating)}  /></p>
        </S.AuthorWrapper>
       
      </S.Header>
      
      {/* <BaseImage src={imgUrl} alt="article" preview={false} /> */}
      <S.InfoWrapper>
        <S.InfoHeader>
          <S.Title>{title}</S.Title>
        </S.InfoHeader>
        <S.Description>{description}</S.Description>
      </S.InfoWrapper>

      
    </S.Wrapper>
  );
};