import axios from "axios";

export interface States {
    name: string;
    key: string;
    
  }
  
  export const getCitiNames2 = (stateName:any): Promise<any> => {
   let cities = getStateNamesLaters[stateName];
    return new Promise((res) => {
      setTimeout(() => {
        res(cities);
      }, 0);
    });
  };


  export const getCitiNames = async (stateName:any): Promise<any> => {
    try {
      const response = await axios.get<any>('https://service-to-get-api-4f868ffdd2cb.herokuapp.com/api/v1/statescities/findCities/'+stateName);
   //   const response = await axios.get<any>('http://localhost:5000/api/v1/statescities/findCities/'+stateName) ;
   //   console.log(JSON.stringify(response.data));
 //     http://localhost:5000/api/v1/statescities/findCities/TX
      return response.data || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      throw new Error(e);
    }
  };
  

 

  export const getStateNamesLaters:any = {
    "AL": [
      "Alabaster",
      "Albertville",
      "Alexander City",
      "Andalusia",
      "Anniston",
      "Arab",
      "Athens",
      "Atmore",
      "Attalla",
      "Auburn",
      "Bay Minette",
      "Bessemer",
      "Birmingham",
      "Boaz",
      "Center Point",
      "Chickasaw",
      "Clanton",
      "Cullman",
      "Daphne",
      "Decatur",
      "Demopolis",
      "Dothan",
      "Enterprise",
      "Eufaula",
      "Fairfield",
      "Fairhope",
      "Florence",
      "Foley",
      "Forestdale",
      "Fort Payne",
      "Fort Rucker",
      "Fultondale",
      "Gadsden",
      "Gardendale",
      "Greenville",
      "Guntersville",
      "Hamilton",
      "Hartselle",
      "Helena",
      "Homewood",
      "Hoover",
      "Hueytown",
      "Huntsville",
      "Irondale",
      "Jacksonville",
      "Jasper",
      "Lanett",
      "Leeds",
      "Madison",
      "Millbrook",
      "Mobile",
      "Monroeville",
      "Montgomery",
      "Moody",
      "Mountain Brook",
      "Muscle Shoals",
      "Northport",
      "Opelika",
      "Opp",
      "Oxford",
      "Ozark",
      "Pelham",
      "Pell City",
      "Phenix City",
      "Pleasant Grove",
      "Prattville",
      "Prichard",
      "Rainbow City",
      "Roanoke",
      "Russellville",
      "Saks",
      "Saraland",
      "Scottsboro",
      "Selma",
      "Sheffield",
      "Smiths",
      "Southside",
      "Sylacauga",
      "Talladega",
      "Tarrant",
      "Theodore",
      "Tillmans Corner",
      "Troy",
      "Trussville",
      "Tuscaloosa",
      "Tuscumbia",
      "Tuskegee",
      "Valley",
      "Vestavia Hills"
    ],
    "AK": [
      "Anchorage",
      "College",
      "Fairbanks",
      "Juneau and",
      "Kenai",
      "Ketchikan",
      "Knik-Fairview",
      "Kodiak",
      "Lakes",
      "Sitka and"
    ],
    "AZ": [
      "Apache Junction",
      "Avondale",
      "Bisbee",
      "Buckeye",
      "Bullhead City",
      "Camp Verde",
      "Casa Grande",
      "Casas Adobes",
      "Catalina",
      "Catalina Foothills",
      "Chandler",
      "Chino Valley",
      "Coolidge",
      "Cottonwood",
      "Cottonwood-Verde Village",
      "Dewey-Humboldt",
      "Douglas",
      "Drexel Heights",
      "El Mirage",
      "Eloy",
      "Flagstaff",
      "Florence",
      "Flowing Wells",
      "Fortuna Foothills",
      "Fountain Hills",
      "Gilbert",
      "Glendale",
      "Globe",
      "Gold Camp",
      "Goodyear",
      "Green Valley",
      "Kingman",
      "Lake Havasu City",
      "Marana",
      "Mesa",
      "Mohave Valley",
      "New Kingman-Butler",
      "New River",
      "Nogales",
      "Oro Valley",
      "Page",
      "Paradise Valley",
      "Payson",
      "Peoria",
      "Phoenix",
      "Picture Rocks",
      "Prescott",
      "Prescott Valley",
      "Safford",
      "San Luis",
      "Scottsdale",
      "Sedona",
      "Show Low",
      "Sierra Vista",
      "Sierra Vista Southeast",
      "Somerton",
      "Sun City",
      "Sun City West",
      "Sun Lakes",
      "Surprise",
      "Tanque Verde",
      "Tempe",
      "Tuba City",
      "Tucson",
      "Tucson Estates",
      "Winslow",
      "Yuma"
    ],
    "AR": [
      "Arkadelphia",
      "Batesville",
      "Bella Vista",
      "Benton",
      "Bentonville",
      "Blytheville",
      "Bryant",
      "Cabot",
      "Camden",
      "Clarksville",
      "Conway",
      "Crossett",
      "El Dorado",
      "Fayetteville",
      "Forrest City",
      "Fort Smith",
      "Greenwood",
      "Harrison",
      "Heber Springs",
      "Helena",
      "Hope",
      "Hot Springs",
      "Hot Springs Village",
      "Jacksonville",
      "Jonesboro",
      "Little Rock",
      "Magnolia",
      "Malvern",
      "Marion",
      "Maumelle",
      "Monticello",
      "Morrilton",
      "Mountain Home",
      "Newport",
      "North Little Rock",
      "Osceola",
      "Paragould",
      "Pine Bluff",
      "Pocahontas",
      "Rogers",
      "Russellville",
      "Searcy",
      "Sherwood",
      "Siloam Springs",
      "Springdale",
      "Stuttgart",
      "Texarkana",
      "Trumann",
      "Van Buren",
      "Warren",
      "West Helena",
      "West Memphis",
      "Wynne"
    ],
    "CA": [
      "Adelanto",
      "Agoura Hills",
      "Alameda",
      "Alamo",
      "Albany",
      "Alhambra",
      "Aliso Viejo",
      "Alondra Park",
      "Alpine",
      "Alta Sierra",
      "Altadena",
      "Alum Rock",
      "American Canyon",
      "Anaheim",
      "Anderson",
      "Antioch",
      "Apple Valley",
      "Aptos",
      "Arcadia",
      "Arcata",
      "Arden-Arcade",
      "Arroyo Grande",
      "Artesia",
      "Arvin",
      "Ashland",
      "Atascadero",
      "Atherton",
      "Atwater",
      "Auburn",
      "August",
      "Avenal",
      "Avocado Heights",
      "Azusa",
      "Bakersfield",
      "Baldwin Park",
      "Banning",
      "Barstow",
      "Bay Point",
      "Baywood-Los Osos",
      "Beaumont",
      "Bell",
      "Bell Gardens",
      "Bellflower",
      "Belmont",
      "Benicia",
      "Berkeley",
      "Bermuda Dunes",
      "Beverly Hills",
      "Blackhawk-Camino Tassajara",
      "Bloomington",
      "Blythe",
      "Bonadelle Ranchos-Madera Ranchos",
      "Bonita",
      "Bostonia",
      "Boyes Hot Springs",
      "Brawley",
      "Brea",
      "Brentwood",
      "Buena Park",
      "Burbank",
      "Burlingame",
      "Calabasas",
      "Calexico",
      "California City",
      "Calimesa",
      "Calipatria",
      "Camarillo",
      "Cambria",
      "Cameron Park",
      "Camp Pendleton North",
      "Camp Pendleton South",
      "Campbell",
      "Canyon Lake",
      "Capitola",
      "Carlsbad",
      "Carmichael",
      "Carpinteria",
      "Carson",
      "Casa de Oro-Mount Helix",
      "Castro Valley",
      "Castroville",
      "Cathedral City",
      "Ceres",
      "Cerritos",
      "Charter Oak",
      "Cherryland",
      "Chico",
      "Chino",
      "Chino Hills",
      "Chowchilla",
      "Chula Vista",
      "Citrus",
      "Citrus Heights",
      "Claremont",
      "Clayton",
      "Clearlake",
      "Cloverdale",
      "Clovis",
      "Coachella",
      "Coalinga",
      "Colton",
      "Commerce",
      "Compton",
      "Concord",
      "Corcoran",
      "Corning",
      "Corona",
      "Coronado",
      "Corte Madera",
      "Costa Mesa",
      "Cotati",
      "Coto de Caza",
      "Country Club",
      "Covina",
      "Crestline",
      "Cudahy",
      "Culver City",
      "Cupertino",
      "Cypress",
      "Daly City",
      "Dana Point",
      "Danville",
      "Davis",
      "Del Aire",
      "Delano",
      "Delhi",
      "Desert Hot Springs",
      "Diamond Bar",
      "Dinuba",
      "Discovery Bay",
      "Dixon",
      "Downey",
      "Duarte",
      "Dublin",
      "Earlimart",
      "East Compton",
      "East Foothills",
      "East Hemet",
      "East La Mirada",
      "East Los Angeles",
      "East Palo Alto",
      "East Pasadena",
      "East Porterville",
      "East San Gabriel",
      "El Cajon",
      "El Centro",
      "El Cerrito",
      "El Dorado Hills",
      "El Monte",
      "El Paso de Robles",
      "El Rio",
      "El Segundo",
      "El Sobrante",
      "Elk Grove",
      "Emeryville",
      "Encinitas",
      "Escondido",
      "Eureka",
      "Exeter",
      "Fair Oaks",
      "Fairfax",
      "Fairfield",
      "Fairview",
      "Fallbrook",
      "Farmersville",
      "Fillmore",
      "Florence-Graham",
      "Florin",
      "Folsom",
      "Fontana",
      "Foothill Farms",
      "Foothill Ranch",
      "Fort Bragg",
      "Fortuna",
      "Foster City",
      "Fountain Valley",
      "Fremont",
      "Fresno",
      "Fullerton",
      "Galt",
      "Garden Acres",
      "Garden Grove",
      "Gardena",
      "Gilroy",
      "Glen Avon",
      "Glendale",
      "Glendora",
      "Gold River",
      "Golden Hills",
      "Goleta",
      "Gonzales",
      "Grand Terrace",
      "Granite Bay",
      "Grass Valley",
      "Greenfield",
      "Grover Beach",
      "Hacienda Heights",
      "Half Moon Bay",
      "Hanford",
      "Hawaiian Gardens",
      "Hawthorne",
      "Hayward",
      "Healdsburg",
      "Hemet",
      "Hercules",
      "Hermosa Beach",
      "Hesperia",
      "Highland",
      "Hillsborough",
      "Hollister",
      "Home Gardens",
      "Huntington Beach",
      "Huntington Park",
      "Huron",
      "Imperial",
      "Imperial Beach",
      "Indio",
      "Inglewood",
      "Interlaken",
      "Ione",
      "Irvine",
      "Isla Vista",
      "Kentfield",
      "Kerman",
      "King City",
      "Kingsburg",
      "La Canada Flintridge",
      "La Crescenta-Montrose",
      "La Habra",
      "La Mesa",
      "La Mirada",
      "La Palma",
      "La Presa",
      "La Puente",
      "La Quinta",
      "La Riviera",
      "La Verne",
      "Ladera Heights",
      "Lafayette",
      "Laguna",
      "Laguna Beach",
      "Laguna Hills",
      "Laguna Niguel",
      "Laguna West-Lakeside",
      "Laguna Woods",
      "Lake Arrowhead",
      "Lake Elsinore",
      "Lake Forest",
      "Lake Los Angeles",
      "Lakeside",
      "Lakewood",
      "Lamont",
      "Lancaster",
      "Larkfield-Wikiup",
      "Larkspur",
      "Lathrop",
      "Lawndale",
      "Lemon Grove",
      "Lemoore",
      "Lennox",
      "Lincoln",
      "Linda",
      "Lindsay",
      "Live Oak",
      "Live Oak",
      "Livermore",
      "Livingston",
      "Lodi",
      "Loma Linda",
      "Lomita",
      "Lompoc",
      "Long Beach",
      "Loomis",
      "Los Alamitos",
      "Los Altos",
      "Los Altos Hills",
      "Los Angeles",
      "Los Banos",
      "Los Gatos",
      "Lucas Valley-Marinwood",
      "Lynwood",
      "Madera",
      "Madera Acres",
      "Magalia",
      "Malibu",
      "Mammoth Lakes",
      "Manhattan Beach",
      "Manteca",
      "Marina",
      "Marina del Rey",
      "Martinez",
      "Marysville",
      "Maywood",
      "McFarland",
      "McKinleyville",
      "Mendota",
      "Menlo Park",
      "Mentone",
      "Merced",
      "Mill Valley",
      "Millbrae",
      "Milpitas",
      "Mira Loma",
      "Mira Monte",
      "Mission Viejo",
      "Modesto",
      "Monrovia",
      "Montclair",
      "Montebello",
      "Montecito",
      "Monterey",
      "Monterey Park",
      "Moorpark",
      "Moraga",
      "Moreno Valley",
      "Morgan Hill",
      "Morro Bay",
      "Mountain View",
      "Murrieta",
      "Muscoy",
      "Napa",
      "National City",
      "Newark",
      "Newman",
      "Newport Beach",
      "Nipomo",
      "Norco",
      "North Auburn",
      "North Fair Oaks",
      "North Highlands",
      "Norwalk",
      "Novato",
      "Oakdale",
      "Oakland",
      "Oakley",
      "Oceano",
      "Oceanside",
      "Oildale",
      "Ojai",
      "Olivehurst",
      "Ontario",
      "Opal Cliffs",
      "Orange",
      "Orange Cove",
      "Orangevale",
      "Orcutt",
      "Orinda",
      "Orland",
      "Orosi",
      "Oroville",
      "Oroville East",
      "Oxnard",
      "Pacific Grove",
      "Pacifica",
      "Palm Desert",
      "Palm Springs",
      "Palmdale",
      "Palo Alto",
      "Palos Verdes Estates",
      "Paradise",
      "Paramount",
      "Parkway-South Sacramento",
      "Parlier",
      "Pasadena",
      "Patterson",
      "Pedley",
      "Perris",
      "Petaluma",
      "Pico Rivera",
      "Piedmont",
      "Pinole",
      "Pismo Beach",
      "Pittsburg",
      "Placentia",
      "Placerville",
      "Pleasant Hill",
      "Pleasanton",
      "Pomona",
      "Port Hueneme",
      "Porterville",
      "Portola Hills",
      "Poway",
      "Prunedale",
      "Quartz Hill",
      "Ramona",
      "Rancho Cordova",
      "Rancho Cucamonga",
      "Rancho Mirage",
      "Rancho Palos Verdes",
      "Rancho San Diego",
      "Rancho Santa Margarita",
      "Red Bluff",
      "Redding",
      "Redlands",
      "Redondo Beach",
      "Redwood City",
      "Reedley",
      "Rialto",
      "Richmond",
      "Ridgecrest",
      "Rio del Mar",
      "Rio Linda",
      "Ripon",
      "Riverbank",
      "Riverside",
      "Rocklin",
      "Rodeo",
      "Rohnert Park",
      "Rolling Hills Estates",
      "Rosamond",
      "Rosedale",
      "Roseland",
      "Rosemead",
      "Rosemont",
      "Roseville",
      "Rossmoor",
      "Rowland Heights",
      "Rubidoux",
      "Sacramento",
      "Salida",
      "Salinas",
      "San Anselmo",
      "San Bernardino",
      "San Bruno",
      "San Buenaventura",
      "San Carlos",
      "San Clemente",
      "San Diego",
      "San Diego Country Estates",
      "San Dimas",
      "San Fernando",
      "San Francisco",
      "San Gabriel",
      "San Jacinto",
      "San Jose",
      "San Juan Capistrano",
      "San Leandro",
      "San Lorenzo",
      "San Luis Obispo",
      "San Marcos",
      "San Marino",
      "San Mateo",
      "San Pablo",
      "San Rafael",
      "San Ramon",
      "Sanger",
      "Santa Ana",
      "Santa Barbara",
      "Santa Clara",
      "Santa Clarita",
      "Santa Cruz",
      "Santa Fe Springs",
      "Santa Maria",
      "Santa Monica",
      "Santa Paula",
      "Santa Rosa",
      "Santee",
      "Saratoga",
      "Sausalito",
      "Scotts Valley",
      "Seal Beach",
      "Seaside",
      "Sebastopol",
      "Selma",
      "Shafter",
      "Shasta Lake",
      "Sierra Madre",
      "Signal Hill",
      "Simi Valley",
      "Solana Beach",
      "Soledad",
      "Sonoma",
      "South El Monte",
      "South Gate",
      "South Lake Tahoe",
      "South Oroville",
      "South Pasadena",
      "South San Francisco",
      "South San Gabriel",
      "South San Jose Hills",
      "South Whittier",
      "South Yuba City",
      "Spring Valley",
      "Stanford",
      "Stanton",
      "Stockton",
      "Suisun City",
      "Sun City",
      "Sunnyvale",
      "Susanville",
      "Taft",
      "Tamalpais-Homestead Valley",
      "Tehachapi",
      "Temecula",
      "Temple City",
      "Thermalito",
      "Thousand Oaks",
      "Tiburon",
      "Torrance",
      "Tracy",
      "Truckee",
      "Tulare",
      "Turlock",
      "Tustin",
      "Tustin Foothills",
      "Twentynine Palms",
      "Twentynine Palms Base",
      "Ukiah",
      "Union City",
      "Upland",
      "Vacaville",
      "Valinda",
      "Valle Vista",
      "Vallejo",
      "Valley Center",
      "Vandenberg AFB",
      "Victorville",
      "View Park-Windsor Hills",
      "Vincent",
      "Vineyard",
      "Visalia",
      "Vista",
      "Walnut",
      "Walnut Creek",
      "Walnut Park",
      "Wasco",
      "Waterford",
      "Watsonville",
      "West Athens",
      "West Carson",
      "West Covina",
      "West Hollywood",
      "West Modesto",
      "West Puente Valley",
      "West Sacramento",
      "West Whittier-Los Nietos",
      "Westlake Village",
      "Westminster",
      "Westmont",
      "Whittier",
      "Wildomar",
      "Willowbrook",
      "Willows",
      "Windsor",
      "Winter Gardens",
      "Winters",
      "Winton",
      "Woodcrest",
      "Woodlake",
      "Woodland",
      "Yorba Linda",
      "Yreka",
      "Yuba City",
      "Yucaipa",
      "Yucca Valley"
    ],
    "CO": [
      "Air Force Academy",
      "Alamosa",
      "Applewood",
      "Arvada",
      "Aurora",
      "Berkley",
      "Black Forest",
      "Boulder",
      "Brighton",
      "Broomfield",
      "Canon City",
      "Castle Rock",
      "Castlewood",
      "Cimarron Hills",
      "Clifton",
      "Colorado Springs",
      "Columbine",
      "Commerce City",
      "Cortez",
      "Craig",
      "Delta",
      "Denver",
      "Derby",
      "Durango",
      "Edwards",
      "Englewood",
      "Erie",
      "Evans",
      "Evergreen",
      "Federal Heights",
      "Fort Carson",
      "Fort Collins",
      "Fort Lupton",
      "Fort Morgan",
      "Fountain",
      "Fruita",
      "Fruitvale",
      "Glenwood Springs",
      "Golden",
      "Grand Junction",
      "Greeley",
      "Greenwood Village",
      "Gunbarrel",
      "Highlands Ranch",
      "Ken Caryl",
      "La Junta",
      "Lafayette",
      "Lakewood",
      "Lamar",
      "Littleton",
      "Longmont",
      "Louisville",
      "Loveland",
      "Montrose",
      "Northglenn",
      "Orchard Mesa",
      "Parker",
      "Pueblo",
      "Pueblo West",
      "Redlands",
      "Rifle",
      "Security-Widefield",
      "Sherrelwood",
      "Southglenn",
      "Steamboat Springs",
      "Sterling",
      "Stonegate",
      "Stratmoor",
      "Superior",
      "The Pinery",
      "Thornton",
      "Trinidad",
      "Twin Lakes",
      "Welby",
      "Westminster",
      "Wheat Ridge",
      "Windsor",
      "Woodland Park",
      "Woodmoor"
    ],
    "CT": [
      "Ansonia",
      "Ansonia",
      "Avon",
      "Berlin",
      "Bethel",
      "Bethel",
      "Bloomfield",
      "Branford",
      "Bridgeport",
      "Bridgeport",
      "Bristol",
      "Bristol",
      "Brookfield",
      "Brooklyn",
      "Burlington",
      "Canton",
      "Central Manchester",
      "Cheshire",
      "Clinton",
      "Colchester",
      "Conning Towers-Nautilus Park",
      "Coventry",
      "Cromwell",
      "Danbury",
      "Danbury",
      "Darien",
      "Darien",
      "Derby",
      "Derby",
      "Durham",
      "East Haddam",
      "East Hampton",
      "East Hartford",
      "East Hartford",
      "East Haven",
      "East Haven",
      "East Lyme",
      "East Windsor",
      "Easton",
      "Ellington",
      "Enfield",
      "Essex",
      "Fairfield",
      "Farmington",
      "Glastonbury",
      "Glastonbury Center",
      "Granby",
      "Greenwich",
      "Griswold",
      "Groton",
      "Groton",
      "Guilford",
      "Haddam",
      "Hamden",
      "Hartford",
      "Hartford",
      "Hebron",
      "Kensington",
      "Killingly",
      "Killingworth",
      "Lebanon",
      "Ledyard",
      "Litchfield",
      "Madison",
      "Manchester",
      "Mansfield",
      "Meriden",
      "Meriden",
      "Middlebury",
      "Middletown",
      "Middletown",
      "Milford",
      "Milford",
      "Monroe",
      "Montville",
      "Naugatuck",
      "Naugatuck",
      "New Britain",
      "New Britain",
      "New Canaan",
      "New Fairfield",
      "New Hartford",
      "New Haven",
      "New Haven",
      "New London",
      "New London",
      "New Milford",
      "New Milford",
      "Newington",
      "Newington",
      "Newtown",
      "North Branford",
      "North Haven",
      "North Haven",
      "Norwalk",
      "Norwalk",
      "Norwich",
      "Norwich",
      "Oakville",
      "Old Lyme",
      "Old Saybrook",
      "Orange",
      "Orange",
      "Oxford",
      "Plainfield",
      "Plainville",
      "Plymouth",
      "Portland",
      "Prospect",
      "Putnam",
      "Putnam District",
      "Redding",
      "Ridgefield",
      "Ridgefield",
      "Rockville",
      "Rocky Hill",
      "Seymour",
      "Shelton",
      "Shelton",
      "Simsbury",
      "Somers",
      "South Windsor",
      "Southbury",
      "Southington",
      "Southwood Acres",
      "Stafford",
      "Stamford",
      "Stamford",
      "Stonington",
      "Storrs",
      "Stratford",
      "Stratford",
      "Suffield",
      "Thomaston",
      "Thompson",
      "Thompsonville",
      "Tolland",
      "Torrington",
      "Torrington",
      "Trumbull",
      "Trumbull",
      "Vernon",
      "Wallingford",
      "Wallingford Center",
      "Waterbury",
      "Waterbury",
      "Waterford",
      "Watertown",
      "West Hartford",
      "West Hartford",
      "West Haven",
      "West Haven",
      "Westbrook",
      "Weston",
      "Westport",
      "Westport",
      "Wethersfield",
      "Wethersfield",
      "Willimantic",
      "Wilton",
      "Winchester",
      "Windham",
      "Windsor",
      "Windsor Locks",
      "Windsor Locks",
      "Winsted",
      "Wolcott",
      "Woodbridge",
      "Woodbury",
      "Woodstock"
    ],
    "DE": [
      "Bear",
      "Brookside",
      "Claymont",
      "Dover",
      "Glasgow",
      "Hockessin",
      "Middletown",
      "Milford",
      "Newark",
      "North Star",
      "Pike Creek",
      "Seaford",
      "Wilmington",
      "Wilmington Manor"
    ],
    "FL": [
      "Alachua",
      "Altamonte Springs",
      "Andover",
      "Apollo Beach",
      "Apopka",
      "Arcadia",
      "Atlantic Beach",
      "Auburndale",
      "Aventura",
      "Avon Park",
      "Azalea Park",
      "Bartow",
      "Bayonet Point",
      "Bayshore Gardens",
      "Beacon Square",
      "Bee Ridge",
      "Bellair-Meadowbrook Terrace",
      "Belle Glade",
      "Bellview",
      "Beverly Hills",
      "Bloomingdale",
      "Boca Del Mar",
      "Boca Raton",
      "Bonita Springs",
      "Boynton Beach",
      "Bradenton",
      "Brandon",
      "Brent",
      "Broadview Park",
      "Brooksville",
      "Brownsville",
      "Callaway",
      "Cape Canaveral",
      "Cape Coral",
      "Carol City",
      "Casselberry",
      "Century Village",
      "Cheval",
      "Citrus Park",
      "Citrus Ridge",
      "Clearwater",
      "Clermont",
      "Clewiston",
      "Cocoa",
      "Cocoa Beach",
      "Coconut Creek",
      "Collier Manor-Cresthaven",
      "Conway",
      "Cooper City",
      "Coral Gables",
      "Coral Springs",
      "Coral Terrace",
      "Country Club",
      "Country Walk",
      "Crestview",
      "Cutler",
      "Cutler Ridge",
      "Cypress Gardens",
      "Cypress Lake",
      "Dade City",
      "Dania Beach",
      "Davie",
      "Daytona Beach",
      "De Bary",
      "De Land",
      "Deerfield Beach",
      "Delray Beach",
      "Deltona",
      "Destin",
      "Doctor Phillips",
      "Doral",
      "Dunedin",
      "East Lake",
      "East Perrine",
      "Edgewater",
      "Eglin AFB",
      "Egypt Lake-Leto",
      "Elfers",
      "Englewood",
      "Ensley",
      "Estero",
      "Eustis",
      "Fairview Shores",
      "Fern Park",
      "Fernandina Beach",
      "Ferry Pass",
      "Florida City",
      "Florida Ridge",
      "Forest City",
      "Fort Lauderdale",
      "Fort Myers",
      "Fort Myers Beach",
      "Fort Pierce",
      "Fort Pierce North",
      "Fort Walton Beach",
      "Fountainbleau",
      "Fruit Cove",
      "Fruitville",
      "Gainesville",
      "Gibsonton",
      "Gifford",
      "Gladeview",
      "Glenvar Heights",
      "Golden Gate",
      "Golden Glades",
      "Golden Lakes",
      "Goldenrod",
      "Gonzalez",
      "Goulds",
      "Greater Carrollwood",
      "Greater Northdale",
      "Greater Sun Center",
      "Greenacres",
      "Gulf Gate Estates",
      "Gulfport",
      "Haines City",
      "Hallandale",
      "Hamptons at Boca Raton",
      "Hernando",
      "Hialeah",
      "Hialeah Gardens",
      "Hobe Sound",
      "Holiday",
      "Holly Hill",
      "Hollywood",
      "Homestead",
      "Homosassa Springs",
      "Hudson",
      "Hunters Creek",
      "Immokalee",
      "Indian Harbour Beach",
      "Inverness",
      "Inwood",
      "Iona",
      "Islamorada",
      "Ives Estates",
      "Jacksonville",
      "Jacksonville Beach",
      "Jasmine Estates",
      "Jensen Beach",
      "Jupiter",
      "Kendale Lakes",
      "Kendall",
      "Kendall West",
      "Key Biscayne",
      "Key Largo",
      "Key West",
      "Keystone",
      "Kings Point",
      "Kissimmee",
      "Lady Lake",
      "Lake Butter",
      "Lake City",
      "Lake Lorraine",
      "Lake Lucerne",
      "Lake Magdalene",
      "Lake Mary",
      "Lake Park",
      "Lake Wales",
      "Lake Worth",
      "Lake Worth Corridor",
      "Lakeland",
      "Lakeland Highlands",
      "Lakes by the Bay",
      "Lakeside",
      "Lakewood Park",
      "Land O’ Lakes",
      "Lantana",
      "Largo",
      "Lauderdale Lakes",
      "Lauderhill",
      "Laurel",
      "Leesburg",
      "Lehigh Acres",
      "Leisure City",
      "Lighthouse Point",
      "Live Oak",
      "Lockhart",
      "Longboat Key",
      "Longwood",
      "Lutz",
      "Lynn Haven",
      "Maitland",
      "Mango",
      "Marathon",
      "Marco Island",
      "Margate",
      "Marianna",
      "McGregor",
      "Meadow Woods",
      "Medulla",
      "Melbourne",
      "Melrose Park",
      "Memphis",
      "Merritt Island",
      "Miami",
      "Miami Beach",
      "Miami Lakes",
      "Miami Shores",
      "Miami Springs",
      "Micco",
      "Middleburg",
      "Milton",
      "Mims",
      "Miramar",
      "Mount Dora",
      "Myrtle Grove",
      "Naples",
      "Naples Park",
      "Neptune Beach",
      "New Port Richey",
      "New Port Richey East",
      "New Smyrna Beach",
      "Niceville",
      "Norland",
      "North Andrews Gardens",
      "North Bay Village",
      "North Fort Myers",
      "North Lauderdale",
      "North Miami",
      "North Miami Beach",
      "North Palm Beach",
      "North Port",
      "North Sarasota",
      "Oak Ridge",
      "Oakland Park",
      "Ocala",
      "Ocoee",
      "Ojus",
      "Oldsmar",
      "Olympia Heights",
      "Opa-locka",
      "Opa-locka North",
      "Orange City",
      "Orange Park",
      "Orlando",
      "Orlovista",
      "Ormond Beach",
      "Ormond-By-The-Sea",
      "Oviedo",
      "Pace",
      "Palatka",
      "Palm Bay",
      "Palm Beach",
      "Palm Beach Gardens",
      "Palm City",
      "Palm Coast",
      "Palm Harbor",
      "Palm River-Clair Mel",
      "Palm Springs",
      "Palm Valley",
      "Palmetto",
      "Palmetto Estates",
      "Panama City",
      "Panama City Beach",
      "Parkland",
      "Pembroke Park",
      "Pembroke Pines",
      "Pensacola",
      "Perry",
      "Pine Castle",
      "Pine Hills",
      "Pinecrest",
      "Pinellas Park",
      "Pinewood",
      "Plant City",
      "Plantation",
      "Poinciana",
      "Pompano Beach",
      "Pompano Beach Highlands",
      "Port Charlotte",
      "Port Orange",
      "Port Salerno",
      "Port St. John",
      "Port St. Lucie",
      "Princeton",
      "Punta Gorda",
      "Quincy",
      "Richmond Heights",
      "Richmond West",
      "Riverview",
      "Riviera Beach",
      "Rockledge",
      "Rotonda",
      "Royal Palm Beach",
      "Ruskin",
      "Safety Harbor",
      "San Carlos Park",
      "Sandalfoot Cove",
      "Sanford",
      "Sanibel",
      "Sarasota",
      "Sarasota Springs",
      "Satellite Beach",
      "Scott Lake",
      "Sebastian",
      "Sebring",
      "Seminole",
      "Shady Hills",
      "Siesta Key",
      "Silver Springs Shores",
      "South Bradenton",
      "South Daytona",
      "South Highpoint",
      "South Miami",
      "South Miami Heights",
      "South Patrick Shores",
      "South Venice",
      "Southeast Arcadia",
      "Southgate",
      "Spring Hill",
      "Springfield",
      "St. Augustine",
      "St. Cloud",
      "St. Pete Beach",
      "St. Petersburg",
      "Stuart",
      "Sugarmill Woods",
      "Sunny Isles Beach",
      "Sunrise",
      "Sunset",
      "Sweetwater",
      "Tallahassee",
      "Tamarac",
      "Tamiami",
      "Tampa",
      "Tarpon Springs",
      "Tavares",
      "Temple Terrace",
      "The Crossings",
      "The Hammocks",
      "The Villages",
      "Thonotosassa",
      "Three Lakes",
      "Titusville",
      "Town 'n' Country",
      "Treasure Island",
      "Union Park",
      "University",
      "University Park",
      "Upper Grand Lagoon",
      "Valparaiso",
      "Valrico",
      "Venice",
      "Venice Gardens",
      "Vero Beach",
      "Vero Beach South",
      "Villas",
      "Warrington",
      "Wekiwa Springs",
      "Wellington",
      "West and East Lealman",
      "West Little River",
      "West Melbourne",
      "West Palm Beach",
      "West Pensacola",
      "West Perrine",
      "West Vero Corridor",
      "Westchase",
      "Westchester",
      "Westgate-Belvedere Homes",
      "Weston",
      "Westview",
      "Westwood Lakes",
      "Williamsburg",
      "Wilton Manors",
      "Winston",
      "Winter Garden",
      "Winter Haven",
      "Winter Park",
      "Winter Springs",
      "Wright",
      "Yeehaw Junction",
      "Yulee",
      "Zephyrhills"
    ],
    "GA": [
      "Acworth",
      "Albany",
      "Alpharetta",
      "Americus",
      "Athens-Clarke County",
      "Atlanta",
      "Auburn",
      "Augusta-Richmond County",
      "Bainbridge",
      "Belvedere Park",
      "Brunswick",
      "Buford",
      "Cairo",
      "Calhoun",
      "Candler-McAfee",
      "Canton",
      "Carrollton",
      "Cartersville",
      "Cedartown",
      "Chamblee",
      "Clarkston",
      "College Park",
      "Columbus",
      "Conley",
      "Conyers",
      "Cordele",
      "Country Club Estates",
      "Covington",
      "Dalton",
      "Decatur",
      "Dock Junction",
      "Doraville",
      "Douglas",
      "Douglasville",
      "Druid Hills",
      "Dublin",
      "Duluth",
      "Dunwoody",
      "East Point",
      "Eatonton",
      "Evans",
      "Fair Oaks",
      "Fairview",
      "Fayetteville",
      "Fitzgerald",
      "Forest Park",
      "Fort Benning South",
      "Fort Oglethorpe",
      "Fort Stewart",
      "Fort Valley",
      "Gainesville",
      "Garden City",
      "Georgetown",
      "Gresham Park",
      "Griffin",
      "Grovetown",
      "Hapeville",
      "Hinesville",
      "Irondale",
      "Jesup",
      "Kennesaw",
      "Kingsland",
      "La Fayette",
      "LaGrange",
      "Lawrenceville",
      "Lilburn",
      "Mableton",
      "Macon",
      "Marietta",
      "Martinez",
      "McDonough",
      "Milledgeville",
      "Monroe",
      "Moultrie",
      "Mountain Park",
      "Newnan",
      "Norcross",
      "North Atlanta",
      "North Decatur",
      "North Druid Hills",
      "Panthersville",
      "Peachtree City",
      "Perry",
      "Pooler",
      "Powder Springs",
      "Redan",
      "Richmond Hill",
      "Riverdale",
      "Rome",
      "Roswell",
      "Sandersville",
      "Sandy Springs",
      "Savannah",
      "Scottdale",
      "Skidaway Island",
      "Smyrna",
      "Snellville",
      "St. Marys",
      "St. Simons",
      "Statesboro",
      "Stockbridge",
      "Stone Mountain",
      "Sugar Hill",
      "Suwanee",
      "Swainsboro",
      "Thomaston",
      "Thomasville",
      "Thomson",
      "Tifton",
      "Toccoa",
      "Tucker",
      "Union City",
      "Valdosta",
      "Vidalia",
      "Vinings",
      "Warner Robins",
      "Waycross",
      "Wilmington Island",
      "Winder",
      "Woodstock"
    ],
    "HI": [
      "Ahuimanu",
      "Aiea",
      "Ewa Beach",
      "Haiku-Pauwela",
      "Halawa",
      "Hawaiian Paradise Park",
      "Hilo",
      "Holualoa",
      "Honolulu",
      "Kahului",
      "Kailua",
      "Kailua",
      "Kalaoa",
      "Kaneohe",
      "Kaneohe Station",
      "Kapaa",
      "Kihei",
      "Lahaina",
      "Makaha",
      "Makakilo City",
      "Makawao",
      "Mililani Town",
      "Nanakuli",
      "Napili-Honokowai",
      "Pearl City",
      "Pukalani",
      "Schofield Barracks",
      "Village Park",
      "Wahiawa",
      "Waianae",
      "Waihee-Waiehu",
      "Wailuku",
      "Waimalu",
      "Waimea",
      "Waipahu",
      "Waipio"
    ],
    "ID": [
      "Ammon",
      "Blackfoot",
      "Boise City",
      "Burley",
      "Caldwell",
      "Chubbuck",
      "Coeur d’Alene",
      "Eagle",
      "Garden City",
      "Hailey",
      "Hayden",
      "Idaho Falls",
      "Jerome",
      "Lewiston",
      "Meridian",
      "Moscow",
      "Mountain Home",
      "Mountain Home AFB",
      "Nampa",
      "Payette",
      "Pocatello",
      "Post Falls",
      "Rexburg",
      "Sandpoint",
      "Twin Falls"
    ],
    "IL": [
      "Addison",
      "Algonquin",
      "Alsip",
      "Alton",
      "Antioch",
      "Arlington Heights",
      "Aurora",
      "Barrington",
      "Bartlett",
      "Bartonville",
      "Batavia",
      "Beach Park",
      "Belleville",
      "Bellwood",
      "Belvidere",
      "Bensenville",
      "Benton",
      "Berwyn",
      "Bethalto",
      "Bloomingdale",
      "Bloomington",
      "Blue Island",
      "Bolingbrook",
      "Boulder Hill",
      "Bourbonnais",
      "Bradley",
      "Bridgeview",
      "Broadview",
      "Brookfield",
      "Buffalo Grove",
      "Burbank",
      "Burr Ridge",
      "Cahokia",
      "Calumet City",
      "Calumet Park",
      "Canton",
      "Carbondale",
      "Carol Stream",
      "Carpentersville",
      "Cary",
      "Centralia",
      "Champaign",
      "Channahon",
      "Charleston",
      "Chatham",
      "Chicago",
      "Chicago Heights",
      "Chicago Ridge",
      "Cicero",
      "Clarendon Hills",
      "Clinton",
      "Collinsville",
      "Columbia",
      "Country Club Hills",
      "Crest Hill",
      "Crestwood",
      "Crete",
      "Crystal Lake",
      "Danville",
      "Darien",
      "Decatur",
      "Deerfield",
      "DeKalb",
      "Des Plaines",
      "Dixon",
      "Dolton",
      "Downers Grove",
      "Du Quoin",
      "East Alton",
      "East Moline",
      "East Peoria",
      "East St. Louis",
      "Edwardsville",
      "Effingham",
      "Elgin",
      "Elk Grove Village",
      "Elmhurst",
      "Elmwood Park",
      "Evanston",
      "Evergreen Park",
      "Fairview Heights",
      "Flossmoor",
      "Forest Park",
      "Fox Lake",
      "Frankfort",
      "Frankfort Square",
      "Franklin Park",
      "Freeport",
      "Gages Lake",
      "Galesburg",
      "Geneseo",
      "Geneva",
      "Glen Carbon",
      "Glen Ellyn",
      "Glencoe",
      "Glendale Heights",
      "Glenview",
      "Glenwood",
      "Godfrey",
      "Goodings Grove",
      "Granite City",
      "Grayslake",
      "Greenville",
      "Gurnee",
      "Hanover Park",
      "Harrisburg",
      "Harvard",
      "Harvey",
      "Harwood Heights",
      "Hawthorn Woods",
      "Hazel Crest",
      "Herrin",
      "Hickory Hills",
      "Highland",
      "Highland Park",
      "Hillside",
      "Hinsdale",
      "Hoffman Estates",
      "Homewood",
      "Inverness",
      "Island Lake",
      "Itasca",
      "Jacksonville",
      "Jerseyville",
      "Joliet",
      "Justice",
      "Kankakee",
      "Kewanee",
      "La Grange",
      "La Grange Park",
      "La Salle",
      "Lake Bluff",
      "Lake Forest",
      "Lake in the Hills",
      "Lake Zurich",
      "Lansing",
      "Lemont",
      "Libertyville",
      "Lincoln",
      "Lincolnshire",
      "Lincolnwood",
      "Lindenhurst",
      "Lisle",
      "Litchfield",
      "Lockport",
      "Lombard",
      "Long Grove",
      "Loves Park",
      "Lynwood",
      "Lyons",
      "Machesney Park",
      "Macomb",
      "Manteno",
      "Marengo",
      "Marion",
      "Markham",
      "Matteson",
      "Mattoon",
      "Maywood",
      "McHenry",
      "Melrose Park",
      "Mendota",
      "Metropolis",
      "Midlothian",
      "Mokena",
      "Moline",
      "Monmouth",
      "Morris",
      "Morton",
      "Morton Grove",
      "Mount Carmel",
      "Mount Prospect",
      "Mount Vernon",
      "Mundelein",
      "Murphysboro",
      "Naperville",
      "New Lenox",
      "Niles",
      "Normal",
      "Norridge",
      "North Aurora",
      "North Chicago",
      "North Riverside",
      "Northbrook",
      "Northlake",
      "O’Fallon",
      "Oak Brook",
      "Oak Forest",
      "Oak Lawn",
      "Oak Park",
      "Olney",
      "Orland Hills",
      "Orland Park",
      "Oswego",
      "Ottawa",
      "Palatine",
      "Palos Heights",
      "Palos Hills",
      "Paris",
      "Park City",
      "Park Forest",
      "Park Ridge",
      "Pekin",
      "Peoria",
      "Peoria Heights",
      "Peru",
      "Plainfield",
      "Pontiac",
      "Princeton",
      "Prospect Heights",
      "Quincy",
      "Rantoul",
      "Richton Park",
      "River Forest",
      "River Grove",
      "Riverdale",
      "Riverside",
      "Robbins",
      "Robinson",
      "Rochelle",
      "Rock Falls",
      "Rock Island",
      "Rockford",
      "Rolling Meadows",
      "Romeoville",
      "Roscoe",
      "Roselle",
      "Round Lake Beach",
      "Round Lake Park",
      "Salem",
      "Sandwich",
      "Sauk Village",
      "Schaumburg",
      "Schiller Park",
      "Shiloh",
      "Shorewood",
      "Silvis",
      "Skokie",
      "South Elgin",
      "South Holland",
      "Springfield",
      "St. Charles",
      "Steger",
      "Sterling",
      "Stickney",
      "Streamwood",
      "Streator",
      "Summit",
      "Swansea",
      "Sycamore",
      "Taylorville",
      "Tinley Park",
      "Troy",
      "University Park",
      "Urbana",
      "Vandalia",
      "Vernon Hills",
      "Villa Park",
      "Warrenville",
      "Washington",
      "Waterloo",
      "Wauconda",
      "Waukegan",
      "West Chicago",
      "West Frankfort",
      "Westchester",
      "Western Springs",
      "Westmont",
      "Wheaton",
      "Wheeling",
      "Willowbrook",
      "Wilmette",
      "Winfield",
      "Winnetka",
      "Winthrop Harbor",
      "Wonder Lake",
      "Wood Dale",
      "Wood River",
      "Woodridge",
      "Woodstock",
      "Worth",
      "Yorkville",
      "Zion"
    ],
    "IN": [
      "Alexandria",
      "Anderson",
      "Angola",
      "Auburn",
      "Avon",
      "Batesville",
      "Bedford",
      "Beech Grove",
      "Bloomington",
      "Bluffton",
      "Boonville",
      "Brazil",
      "Brownsburg",
      "Carmel",
      "Cedar Lake",
      "Chesterton",
      "Clarksville",
      "Columbia City",
      "Columbus",
      "Connersville",
      "Crawfordsville",
      "Crown Point",
      "Danville",
      "Decatur",
      "Dyer",
      "East Chicago",
      "Elkhart",
      "Elwood",
      "Evansville",
      "Fishers",
      "Fort Wayne",
      "Frankfort",
      "Franklin",
      "Gary",
      "Goshen",
      "Granger",
      "Greencastle",
      "Greenfield",
      "Greensburg",
      "Greenwood",
      "Griffith",
      "Hammond",
      "Hartford City",
      "Highland",
      "Hobart",
      "Huntington",
      "Indianapolis",
      "Jasper",
      "Jeffersonville",
      "Kendallville",
      "Kokomo",
      "La Porte",
      "Lafayette",
      "Lake Station",
      "Lakes of the Four Seasons",
      "Lawrence",
      "Lebanon",
      "Logansport",
      "Lowell",
      "Madison",
      "Marion",
      "Martinsville",
      "Merrillville",
      "Michigan City",
      "Mishawaka",
      "Mooresville",
      "Mount Vernon",
      "Muncie",
      "Munster",
      "Nappanee",
      "New Albany",
      "New Castle",
      "New Haven",
      "Noblesville",
      "North Manchester",
      "North Vernon",
      "Peru",
      "Plainfield",
      "Plymouth",
      "Portage",
      "Portland",
      "Princeton",
      "Richmond",
      "Rochester",
      "Salem",
      "Schererville",
      "Scottsburg",
      "Sellersburg",
      "Seymour",
      "Shelbyville",
      "South Bend",
      "Speedway",
      "St. John",
      "Tell City",
      "Terre Haute",
      "Valparaiso",
      "Vincennes",
      "Wabash",
      "Warsaw",
      "Washington",
      "West Lafayette",
      "Westfield",
      "Zionsville"
    ],
    "IA": [
      "Altoona",
      "Ames",
      "Ankeny",
      "Atlantic",
      "Bettendorf",
      "Boone",
      "Burlington",
      "Carroll",
      "Cedar Falls",
      "Cedar Rapids",
      "Charles City",
      "Clear Lake",
      "Clinton",
      "Clive",
      "Coralville",
      "Council Bluffs",
      "Creston",
      "Davenport",
      "Decorah",
      "Denison",
      "Des Moines",
      "Dubuque",
      "Estherville",
      "Fairfield",
      "Fort Dodge",
      "Fort Madison",
      "Grinnell",
      "Hiawatha",
      "Independence",
      "Indianola",
      "Iowa City",
      "Johnston",
      "Keokuk",
      "Knoxville",
      "Le Mars",
      "Maquoketa",
      "Marion",
      "Marshalltown",
      "Mason City",
      "Mount Pleasant",
      "Muscatine",
      "Nevada",
      "Newton",
      "Norwalk",
      "Oelwein",
      "Oskaloosa",
      "Ottumwa",
      "Pella",
      "Perry",
      "Red Oak",
      "Sioux Center",
      "Sioux City",
      "Spencer",
      "Storm Lake",
      "Urbandale",
      "Washington",
      "Waterloo",
      "Waverly",
      "Webster City",
      "West Des Moines"
    ],
    "KS": [
      "Abilene",
      "Andover",
      "Arkansas City",
      "Atchison",
      "Augusta",
      "Bonner Springs",
      "Chanute",
      "Coffeyville",
      "Derby",
      "Dodge City",
      "El Dorado",
      "Emporia",
      "Fort Riley North",
      "Fort Scott",
      "Garden City",
      "Gardner",
      "Great Bend",
      "Hays",
      "Haysville",
      "Hutchinson",
      "Independence",
      "Iola",
      "Junction City",
      "Kansas City",
      "Lansing",
      "Lawrence",
      "Leavenworth",
      "Leawood",
      "Lenexa",
      "Liberal",
      "Manhattan",
      "McPherson",
      "Merriam",
      "Mission",
      "Newton",
      "Olathe",
      "Ottawa",
      "Overland Park",
      "Parsons",
      "Pittsburg",
      "Prairie Village",
      "Pratt",
      "Roeland Park",
      "Salina",
      "Shawnee",
      "Topeka",
      "Wellington",
      "Wichita",
      "Winfield"
    ],
    "KY": [
      "Alexandria",
      "Ashland",
      "Bardstown",
      "Bellevue",
      "Berea",
      "Bowling Green",
      "Buechel",
      "Burlington",
      "Campbellsville",
      "Corbin",
      "Covington",
      "Cynthiana",
      "Danville",
      "Edgewood",
      "Elizabethtown",
      "Elsmere",
      "Erlanger",
      "Fairdale",
      "Fern Creek",
      "Flatwoods",
      "Florence",
      "Fort Campbell North",
      "Fort Knox",
      "Fort Mitchell",
      "Fort Thomas",
      "Frankfort",
      "Franklin",
      "Georgetown",
      "Glasgow",
      "Harrodsburg",
      "Henderson",
      "Highland Heights",
      "Highview",
      "Hillview",
      "Hopkinsville",
      "Independence",
      "Jeffersontown",
      "Lawrenceburg",
      "Leitchfield",
      "Lexington-Fayette",
      "Louisville",
      "Lyndon",
      "Madisonville",
      "Mayfield",
      "Maysville",
      "Middlesborough",
      "Mount Washington",
      "Murray",
      "Newburg",
      "Newport",
      "Nicholasville",
      "Oak Grove",
      "Oakbrook",
      "Okolona",
      "Owensboro",
      "Paducah",
      "Paris",
      "Pikeville",
      "Pleasure Ridge Park",
      "Princeton",
      "Radcliff",
      "Richmond",
      "Russellville",
      "Shelbyville",
      "Shepherdsville",
      "Shively",
      "Somerset",
      "St. Dennis",
      "St. Matthews",
      "Taylor Mill",
      "Valley Station",
      "Versailles",
      "Villa Hills",
      "Winchester"
    ],
    "LA": [
      "Abbeville",
      "Alexandria",
      "Arabi",
      "Baker",
      "Bastrop",
      "Baton Rouge",
      "Bayou Cane",
      "Belle Chasse",
      "Bogalusa",
      "Bossier City",
      "Breaux Bridge",
      "Bridge City",
      "Brownsville-Bawcomville",
      "Carencro",
      "Chalmette",
      "Claiborne",
      "Covington",
      "Crowley",
      "De Ridder",
      "Denham Springs",
      "Destrehan",
      "Donaldsonville",
      "Eden Isle",
      "Estelle",
      "Eunice",
      "Fort Polk South",
      "Franklin",
      "Galliano",
      "Gardere",
      "Gonzales",
      "Gretna",
      "Hammond",
      "Harahan",
      "Harvey",
      "Houma",
      "Jefferson",
      "Jennings",
      "Kenner",
      "Lacombe",
      "Lafayette",
      "Lake Charles",
      "Laplace",
      "Larose",
      "Leesville",
      "Luling",
      "Mandeville",
      "Marrero",
      "Meraux",
      "Merrydale",
      "Metairie",
      "Minden",
      "Monroe",
      "Morgan City",
      "Moss Bluff",
      "Natchitoches",
      "New Iberia",
      "New Orleans",
      "Oak Hills Place",
      "Oakdale",
      "Opelousas",
      "Pineville",
      "Plaquemine",
      "Prien",
      "Raceland",
      "Rayne",
      "Reserve",
      "River Ridge",
      "Ruston",
      "Scott",
      "Shenandoah",
      "Shreveport",
      "Slidell",
      "St. Martinville",
      "St. Rose",
      "Sulphur",
      "Tallulah",
      "Terrytown",
      "Thibodaux",
      "Timberlane",
      "Village St. George",
      "Ville Platte",
      "Violet",
      "Waggaman",
      "West Monroe",
      "Westwego",
      "Woodmere",
      "Zachary"
    ],
    "ME": [
      "Auburn",
      "Augusta",
      "Bangor",
      "Bath",
      "Belfast",
      "Berwick",
      "Biddeford",
      "Brewer",
      "Brunswick",
      "Brunswick",
      "Buxton",
      "Cape Elizabeth",
      "Caribou",
      "Cumberland",
      "Ellsworth",
      "Fairfield",
      "Falmouth",
      "Farmington",
      "Freeport",
      "Gardiner",
      "Gorham",
      "Gray",
      "Hampden",
      "Houlton",
      "Kennebunk",
      "Kittery",
      "Lewiston",
      "Lisbon",
      "Old Orchard Beach",
      "Old Orchard Beach",
      "Old Town",
      "Orono",
      "Orono",
      "Portland",
      "Presque Isle",
      "Rockland",
      "Rumford",
      "Saco",
      "Sanford",
      "Sanford",
      "Scarborough",
      "Skowhegan",
      "Skowhegan",
      "South Berwick",
      "South Portland",
      "Standish",
      "Topsham",
      "Topsham",
      "Waterboro",
      "Waterville",
      "Wells",
      "Westbrook",
      "Windham",
      "Winslow",
      "Winslow",
      "Winthrop",
      "Yarmouth",
      "York"
    ],
    "MD": [
      "Aberdeen",
      "Accokeek",
      "Adelphi",
      "Andrews AFB",
      "Annapolis",
      "Arbutus",
      "Arnold",
      "Aspen Hill",
      "Ballenger Creek",
      "Baltimore",
      "Bel Air",
      "Bel Air North",
      "Bel Air South",
      "Beltsville",
      "Bennsville",
      "Bethesda",
      "Bladensburg",
      "Bowie",
      "Bowleys Quarters",
      "Brooklyn Park",
      "Burtonsville",
      "California",
      "Calverton",
      "Cambridge",
      "Camp Springs",
      "Cape St. Claire",
      "Carney",
      "Catonsville",
      "Chesapeake Ranch Estates-Drum Point",
      "Cheverly",
      "Chevy Chase",
      "Chillum",
      "Clinton",
      "Cloverly",
      "Cockeysville",
      "Colesville",
      "College Park",
      "Columbia",
      "Coral Hills",
      "Crofton",
      "Cumberland",
      "Damascus",
      "Darnestown",
      "Dundalk",
      "East Riverdale",
      "Easton",
      "Edgemere",
      "Edgewood",
      "Eldersburg",
      "Elkridge",
      "Elkton",
      "Ellicott City",
      "Essex",
      "Fairland",
      "Fallston",
      "Ferndale",
      "Forest Glen",
      "Forestville",
      "Fort Meade",
      "Fort Washington",
      "Frederick",
      "Friendly",
      "Frostburg",
      "Gaithersburg",
      "Garrison",
      "Germantown",
      "Glen Burnie",
      "Glenarden",
      "Glenn Dale",
      "Greater Landover",
      "Greater Upper Marlboro",
      "Green Haven",
      "Green Valley",
      "Greenbelt",
      "Hagerstown",
      "Halfway",
      "Havre de Grace",
      "Hillcrest Heights",
      "Hyattsville",
      "Jessup",
      "Joppatowne",
      "Kemp Mill",
      "Kettering",
      "La Plata",
      "Lake Arbor",
      "Lake Shore",
      "Langley Park",
      "Lanham-Seabrook",
      "Lansdowne-Baltimore Highlands",
      "Largo",
      "Laurel",
      "Lexington Park",
      "Linganore-Bartonsville",
      "Linthicum",
      "Lochearn",
      "Londontowne",
      "Lutherville-Timonium",
      "Marlow Heights",
      "Marlton",
      "Maryland City",
      "Mays Chapel",
      "Middle River",
      "Milford Mill",
      "Mitchellville",
      "Montgomery Village",
      "Mount Airy",
      "Mount Rainier",
      "New Carrollton",
      "North Bethesda",
      "North Kensington",
      "North Laurel",
      "North Potomac",
      "Ocean City",
      "Ocean Pines",
      "Odenton",
      "Olney",
      "Overlea",
      "Owings Mills",
      "Oxon Hill-Glassmanor",
      "Parkville",
      "Parole",
      "Pasadena",
      "Perry Hall",
      "Pikesville",
      "Potomac",
      "Randallstown",
      "Redland",
      "Reisterstown",
      "Riverdale Park",
      "Riverside",
      "Riviera Beach",
      "Rockville",
      "Rosaryville",
      "Rosedale",
      "Rossmoor",
      "Rossville",
      "Salisbury",
      "Savage-Guilford",
      "Severn",
      "Severna Park",
      "Silver Spring",
      "South Gate",
      "South Kensington",
      "South Laurel",
      "St. Charles",
      "Suitland-Silver Hill",
      "Takoma Park",
      "Temple Hills",
      "Towson",
      "Travilah",
      "Waldorf",
      "Walker Mill",
      "Westminster",
      "Wheaton-Glenmont",
      "White Marsh",
      "White Oak",
      "Woodlawn",
      "Woodlawn",
      "Woodmore"
    ],
    "MA": [
      "Abington",
      "Abington",
      "Acton",
      "Acushnet",
      "Adams",
      "Agawam",
      "Amesbury",
      "Amesbury",
      "Amherst",
      "Amherst Center",
      "Andover",
      "Andover",
      "Arlington",
      "Arlington",
      "Ashland",
      "Athol",
      "Athol",
      "Attleboro",
      "Auburn",
      "Ayer",
      "Barnstable Town",
      "Bedford",
      "Belchertown",
      "Bellingham",
      "Belmont",
      "Belmont",
      "Beverly",
      "Billerica",
      "Blackstone",
      "Boston",
      "Bourne",
      "Boxford",
      "Braintree",
      "Braintree",
      "Brewster",
      "Bridgewater",
      "Bridgewater",
      "Brockton",
      "Brookline",
      "Brookline",
      "Burlington",
      "Burlington",
      "Cambridge",
      "Canton",
      "Carver",
      "Charlton",
      "Chatham",
      "Chelmsford",
      "Chelsea",
      "Chicopee",
      "Clinton",
      "Clinton",
      "Cochituate",
      "Cohasset",
      "Concord",
      "Dalton",
      "Danvers",
      "Danvers",
      "Dartmouth",
      "Dedham",
      "Dedham",
      "Dennis",
      "Dighton",
      "Douglas",
      "Dracut",
      "Dudley",
      "Duxbury",
      "East Bridgewater",
      "East Falmouth",
      "East Longmeadow",
      "Easthampton",
      "Easton",
      "Everett",
      "Fairhaven",
      "Fall River",
      "Falmouth",
      "Fitchburg",
      "Foxborough",
      "Framingham",
      "Framingham",
      "Franklin",
      "Freetown",
      "Gardner",
      "Georgetown",
      "Gloucester",
      "Grafton",
      "Granby",
      "Great Barrington",
      "Greenfield",
      "Greenfield",
      "Groton",
      "Groveland",
      "Halifax",
      "Hamilton",
      "Hanover",
      "Hanson",
      "Harwich",
      "Haverhill",
      "Hingham",
      "Holbrook",
      "Holbrook",
      "Holden",
      "Holliston",
      "Holyoke",
      "Hopkinton",
      "Hudson",
      "Hudson",
      "Hull",
      "Hull",
      "Ipswich",
      "Kingston",
      "Lakeville",
      "Lancaster",
      "Lawrence",
      "Leicester",
      "Leominster",
      "Lexington",
      "Lexington",
      "Lincoln",
      "Littleton",
      "Longmeadow",
      "Longmeadow",
      "Lowell",
      "Ludlow",
      "Lunenburg",
      "Lynn",
      "Lynnfield",
      "Lynnfield",
      "Malden",
      "Mansfield",
      "Mansfield Center",
      "Marblehead",
      "Marblehead",
      "Marlborough",
      "Marshfield",
      "Mashpee",
      "Mattapoisett",
      "Maynard",
      "Maynard",
      "Medfield",
      "Medfield",
      "Medford",
      "Medway",
      "Melrose",
      "Merrimac",
      "Methuen",
      "Middleborough",
      "Middleborough Center",
      "Middleton",
      "Milford",
      "Milford",
      "Millbury",
      "Millis",
      "Milton",
      "Milton",
      "Monson",
      "Montague",
      "Nantucket",
      "Natick",
      "Needham",
      "Needham",
      "New Bedford",
      "Newbury",
      "Newburyport",
      "Newton",
      "Norfolk",
      "North Adams",
      "North Amherst",
      "North Andover",
      "North Attleborough",
      "North Attleborough Center",
      "North Reading",
      "Northampton",
      "Northborough",
      "Northborough",
      "Northbridge",
      "Norton",
      "Norwell",
      "Norwood",
      "Norwood",
      "Orange",
      "Orleans",
      "Oxford",
      "Palmer",
      "Peabody",
      "Pembroke",
      "Pepperell",
      "Pinehurst",
      "Pittsfield",
      "Plainville",
      "Plymouth",
      "Plymouth",
      "Quincy",
      "Randolph",
      "Randolph",
      "Raynham",
      "Reading",
      "Reading",
      "Rehoboth",
      "Revere",
      "Rockland",
      "Rockport",
      "Rutland",
      "Salem",
      "Salisbury",
      "Sandwich",
      "Saugus",
      "Saugus",
      "Scituate",
      "Seekonk",
      "Sharon",
      "Shirley",
      "Shrewsbury",
      "Somerset",
      "Somerset",
      "Somerville",
      "South Hadley",
      "South Yarmouth",
      "Southborough",
      "Southbridge",
      "Southbridge",
      "Southwick",
      "Spencer",
      "Spencer",
      "Springfield",
      "Sterling",
      "Stoneham",
      "Stoneham",
      "Stoughton",
      "Sturbridge",
      "Sudbury",
      "Sutton",
      "Swampscott",
      "Swampscott",
      "Swansea",
      "Taunton",
      "Templeton",
      "Tewksbury",
      "Topsfield",
      "Townsend",
      "Tyngsborough",
      "Uxbridge",
      "Wakefield",
      "Wakefield",
      "Walpole",
      "Waltham",
      "Ware",
      "Ware",
      "Wareham",
      "Watertown",
      "Wayland",
      "Webster",
      "Webster",
      "Wellesley",
      "Wellesley",
      "West Boylston",
      "West Bridgewater",
      "West Springfield",
      "West Springfield",
      "West Yarmouth",
      "Westborough",
      "Westfield",
      "Westford",
      "Westminster",
      "Weston",
      "Westport",
      "Westwood",
      "Weymouth",
      "Weymouth",
      "Whitinsville",
      "Whitman",
      "Wilbraham",
      "Williamstown",
      "Wilmington",
      "Wilmington",
      "Winchendon",
      "Winchester",
      "Winchester",
      "Winthrop",
      "Winthrop",
      "Woburn",
      "Worcester",
      "Wrentham",
      "Yarmouth"
    ],
    "MI": [
      "Adrian",
      "Albion",
      "Allen Park",
      "Allendale",
      "Alma",
      "Alpena",
      "Ann Arbor",
      "Auburn Hills",
      "Battle Creek",
      "Bay City",
      "Beecher",
      "Benton Harbor",
      "Berkley",
      "Beverly Hills",
      "Big Rapids",
      "Birmingham",
      "Bloomfield Township",
      "Bridgeport",
      "Brighton",
      "Buena Vista",
      "Burton",
      "Cadillac",
      "Canton",
      "Carrollton",
      "Center Line",
      "Charlotte",
      "Clawson",
      "Clinton",
      "Coldwater",
      "Comstock Park",
      "Cutlerville",
      "Dearborn",
      "Dearborn Heights",
      "Detroit",
      "Dowagiac",
      "East Grand Rapids",
      "East Lansing",
      "Eastpointe",
      "Eastwood",
      "Ecorse",
      "Escanaba",
      "Fair Plain",
      "Farmington",
      "Farmington Hills",
      "Fenton",
      "Ferndale",
      "Flat Rock",
      "Flint",
      "Flushing",
      "Forest Hills",
      "Fraser",
      "Garden City",
      "Grand Blanc",
      "Grand Haven",
      "Grand Ledge",
      "Grand Rapids",
      "Grandville",
      "Greenville",
      "Grosse Ile",
      "Grosse Pointe Farms",
      "Grosse Pointe Park",
      "Grosse Pointe Woods",
      "Hamtramck",
      "Harper Woods",
      "Harrison",
      "Haslett",
      "Hastings",
      "Hazel Park",
      "Highland Park",
      "Hillsdale",
      "Holland",
      "Holly",
      "Holt",
      "Houghton",
      "Howell",
      "Hudsonville",
      "Huntington Woods",
      "Inkster",
      "Ionia",
      "Iron Mountain",
      "Ironwood",
      "Ishpeming",
      "Jackson",
      "Jenison",
      "Kalamazoo",
      "Kentwood",
      "Lambertville",
      "Lansing",
      "Lapeer",
      "Lincoln Park",
      "Livonia",
      "Ludington",
      "Madison Heights",
      "Manistee",
      "Marquette",
      "Marshall",
      "Marysville",
      "Mason",
      "Melvindale",
      "Menominee",
      "Midland",
      "Milford",
      "Monroe",
      "Mount Clemens",
      "Mount Pleasant",
      "Muskegon",
      "Muskegon Heights",
      "New Baltimore",
      "Niles",
      "Northview",
      "Northville",
      "Norton Shores",
      "Novi",
      "Oak Park",
      "Okemos",
      "Owosso",
      "Petoskey",
      "Plymouth",
      "Plymouth Township",
      "Pontiac",
      "Port Huron",
      "Portage",
      "Redford",
      "River Rouge",
      "Riverview",
      "Rochester",
      "Rochester Hills",
      "Romulus",
      "Roseville",
      "Royal Oak",
      "Saginaw",
      "Saginaw Township North",
      "Saginaw Township South",
      "Saline",
      "Sault Ste. Marie",
      "Shelby",
      "Shields",
      "South Lyon",
      "South Monroe",
      "Southfield",
      "Southgate",
      "St. Clair Shores",
      "St. Johns",
      "St. Joseph",
      "Sterling Heights",
      "Sturgis",
      "Taylor",
      "Tecumseh",
      "Temperance",
      "Three Rivers",
      "Traverse City",
      "Trenton",
      "Troy",
      "Walker",
      "Walled Lake",
      "Warren",
      "Waterford",
      "Waverly",
      "Wayne",
      "West Bloomfield Township",
      "Westland",
      "Westwood",
      "Whitmore Lake",
      "Wixom",
      "Woodhaven",
      "Wyandotte",
      "Wyoming",
      "Ypsilanti"
    ],
    "MN": [
      "Albert Lea",
      "Alexandria",
      "Andover",
      "Anoka",
      "Apple Valley",
      "Arden Hills",
      "Austin",
      "Bemidji",
      "Big Lake",
      "Blaine",
      "Bloomington",
      "Brainerd",
      "Brooklyn Center",
      "Brooklyn Park",
      "Buffalo",
      "Burnsville",
      "Champlin",
      "Chanhassen",
      "Chaska",
      "Cloquet",
      "Columbia Heights",
      "Coon Rapids",
      "Cottage Grove",
      "Crookston",
      "Crystal",
      "Detroit Lakes",
      "Duluth",
      "Eagan",
      "East Bethel",
      "East Grand Forks",
      "Eden Prairie",
      "Edina",
      "Elk River",
      "Fairmont",
      "Faribault",
      "Farmington",
      "Fergus Falls",
      "Forest Lake",
      "Fridley",
      "Golden Valley",
      "Grand Rapids",
      "Ham Lake",
      "Hastings",
      "Hermantown",
      "Hibbing",
      "Hopkins",
      "Hugo",
      "Hutchinson",
      "International Falls",
      "Inver Grove Heights",
      "Lake Elmo",
      "Lakeville",
      "Lino Lakes",
      "Litchfield",
      "Little Canada",
      "Little Falls",
      "Mahtomedi",
      "Mankato",
      "Maple Grove",
      "Maplewood",
      "Marshall",
      "Mendota Heights",
      "Minneapolis",
      "Minnetonka",
      "Monticello",
      "Moorhead",
      "Mound",
      "Mounds View",
      "New Brighton",
      "New Hope",
      "New Ulm",
      "North Branch",
      "North Mankato",
      "North St. Paul",
      "Northfield",
      "Oak Grove",
      "Oakdale",
      "Orono",
      "Otsego",
      "Owatonna",
      "Plymouth",
      "Prior Lake",
      "Ramsey",
      "Red Wing",
      "Richfield",
      "Robbinsdale",
      "Rochester",
      "Rosemount",
      "Roseville",
      "Sartell",
      "Sauk Rapids",
      "Savage",
      "Shakopee",
      "Shoreview",
      "Shorewood",
      "South St. Paul",
      "Spring Lake Park",
      "St. Anthony",
      "St. Cloud",
      "St. Louis Park",
      "St. Michael",
      "St. Paul",
      "St. Peter",
      "Stillwater",
      "Thief River Falls",
      "Vadnais Heights",
      "Virginia",
      "Waconia",
      "Waite Park",
      "Waseca",
      "West St. Paul",
      "White Bear Lake",
      "Willmar",
      "Winona",
      "Woodbury",
      "Worthington"
    ],
    "MS": [
      "Aberdeen",
      "Amory",
      "Batesville",
      "Bay St. Louis",
      "Biloxi",
      "Booneville",
      "Brandon",
      "Brookhaven",
      "Byram",
      "Canton",
      "Clarksdale",
      "Cleveland",
      "Clinton",
      "Columbia",
      "Columbus",
      "Corinth",
      "D’Iberville",
      "Gautier",
      "Greenville",
      "Greenwood",
      "Grenada",
      "Gulfport",
      "Hattiesburg",
      "Hernando",
      "Holly Springs",
      "Horn Lake",
      "Indianola",
      "Jackson",
      "Kosciusko",
      "Laurel",
      "Long Beach",
      "Louisville",
      "Madison",
      "McComb",
      "Meridian",
      "Moss Point",
      "Natchez",
      "New Albany",
      "Ocean Springs",
      "Olive Branch",
      "Oxford",
      "Pascagoula",
      "Pass Christian",
      "Pearl",
      "Petal",
      "Philadelphia",
      "Picayune",
      "Richland",
      "Ridgeland",
      "Senatobia",
      "Southaven",
      "St. Martin",
      "Starkville",
      "Tupelo",
      "Vicksburg",
      "Waveland",
      "West Hattiesburg",
      "West Point",
      "Yazoo City"
    ],
    "MO": [
      "Affton",
      "Arnold",
      "Aurora",
      "Ballwin",
      "Barnhart",
      "Bellefontaine Neighbors",
      "Belton",
      "Berkeley",
      "Black Jack",
      "Blue Springs",
      "Bolivar",
      "Boonville",
      "Branson",
      "Brentwood",
      "Bridgeton",
      "Cameron",
      "Cape Girardeau",
      "Carthage",
      "Caruthersville",
      "Chesterfield",
      "Chillicothe",
      "Clayton",
      "Clinton",
      "Columbia",
      "Concord",
      "Crestwood",
      "Creve Coeur",
      "De Soto",
      "Des Peres",
      "Dexter",
      "Ellisville",
      "Eureka",
      "Excelsior Springs",
      "Farmington",
      "Ferguson",
      "Festus",
      "Florissant",
      "Fort Leonard Wood",
      "Fulton",
      "Gladstone",
      "Grandview",
      "Hannibal",
      "Harrisonville",
      "Hazelwood",
      "Independence",
      "Jackson",
      "Jefferson City",
      "Jennings",
      "Joplin",
      "Kansas City",
      "Kennett",
      "Kirksville",
      "Kirkwood",
      "Ladue",
      "Lake St. Louis",
      "Lebanon",
      "Lee’s Summit",
      "Lemay",
      "Liberty",
      "Manchester",
      "Maplewood",
      "Marshall",
      "Maryland Heights",
      "Maryville",
      "Mehlville",
      "Mexico",
      "Moberly",
      "Monett",
      "Murphy",
      "Neosho",
      "Nevada",
      "Nixa",
      "O’Fallon",
      "Oakville",
      "Olivette",
      "Overland",
      "Ozark",
      "Park Hills",
      "Perryville",
      "Poplar Bluff",
      "Raymore",
      "Raytown",
      "Republic",
      "Richmond",
      "Richmond Heights",
      "Rolla",
      "Sappington",
      "Sedalia",
      "Shrewsbury",
      "Sikeston",
      "Spanish Lake",
      "Springfield",
      "St. Ann",
      "St. Charles",
      "St. John",
      "St. Joseph",
      "St. Louis",
      "St. Peters",
      "Sullivan",
      "Sunset Hills",
      "Town and Country",
      "Trenton",
      "Troy",
      "Union",
      "University City",
      "Valley Park",
      "Warrensburg",
      "Washington",
      "Webb City",
      "Webster Groves",
      "Wentzville",
      "West Plains",
      "Wildwood"
    ],
    "MT": [
      "Anaconda-Deer Lodge County",
      "Billings",
      "Bozeman",
      "Butte-Silver Bow",
      "Evergreen",
      "Great Falls",
      "Havre",
      "Helena",
      "Helena Valley Southeast",
      "Helena Valley West Central",
      "Kalispell",
      "Laurel",
      "Livingston",
      "Miles City",
      "Missoula"
    ],
    "NE": [
      "Alliance",
      "Beatrice",
      "Bellevue",
      "Blair",
      "Chalco",
      "Columbus",
      "Crete",
      "Elkhorn",
      "Fremont",
      "Gering",
      "Grand Island",
      "Hastings",
      "Kearney",
      "La Vista",
      "Lexington",
      "Lincoln",
      "McCook",
      "Nebraska City",
      "Norfolk",
      "North Platte",
      "Offutt AFB",
      "Omaha",
      "Papillion",
      "Plattsmouth",
      "Ralston",
      "Scottsbluff",
      "Seward",
      "Sidney",
      "South Sioux City",
      "York"
    ],
    "NV": [
      "Boulder City",
      "Carson City",
      "Elko",
      "Enterprise",
      "Fallon",
      "Fernley",
      "Gardnerville Ranchos",
      "Henderson",
      "Incline Village-Crystal Bay",
      "Las Vegas",
      "Laughlin",
      "Lemmon Valley-Golden Valley",
      "Mesquite",
      "Nellis AFB",
      "North Las Vegas",
      "Pahrump",
      "Paradise",
      "Reno",
      "Spanish Springs",
      "Sparks",
      "Spring Creek",
      "Spring Valley",
      "Sun Valley",
      "Sunrise Manor",
      "Whitney",
      "Winchester",
      "Winnemucca"
    ],
    "NH": [
      "Amherst",
      "Atkinson",
      "Barrington",
      "Bedford",
      "Belmont",
      "Berlin",
      "Bow",
      "Claremont",
      "Concord",
      "Conway",
      "Derry",
      "Derry",
      "Dover",
      "Durham",
      "Durham",
      "Exeter",
      "Exeter",
      "Franklin",
      "Gilford",
      "Goffstown",
      "Hampstead",
      "Hampton",
      "Hampton",
      "Hanover",
      "Hanover",
      "Hollis",
      "Hooksett",
      "Hudson",
      "Hudson",
      "Keene",
      "Laconia",
      "Lebanon",
      "Litchfield",
      "Londonderry",
      "Londonderry",
      "Manchester",
      "Merrimack",
      "Milford",
      "Milford",
      "Nashua",
      "Newmarket",
      "Newport",
      "Pelham",
      "Pembroke",
      "Plaistow",
      "Portsmouth",
      "Raymond",
      "Rochester",
      "Salem",
      "Seabrook",
      "Somersworth",
      "Stratham",
      "Swanzey",
      "Weare",
      "Windham",
      "Wolfeboro"
    ],
    "NJ": [
      "Absecon",
      "Allendale",
      "Asbury Park",
      "Ashland",
      "Atlantic City",
      "Audubon",
      "Avenel",
      "Barclay-Kingston",
      "Barrington",
      "Bayonne",
      "Beachwood",
      "Belleville",
      "Bellmawr",
      "Belmar",
      "Bergenfield",
      "Berkeley Heights",
      "Berlin",
      "Bernardsville",
      "Bloomfield",
      "Bloomingdale",
      "Bogota",
      "Boonton",
      "Bound Brook",
      "Bridgeton",
      "Brigantine",
      "Browns Mills",
      "Budd Lake",
      "Burlington",
      "Butler",
      "Caldwell",
      "Camden",
      "Carneys Point",
      "Carteret",
      "Cedar Grove",
      "Chatham",
      "Cherry Hill Mall",
      "Clark",
      "Clayton",
      "Cliffside Park",
      "Clifton",
      "Closter",
      "Collingswood",
      "Colonia",
      "Cranford",
      "Cresskill",
      "Crestwood Village",
      "Dayton",
      "Dover",
      "Dumont",
      "Dunellen",
      "East Brunswick",
      "East Orange",
      "East Rutherford",
      "Eatontown",
      "Echelon",
      "Edgewater",
      "Edison",
      "Elizabeth",
      "Elmwood Park",
      "Emerson",
      "Englewood",
      "Erlton-Ellisburg",
      "Ewing",
      "Fair Lawn",
      "Fairfield",
      "Fairview",
      "Fanwood",
      "Florence-Roebling",
      "Florham Park",
      "Fords",
      "Fort Dix",
      "Fort Lee",
      "Franklin Lakes",
      "Freehold",
      "Garfield",
      "Glassboro",
      "Glen Ridge",
      "Glen Rock",
      "Gloucester City",
      "Greentree",
      "Guttenberg",
      "Hackensack",
      "Hackettstown",
      "Haddon Heights",
      "Haddonfield",
      "Haledon",
      "Hammonton",
      "Harrison",
      "Hasbrouck Heights",
      "Hawthorne",
      "Highland Park",
      "Hillsdale",
      "Hillside",
      "Hoboken",
      "Holiday City-Berkeley",
      "Hopatcong",
      "Irvington",
      "Iselin",
      "Jamesburg",
      "Jersey City",
      "Keansburg",
      "Kearny",
      "Kendall Park",
      "Kenilworth",
      "Keyport",
      "Kinnelon",
      "Lake Mohawk",
      "Lakewood",
      "Laurence Harbor",
      "Leisure Village West-Pine Lake Park",
      "Leonia",
      "Lincoln Park",
      "Lincroft",
      "Linden",
      "Lindenwold",
      "Linwood",
      "Little Falls",
      "Little Ferry",
      "Little Silver",
      "Livingston",
      "Lodi",
      "Long Branch",
      "Lyndhurst",
      "Madison",
      "Madison Park",
      "Manasquan",
      "Manville",
      "Maplewood",
      "Margate City",
      "Marlton",
      "Matawan",
      "Maywood",
      "McGuire AFB",
      "Mercerville-Hamilton Square",
      "Metuchen",
      "Middlesex",
      "Midland Park",
      "Millburn",
      "Milltown",
      "Millville",
      "Montclair",
      "Montvale",
      "Moorestown-Lenola",
      "Morganville",
      "Morristown",
      "Mountainside",
      "Mystic Island",
      "New Brunswick",
      "New Milford",
      "New Providence",
      "Newark",
      "Newton",
      "North Arlington",
      "North Brunswick Township",
      "North Caldwell",
      "North Haledon",
      "North Plainfield",
      "Northfield",
      "Nutley",
      "Oakland",
      "Ocean Acres",
      "Ocean City",
      "Old Bridge",
      "Oradell",
      "Orange",
      "Palisades Park",
      "Palmyra",
      "Paramus",
      "Park Ridge",
      "Passaic",
      "Paterson",
      "Paulsboro",
      "Pennsauken",
      "Pennsville",
      "Perth Amboy",
      "Phillipsburg",
      "Pine Hill",
      "Pitman",
      "Plainfield",
      "Pleasantville",
      "Point Pleasant",
      "Pompton Lakes",
      "Princeton",
      "Princeton Meadows",
      "Rahway",
      "Ramblewood",
      "Ramsey",
      "Raritan",
      "Red Bank",
      "Ridgefield",
      "Ridgefield Park",
      "Ridgewood",
      "Ringwood",
      "River Edge",
      "River Vale",
      "Rockaway",
      "Roselle",
      "Roselle Park",
      "Rumson",
      "Runnemede",
      "Rutherford",
      "Saddle Brook",
      "Sayreville",
      "Scotch Plains",
      "Secaucus",
      "Somers Point",
      "Somerset",
      "Somerville",
      "South Amboy",
      "South Orange",
      "South Plainfield",
      "South River",
      "Spotswood",
      "Springdale",
      "Springfield",
      "Stratford",
      "Strathmore",
      "Succasunna-Kenvil",
      "Summit",
      "Teaneck",
      "Tenafly",
      "Tinton Falls",
      "Toms River",
      "Totowa",
      "Trenton",
      "Twin Rivers",
      "Union",
      "Union Beach",
      "Union City",
      "Upper Saddle River",
      "Ventnor City",
      "Verona",
      "Villas",
      "Vineland",
      "Waldwick",
      "Wallington",
      "Wanaque",
      "Washington",
      "Washington Township",
      "Wayne",
      "West Caldwell",
      "West Freehold",
      "West Long Branch",
      "West Milford",
      "West New York",
      "West Orange",
      "West Paterson",
      "Westfield",
      "Westwood",
      "Wharton",
      "White Horse",
      "White Meadow Lake",
      "Williamstown",
      "Wood-Ridge",
      "Woodbridge",
      "Woodbury",
      "Wyckoff",
      "Yardville-Groveville",
      "Yorketown"
    ],
    "NM": [
      "Alamogordo",
      "Albuquerque",
      "Anthony",
      "Artesia",
      "Aztec",
      "Belen",
      "Bernalillo",
      "Bloomfield",
      "Carlsbad",
      "Chaparral",
      "Clovis",
      "Corrales",
      "Deming",
      "Espanola",
      "Farmington",
      "Gallup",
      "Grants",
      "Hobbs",
      "Kirtland",
      "Las Cruces",
      "Las Vegas",
      "Los Alamos",
      "Los Lunas",
      "Lovington",
      "North Valley",
      "Portales",
      "Raton",
      "Rio Rancho",
      "Roswell",
      "Ruidoso",
      "Santa Fe",
      "Shiprock",
      "Silver City",
      "Socorro",
      "South Valley",
      "Sunland Park",
      "Truth or Consequences",
      "White Rock",
      "Zuni Pueblo"
    ],
    "NY": [
      "Airmont",
      "Albany",
      "Albion",
      "Albion",
      "Alden",
      "Allegany",
      "Altamont",
      "Amherst",
      "Amityville",
      "Amsterdam",
      "Arcadia",
      "Arlington",
      "Attica",
      "Auburn",
      "Aurora",
      "Avon",
      "Babylon",
      "Babylon",
      "Baldwin",
      "Baldwin Harbor",
      "Baldwinsville",
      "Ballston",
      "Barton",
      "Batavia",
      "Bath",
      "Bay Shore",
      "Bayport",
      "Bayville",
      "Baywood",
      "Beacon",
      "Bedford",
      "Beekman",
      "Bellmore",
      "Bethlehem",
      "Bethpage",
      "Big Flats",
      "Binghamton",
      "Blooming Grove",
      "Bohemia",
      "Boston",
      "Brentwood",
      "Briarcliff Manor",
      "Brighton",
      "Brighton",
      "Brockport",
      "Bronxville",
      "Brookhaven",
      "Brunswick",
      "Buffalo",
      "Busti",
      "Cairo",
      "Camillus",
      "Canandaigua",
      "Canandaigua",
      "Canton",
      "Carmel",
      "Catskill",
      "Cazenovia",
      "Cedarhurst",
      "Center Moriches",
      "Centereach",
      "Central Islip",
      "Chappaqua",
      "Cheektowaga",
      "Cheektowaga",
      "Chenango",
      "Chester",
      "Chestnut Ridge",
      "Chili",
      "Cicero",
      "Clarence",
      "Clarkson",
      "Clarkstown",
      "Claverack",
      "Clay",
      "Clifton Park",
      "Cobleskill",
      "Coeymans",
      "Cohoes",
      "Collins",
      "Colonie",
      "Colonie",
      "Commack",
      "Concord",
      "Congers",
      "Copiague",
      "Coram",
      "Corning",
      "Corning",
      "Cornwall",
      "Cortland",
      "Cortlandt",
      "Cortlandville",
      "Coxsackie",
      "Crawford",
      "Croton-on-Hudson",
      "De Witt",
      "Deer Park",
      "Deerpark",
      "Delmar",
      "Depew",
      "Dix Hills",
      "Dobbs Ferry",
      "Dover",
      "Dryden",
      "Dunkirk",
      "East Aurora",
      "East Fishkill",
      "East Glenville",
      "East Greenbush",
      "East Hampton",
      "East Hills",
      "East Islip",
      "East Massapequa",
      "East Meadow",
      "East Northport",
      "East Patchogue",
      "East Rochester",
      "East Rochester",
      "East Rockaway",
      "Eastchester",
      "Eastchester",
      "Eden",
      "Elbridge",
      "Ellicott",
      "Elma",
      "Elmira",
      "Elmira",
      "Elmont",
      "Elwood",
      "Endicott",
      "Endwell",
      "Erwin",
      "Esopus",
      "Evans",
      "Fairmount",
      "Fallsburg",
      "Farmingdale",
      "Farmington",
      "Farmingville",
      "Fenton",
      "Fishkill",
      "Floral Park",
      "Fort Ann",
      "Fort Drum",
      "Fort Salonga",
      "Frankfort",
      "Franklin Square",
      "Fredonia",
      "Freeport",
      "Fulton",
      "Garden City",
      "Garden City Park",
      "Gates",
      "Gates-North Gates",
      "Geddes",
      "Geneseo",
      "Geneseo",
      "Geneva",
      "German Flatts",
      "Glen Cove",
      "Glens Falls",
      "Glens Falls North",
      "Glenville",
      "Gloversville",
      "Goshen",
      "Gouverneur",
      "Granby",
      "Grand Island",
      "Granville",
      "Great Neck",
      "Great Neck Plaza",
      "Greece",
      "Greece",
      "Greenburgh",
      "Greenfield",
      "Greenlawn",
      "Greenville",
      "Guilderland",
      "Halfmoon",
      "Hamburg",
      "Hamburg",
      "Hamlin",
      "Hampton Bays",
      "Hanover",
      "Harrison",
      "Harrison",
      "Hartsdale",
      "Hastings",
      "Hastings-on-Hudson",
      "Hauppauge",
      "Haverstraw",
      "Haverstraw",
      "Hempstead",
      "Hempstead",
      "Henrietta",
      "Herkimer",
      "Herkimer",
      "Hewlett",
      "Hicksville",
      "Highlands",
      "Hillcrest",
      "Holbrook",
      "Holtsville",
      "Homer",
      "Hoosick",
      "Hornell",
      "Horseheads",
      "Horseheads",
      "Hudson",
      "Hudson Falls",
      "Huntington",
      "Huntington",
      "Huntington Station",
      "Hurley",
      "Hyde Park",
      "Ilion",
      "Inwood",
      "Irondequoit",
      "Irondequoit",
      "Irvington",
      "Islip",
      "Islip",
      "Ithaca",
      "Ithaca",
      "Jamestown",
      "Jefferson Valley-Yorktown",
      "Jericho",
      "Johnson City",
      "Johnstown",
      "Johnstown",
      "Kenmore",
      "Kent",
      "Kinderhook",
      "Kings Park",
      "Kingsbury",
      "Kingston",
      "Kirkland",
      "Kiryas Joel",
      "La Grange",
      "Lackawanna",
      "Lake Carmel",
      "Lake Grove",
      "Lake Ronkonkoma",
      "Lancaster",
      "Lancaster",
      "Lansing",
      "Larchmont",
      "Lawrence",
      "Le Ray",
      "Le Roy",
      "Lee",
      "Lenox",
      "Levittown",
      "Lewisboro",
      "Lewiston",
      "Liberty",
      "Lindenhurst",
      "Livonia",
      "Lloyd",
      "Lockport",
      "Lockport",
      "Long Beach",
      "Lynbrook",
      "Lysander",
      "Macedon",
      "Mahopac",
      "Malone",
      "Malone",
      "Malta",
      "Malverne",
      "Mamakating",
      "Mamaroneck",
      "Mamaroneck",
      "Manchester",
      "Manhasset",
      "Manlius",
      "Manorhaven",
      "Manorville",
      "Marcellus",
      "Marcy",
      "Marlborough",
      "Massapequa",
      "Massapequa Park",
      "Massena",
      "Massena",
      "Mastic",
      "Mastic Beach",
      "Mattydale",
      "Mayfield",
      "Mechanicstown",
      "Medford",
      "Medina",
      "Melville",
      "Mendon",
      "Merrick",
      "Middle Island",
      "Middletown",
      "Miller Place",
      "Milo",
      "Milton",
      "Mineola",
      "Monroe",
      "Monroe",
      "Monsey",
      "Montgomery",
      "Monticello",
      "Moreau",
      "Mount Hope",
      "Mount Ivy",
      "Mount Kisco",
      "Mount Kisco",
      "Mount Pleasant",
      "Mount Sinai",
      "Mount Vernon",
      "Murray",
      "Nanuet",
      "Nesconset",
      "New Cassel",
      "New Castle",
      "New City",
      "New Hartford",
      "New Hyde Park",
      "New Paltz",
      "New Paltz",
      "New Rochelle",
      "New Scotland",
      "New Windsor",
      "New Windsor",
      "New York",
      "Newark",
      "Newburgh",
      "Newburgh",
      "Newfane",
      "Newstead",
      "Niagara",
      "Niagara Falls",
      "Niskayuna",
      "North Amityville",
      "North Babylon",
      "North Bay Shore",
      "North Bellmore",
      "North Bellport",
      "North Castle",
      "North Elba",
      "North Greenbush",
      "North Hempstead",
      "North Lindenhurst",
      "North Massapequa",
      "North Merrick",
      "North New Hyde Park",
      "North Patchogue",
      "North Syracuse",
      "North Tonawanda",
      "North Valley Stream",
      "North Wantagh",
      "Northport",
      "Norwich",
      "Nyack",
      "Oakdale",
      "Oceanside",
      "Ogden",
      "Ogdensburg",
      "Olean",
      "Oneida",
      "Oneonta",
      "Onondaga",
      "Ontario",
      "Orange Lake",
      "Orangetown",
      "Orchard Park",
      "Ossining",
      "Ossining",
      "Oswego",
      "Oswego",
      "Owego",
      "Oyster Bay",
      "Oyster Bay",
      "Palmyra",
      "Parma",
      "Patchogue",
      "Patterson",
      "Pawling",
      "Pearl River",
      "Peekskill",
      "Pelham",
      "Pelham",
      "Pendleton",
      "Penfield",
      "Perinton",
      "Perry",
      "Peru",
      "Phelps",
      "Philipstown",
      "Pittsford",
      "Plainedge",
      "Plainview",
      "Plattekill",
      "Plattsburgh",
      "Plattsburgh",
      "Pleasant Valley",
      "Pleasantville",
      "Pomfret",
      "Pompey",
      "Port Chester",
      "Port Jefferson",
      "Port Jefferson Station",
      "Port Jervis",
      "Port Washington",
      "Porter",
      "Potsdam",
      "Potsdam",
      "Poughkeepsie",
      "Poughkeepsie",
      "Putnam Valley",
      "Queensbury",
      "Ramapo",
      "Red Hook",
      "Rensselaer",
      "Rhinebeck",
      "Ridge",
      "Ridgeway",
      "Riverhead",
      "Riverhead",
      "Rochester",
      "Rochester",
      "Rockville Centre",
      "Rocky Point",
      "Rome",
      "Ronkonkoma",
      "Roosevelt",
      "Rosendale",
      "Roslyn Heights",
      "Rotterdam",
      "Rotterdam",
      "Royalton",
      "Rye",
      "Rye",
      "Rye Brook",
      "Salamanca",
      "Salina",
      "Salisbury",
      "Sand Lake",
      "Saratoga Springs",
      "Saugerties",
      "Sayville",
      "Scarsdale",
      "Scarsdale",
      "Schaghticoke",
      "Schenectady",
      "Schodack",
      "Schroeppel",
      "Scotchtown",
      "Scotia",
      "Scriba",
      "Seaford",
      "Selden",
      "Seneca Falls",
      "Seneca Falls",
      "Setauket-East Setauket",
      "Shawangunk",
      "Shirley",
      "Sidney",
      "Skaneateles",
      "Sleepy Hollow",
      "Smithtown",
      "Smithtown",
      "Sodus",
      "Solvay",
      "Somers",
      "Sound Beach",
      "South Farmingdale",
      "South Hill",
      "South Huntington",
      "South Lockport",
      "Southampton",
      "Southeast",
      "Southold",
      "Southport",
      "Southport",
      "Spring Valley",
      "St. James",
      "Stillwater",
      "Stony Brook",
      "Stony Point",
      "Stony Point",
      "Suffern",
      "Sullivan",
      "Sweden",
      "Syosset",
      "Syracuse",
      "Tappan",
      "Tarrytown",
      "Terryville",
      "Thompson",
      "Tonawanda",
      "Tonawanda",
      "Tonawanda",
      "Troy",
      "Tuckahoe",
      "Ulster",
      "Union",
      "Uniondale",
      "Utica",
      "Valley Cottage",
      "Valley Stream",
      "Van Buren",
      "Verona",
      "Vestal",
      "Victor",
      "Volney",
      "Wading River",
      "Walden",
      "Wallkill",
      "Walworth",
      "Wantagh",
      "Wappinger",
      "Warwick",
      "Warwick",
      "Waterford",
      "Waterloo",
      "Watertown",
      "Watervliet",
      "Wawarsing",
      "Wawayanda",
      "Webster",
      "Wellsville",
      "West Babylon",
      "West Glens Falls",
      "West Haverstraw",
      "West Hempstead",
      "West Islip",
      "West Point",
      "West Seneca",
      "West Seneca",
      "Westbury",
      "Westmere",
      "Westmoreland",
      "Wheatfield",
      "White Plains",
      "Whitestown",
      "Williamson",
      "Williston Park",
      "Wilna",
      "Wilton",
      "Windsor",
      "Woodbury",
      "Woodbury",
      "Woodmere",
      "Woodstock",
      "Wyandanch",
      "Yonkers",
      "Yorktown",
      "Yorktown Heights"
    ],
    "NC": [
      "Albemarle",
      "Apex",
      "Archdale",
      "Asheboro",
      "Asheville",
      "Belmont",
      "Black Mountain",
      "Boone",
      "Brevard",
      "Burlington",
      "Carrboro",
      "Cary",
      "Chapel Hill",
      "Charlotte",
      "Clayton",
      "Clemmons",
      "Clinton",
      "Concord",
      "Conover",
      "Cornelius",
      "Davidson",
      "Dunn",
      "Durham",
      "Eden",
      "Elizabeth City",
      "Elon College",
      "Fayetteville",
      "Forest City",
      "Fort Bragg",
      "Fuquay-Varina",
      "Garner",
      "Gastonia",
      "Goldsboro",
      "Graham",
      "Greensboro",
      "Greenville",
      "Half Moon",
      "Hamlet",
      "Havelock",
      "Henderson",
      "Hendersonville",
      "Hickory",
      "High Point",
      "Holly Springs",
      "Hope Mills",
      "Huntersville",
      "Indian Trail",
      "Jacksonville",
      "Kannapolis",
      "Kernersville",
      "Kings Grant",
      "Kings Mountain",
      "Kinston",
      "Laurinburg",
      "Lenoir",
      "Lewisville",
      "Lexington",
      "Lincolnton",
      "Lumberton",
      "Masonboro",
      "Matthews",
      "Mebane",
      "Mint Hill",
      "Monroe",
      "Mooresville",
      "Morehead City",
      "Morganton",
      "Mount Airy",
      "Mount Holly",
      "Murraysville",
      "Myrtle Grove",
      "New Bern",
      "Newton",
      "Oak Island",
      "Oxford",
      "Pinehurst",
      "Piney Green",
      "Raleigh",
      "Reidsville",
      "Roanoke Rapids",
      "Rockingham",
      "Rocky Mount",
      "Roxboro",
      "Salisbury",
      "Sanford",
      "Shelby",
      "Siler City",
      "Smithfield",
      "Southern Pines",
      "Spring Lake",
      "St. Stephens",
      "Statesville",
      "Summerfield",
      "Tarboro",
      "Thomasville",
      "Trinity",
      "Wake Forest",
      "Washington",
      "Waynesville",
      "Weddington",
      "Wilmington",
      "Wilson",
      "Winston-Salem"
    ],
    "ND": [
      "Bismarck",
      "Devils Lake",
      "Dickinson",
      "Fargo",
      "Grand Forks",
      "Jamestown",
      "Mandan",
      "Minot",
      "Minot AFB",
      "Valley City",
      "Wahpeton",
      "West Fargo",
      "Williston"
    ],
    "OH": [
      "Akron",
      "Alliance",
      "Amherst",
      "Ashland",
      "Ashtabula",
      "Athens",
      "Aurora",
      "Austintown",
      "Avon",
      "Avon Lake",
      "Barberton",
      "Bay Village",
      "Beachwood",
      "Beavercreek",
      "Beckett Ridge",
      "Bedford",
      "Bedford Heights",
      "Bellbrook",
      "Bellefontaine",
      "Bellevue",
      "Belpre",
      "Berea",
      "Bexley",
      "Blacklick Estates",
      "Blue Ash",
      "Boardman",
      "Bowling Green",
      "Brecksville",
      "Bridgetown North",
      "Broadview Heights",
      "Brook Park",
      "Brooklyn",
      "Brunswick",
      "Bryan",
      "Bucyrus",
      "Cambridge",
      "Campbell",
      "Canfield",
      "Canton",
      "Celina",
      "Centerville",
      "Cheviot",
      "Chillicothe",
      "Cincinnati",
      "Circleville",
      "Clayton",
      "Cleveland",
      "Cleveland Heights",
      "Clyde",
      "Columbus",
      "Conneaut",
      "Cortland",
      "Coshocton",
      "Covedale",
      "Cuyahoga Falls",
      "Dayton",
      "Defiance",
      "Delaware",
      "Delphos",
      "Dent",
      "Dover",
      "Dry Run",
      "Dublin",
      "East Cleveland",
      "East Liverpool",
      "Eastlake",
      "Eaton",
      "Elyria",
      "Englewood",
      "Euclid",
      "Fairborn",
      "Fairfield",
      "Fairlawn",
      "Fairview Park",
      "Findlay",
      "Finneytown",
      "Forest Park",
      "Forestville",
      "Fostoria",
      "Franklin",
      "Fremont",
      "Gahanna",
      "Galion",
      "Garfield Heights",
      "Geneva",
      "Girard",
      "Grandview Heights",
      "Green",
      "Greenville",
      "Groesbeck",
      "Grove City",
      "Hamilton",
      "Harrison",
      "Heath",
      "Highland Heights",
      "Hilliard",
      "Hillsboro",
      "Howland Center",
      "Hubbard",
      "Huber Heights",
      "Hudson",
      "Huron",
      "Independence",
      "Ironton",
      "Jackson",
      "Kent",
      "Kenton",
      "Kenwood",
      "Kettering",
      "Kirtland",
      "Lakewood",
      "Lancaster",
      "Landen",
      "Lebanon",
      "Lima",
      "Lincoln Village",
      "Logan",
      "London",
      "Lorain",
      "Louisville",
      "Loveland",
      "Lyndhurst",
      "Macedonia",
      "Madeira",
      "Mansfield",
      "Maple Heights",
      "Marietta",
      "Marion",
      "Martins Ferry",
      "Marysville",
      "Mason",
      "Massillon",
      "Maumee",
      "Mayfield Heights",
      "Medina",
      "Mentor",
      "Mentor-on-the-Lake",
      "Miamisburg",
      "Middleburg Heights",
      "Middletown",
      "Milford",
      "Monroe",
      "Montgomery",
      "Moraine",
      "Mount Healthy",
      "Mount Vernon",
      "Napoleon",
      "New Philadelphia",
      "Newark",
      "Niles",
      "North Canton",
      "North College Hill",
      "North Madison",
      "North Olmsted",
      "North Ridgeville",
      "North Royalton",
      "Northbrook",
      "Northgate",
      "Northridge",
      "Northridge",
      "Norton",
      "Norwalk",
      "Norwood",
      "Oakwood",
      "Oberlin",
      "Olmsted Falls",
      "Oregon",
      "Orrville",
      "Oxford",
      "Painesville",
      "Parma",
      "Parma Heights",
      "Pataskala",
      "Pepper Pike",
      "Perry Heights",
      "Perrysburg",
      "Pickerington",
      "Piqua",
      "Port Clinton",
      "Portage Lakes",
      "Portsmouth",
      "Powell",
      "Ravenna",
      "Reading",
      "Reynoldsburg",
      "Richmond Heights",
      "Rittman",
      "Riverside",
      "Rocky River",
      "Rossford",
      "Salem",
      "Sandusky",
      "Sandusky South",
      "Seven Hills",
      "Shaker Heights",
      "Sharonville",
      "Sheffield Lake",
      "Shelby",
      "Shiloh",
      "Sidney",
      "Solon",
      "South Euclid",
      "Springboro",
      "Springdale",
      "Springfield",
      "St. Marys",
      "Steubenville",
      "Stow",
      "Streetsboro",
      "Strongsville",
      "Struthers",
      "Sylvania",
      "Tallmadge",
      "Tiffin",
      "Tipp City",
      "Toledo",
      "Trenton",
      "Trotwood",
      "Troy",
      "Twinsburg",
      "University Heights",
      "Upper Arlington",
      "Upper Sandusky",
      "Urbana",
      "Van Wert",
      "Vandalia",
      "Vermilion",
      "Wadsworth",
      "Wapakoneta",
      "Warren",
      "Warrensville Heights",
      "Washington",
      "Wauseon",
      "Wellston",
      "West Carrollton City",
      "Westerville",
      "Westlake",
      "Wheelersburg",
      "White Oak",
      "Whitehall",
      "Wickliffe",
      "Willard",
      "Willoughby",
      "Willoughby Hills",
      "Willowick",
      "Wilmington",
      "Woodbourne-Hyde Park",
      "Wooster",
      "Worthington",
      "Wright-Patterson AFB",
      "Wyoming",
      "Xenia",
      "Youngstown",
      "Zanesville"
    ],
    "OK": [
      "Ada",
      "Altus",
      "Anadarko",
      "Ardmore",
      "Bartlesville",
      "Bethany",
      "Bixby",
      "Blackwell",
      "Broken Arrow",
      "Chickasha",
      "Choctaw",
      "Claremore",
      "Clinton",
      "Coweta",
      "Cushing",
      "Del City",
      "Duncan",
      "Durant",
      "Edmond",
      "El Reno",
      "Elk City",
      "Enid",
      "Glenpool",
      "Guthrie",
      "Guymon",
      "Henryetta",
      "Idabel",
      "Jenks",
      "Lawton",
      "McAlester",
      "Miami",
      "Midwest City",
      "Moore",
      "Muskogee",
      "Mustang",
      "Norman",
      "Oklahoma City",
      "Okmulgee",
      "Owasso",
      "Pauls Valley",
      "Ponca City",
      "Poteau",
      "Pryor Creek",
      "Sallisaw",
      "Sand Springs",
      "Sapulpa",
      "Seminole",
      "Shawnee",
      "Stillwater",
      "Tahlequah",
      "Tecumseh",
      "The Village",
      "Tulsa",
      "Vinita",
      "Wagoner",
      "Warr Acres",
      "Weatherford",
      "Woodward",
      "Yukon"
    ],
    "OR": [
      "Albany",
      "Aloha",
      "Altamont",
      "Ashland",
      "Astoria",
      "Baker City",
      "Beaverton",
      "Bend",
      "Canby",
      "Cedar Hills",
      "Cedar Mill",
      "Central Point",
      "City of The Dalles",
      "Coos Bay",
      "Cornelius",
      "Corvallis",
      "Cottage Grove",
      "Dallas",
      "Eugene",
      "Fairview",
      "Florence",
      "Forest Grove",
      "Four Corners",
      "Garden Home-Whitford",
      "Gladstone",
      "Grants Pass",
      "Green",
      "Gresham",
      "Hayesville",
      "Hermiston",
      "Hillsboro",
      "Independence",
      "Jennings Lodge",
      "Keizer",
      "Klamath Falls",
      "La Grande",
      "Lake Oswego",
      "Lebanon",
      "Lincoln City",
      "McMinnville",
      "Medford",
      "Milton-Freewater",
      "Milwaukie",
      "Monmouth",
      "Newberg",
      "Newport",
      "North Bend",
      "Oak Grove",
      "Oak Hills",
      "Oatfield",
      "Ontario",
      "Oregon City",
      "Pendleton",
      "Portland",
      "Prineville",
      "Redmond",
      "Rockcreek",
      "Roseburg",
      "Salem",
      "Sherwood",
      "Silverton",
      "Springfield",
      "St. Helens",
      "Stayton",
      "Sunnyside",
      "Sutherlin",
      "Sweet Home",
      "Tigard",
      "Troutdale",
      "Tualatin",
      "West Haven-Sylvan",
      "West Linn",
      "West Slope",
      "Wilsonville",
      "Woodburn"
    ],
    "PA": [
      "Aliquippa",
      "Allentown",
      "Altoona",
      "Ambler",
      "Ambridge",
      "Archbald",
      "Ardmore",
      "Audubon",
      "Back Mountain",
      "Baldwin",
      "Beaver Falls",
      "Bellefonte",
      "Bellevue",
      "Berwick",
      "Bethel Park",
      "Bethlehem",
      "Blakely",
      "Bloomsburg",
      "Blue Bell",
      "Bradford",
      "Brentwood",
      "Bristol",
      "Brookhaven",
      "Broomall",
      "Butler",
      "Camp Hill",
      "Canonsburg",
      "Carbondale",
      "Carlisle",
      "Carnegie",
      "Carnot-Moon",
      "Castle Shannon",
      "Catasauqua",
      "Chambersburg",
      "Chester",
      "Clairton",
      "Clarion",
      "Clearfield",
      "Clifton Heights",
      "Coatesville",
      "Collegeville",
      "Collingdale",
      "Colonial Park",
      "Columbia",
      "Connellsville",
      "Conshohocken",
      "Coraopolis",
      "Corry",
      "Crafton",
      "Croydon",
      "Darby",
      "Darby Township",
      "Dickson City",
      "Dormont",
      "Downingtown",
      "Doylestown",
      "Drexel Hill",
      "DuBois",
      "Dunmore",
      "Duquesne",
      "East Norriton",
      "East Stroudsburg",
      "East York",
      "Easton",
      "Economy",
      "Edinboro",
      "Elizabethtown",
      "Ellwood City",
      "Emmaus",
      "Ephrata",
      "Erie",
      "Fairless Hills",
      "Farrell",
      "Feasterville-Trevose",
      "Fernway",
      "Folcroft",
      "Folsom",
      "Forest Hills",
      "Franklin",
      "Franklin Park",
      "Fullerton",
      "Gettysburg",
      "Glenolden",
      "Glenside",
      "Greensburg",
      "Greenville",
      "Grove City",
      "Hampton Township",
      "Hanover",
      "Harleysville",
      "Harrisburg",
      "Harrison Township",
      "Hatboro",
      "Hazleton",
      "Hermitage",
      "Hershey",
      "Homeacre-Lyndora",
      "Horsham",
      "Huntingdon",
      "Indiana",
      "Jeannette",
      "Jefferson Hills",
      "Johnstown",
      "Kennedy Township",
      "King of Prussia",
      "Kingston",
      "Kulpsville",
      "Lancaster",
      "Lansdale",
      "Lansdowne",
      "Latrobe",
      "Leacock-Leola-Bareville",
      "Lebanon",
      "Levittown",
      "Lewistown",
      "Linglestown",
      "Lionville-Marchwood",
      "Lititz",
      "Lock Haven",
      "Lower Allen",
      "Lower Burrell",
      "Maple Glen",
      "McCandless Township",
      "McKees Rocks",
      "McKeesport",
      "Meadville",
      "Mechanicsburg",
      "Middletown",
      "Middletown",
      "Millersville",
      "Milton",
      "Monaca",
      "Monessen",
      "Montgomeryville",
      "Morrisville",
      "Mount Carmel",
      "Mount Joy",
      "Mount Lebanon",
      "Mountain Top",
      "Munhall",
      "Municipality of Monroeville",
      "Municipality of Murrysville",
      "Nanticoke",
      "Nazareth",
      "Nether Providence Township",
      "New Brighton",
      "New Castle",
      "New Cumberland",
      "New Kensington",
      "Norristown",
      "North Braddock",
      "North Versailles",
      "Northampton",
      "Northwest Harborcreek",
      "O’Hara Township",
      "Oakmont",
      "Oil City",
      "Old Forge",
      "Palmyra",
      "Park Forest Village",
      "Parkville",
      "Penn Hills",
      "Perkasie",
      "Philadelphia",
      "Phoenixville",
      "Pittsburgh",
      "Pittston",
      "Pleasant Hills",
      "Plum",
      "Plymouth",
      "Pottstown",
      "Pottsville",
      "Progress",
      "Prospect Park",
      "Punxsutawney",
      "Quakertown",
      "Radnor Township",
      "Reading",
      "Red Lion",
      "Richboro",
      "Ridley Park",
      "Robinson Township",
      "Ross Township",
      "Sanatoga",
      "Scott Township",
      "Scranton",
      "Shaler Township",
      "Shamokin",
      "Sharon",
      "Shiloh",
      "Somerset",
      "Souderton",
      "South Park Township",
      "South Williamsport",
      "Springfield",
      "St. Marys",
      "State College",
      "Stowe Township",
      "Sunbury",
      "Swarthmore",
      "Swissvale",
      "Tamaqua",
      "Taylor",
      "Titusville",
      "Trooper",
      "Turtle Creek",
      "Uniontown",
      "Upper Providence Township",
      "Upper St. Clair",
      "Village Green-Green Ridge",
      "Warren",
      "Washington",
      "Waynesboro",
      "Weigelstown",
      "West Chester",
      "West Goshen",
      "West Mifflin",
      "West Norriton",
      "West View",
      "White Oak",
      "Whitehall",
      "Wilkes-Barre",
      "Wilkins Township",
      "Wilkinsburg",
      "Williamsport",
      "Willow Grove",
      "Willow Street",
      "Wilson",
      "Woodlyn",
      "Wyomissing",
      "Yeadon",
      "York"
    ],
    "RI": [
      "Barrington",
      "Barrington",
      "Bristol",
      "Bristol",
      "Burrillville",
      "Central Falls",
      "Charlestown",
      "Coventry",
      "Cranston",
      "Cumberland",
      "Cumberland Hill",
      "East Greenwich",
      "East Providence",
      "Exeter",
      "Glocester",
      "Greenville",
      "Hopkinton",
      "Johnston",
      "Lincoln",
      "Middletown",
      "Narragansett",
      "Newport",
      "Newport East",
      "North Kingstown",
      "North Providence",
      "North Providence",
      "North Smithfield",
      "Pawtucket",
      "Portsmouth",
      "Providence",
      "Richmond",
      "Scituate",
      "Smithfield",
      "South Kingstown",
      "Tiverton",
      "Tiverton",
      "Valley Falls",
      "Wakefield-Peacedale",
      "Warren",
      "Warwick",
      "West Warwick",
      "West Warwick",
      "Westerly",
      "Westerly",
      "Woonsocket"
    ],
    "SC": [
      "Aiken",
      "Anderson",
      "Beaufort",
      "Bennettsville",
      "Berea",
      "Burton",
      "Camden",
      "Cayce",
      "Charleston",
      "Chester",
      "Clemson",
      "Clinton",
      "Columbia",
      "Conway",
      "Darlington",
      "Dentsville",
      "Dillon",
      "Easley",
      "Five Forks",
      "Florence",
      "Forest Acres",
      "Fort Mill",
      "Fountain Inn",
      "Gaffney",
      "Gantt",
      "Garden City",
      "Georgetown",
      "Georgetown County",
      "Goose Creek",
      "Greenville",
      "Greenwood",
      "Greer",
      "Hanahan",
      "Hartsville",
      "Hilton Head Island",
      "Homeland Park",
      "Irmo",
      "Ladson",
      "Lake City",
      "Lancaster",
      "Laurel Bay",
      "Laurens",
      "Lexington",
      "Little River",
      "Lugoff",
      "Marion",
      "Mauldin",
      "Mount Pleasant",
      "Myrtle Beach",
      "Newberry",
      "North Augusta",
      "North Charleston",
      "North Myrtle Beach",
      "Oak Grove",
      "Orangeburg",
      "Parker",
      "Red Bank",
      "Red Hill",
      "Rock Hill",
      "Sans Souci",
      "Seneca",
      "Seven Oaks",
      "Simpsonville",
      "Socastee",
      "Spartanburg",
      "St. Andrews",
      "Summerville",
      "Sumter",
      "Taylors",
      "Union",
      "Wade Hampton",
      "Welcome",
      "West Columbia",
      "Woodfield",
      "York"
    ],
    "SD": [
      "Aberdeen",
      "Brookings",
      "Huron",
      "Madison",
      "Mitchell",
      "Pierre",
      "Rapid City",
      "Rapid Valley",
      "Sioux Falls",
      "Spearfish",
      "Sturgis",
      "Vermillion",
      "Watertown",
      "Yankton"
    ],
    "TN": [
      "Alcoa",
      "Athens",
      "Bartlett",
      "Bloomingdale",
      "Brentwood",
      "Bristol",
      "Brownsville",
      "Chattanooga",
      "Clarksville",
      "Cleveland",
      "Clinton",
      "Collegedale",
      "Collierville",
      "Colonial Heights",
      "Columbia",
      "Cookeville",
      "Covington",
      "Crossville",
      "Dayton",
      "Dickson",
      "Dyersburg",
      "East Brainerd",
      "East Ridge",
      "Elizabethton",
      "Farragut",
      "Fayetteville",
      "Franklin",
      "Gallatin",
      "Germantown",
      "Goodlettsville",
      "Green Hill",
      "Greeneville",
      "Harriman",
      "Harrison",
      "Hendersonville",
      "Humboldt",
      "Jackson",
      "Jefferson City",
      "Johnson City",
      "Kingsport",
      "Knoxville",
      "La Follette",
      "La Vergne",
      "Lakeland",
      "Lawrenceburg",
      "Lebanon",
      "Lenoir City",
      "Lewisburg",
      "Lexington",
      "Manchester",
      "Martin",
      "Maryville",
      "McMinnville",
      "Memphis",
      "Middle Valley",
      "Milan",
      "Millington",
      "Morristown",
      "Mount Juliet",
      "Murfreesboro",
      "Nashville-Davidson",
      "Newport",
      "Oak Ridge",
      "Paris",
      "Portland",
      "Pulaski",
      "Red Bank",
      "Ripley",
      "Savannah",
      "Sevierville",
      "Seymour",
      "Shelbyville",
      "Signal Mountain",
      "Smyrna",
      "Soddy-Daisy",
      "South Cleveland",
      "Spring Hill",
      "Springfield",
      "Tullahoma",
      "Union City",
      "White House",
      "Winchester"
    ],
    "TX": [
      "Abilene",
      "Addison",
      "Alamo",
      "Alamo Heights",
      "Aldine",
      "Alice",
      "Allen",
      "Alvin",
      "Amarillo",
      "Anderson Mill",
      "Andrews",
      "Angleton",
      "Aransas Pass",
      "Arlington",
      "Atascocita",
      "Athens",
      "Austin",
      "Azle",
      "Bacliff",
      "Balch Springs",
      "Bay City",
      "Baytown",
      "Beaumont",
      "Bedford",
      "Beeville",
      "Bellaire",
      "Bellmead",
      "Belton",
      "Benbrook",
      "Big Spring",
      "Boerne",
      "Bonham",
      "Borger",
      "Brenham",
      "Bridge City",
      "Brownfield",
      "Brownsville",
      "Brownwood",
      "Brushy Creek",
      "Bryan",
      "Burkburnett",
      "Burleson",
      "Canyon",
      "Canyon Lake",
      "Carrollton",
      "Carthage",
      "Cedar Hill",
      "Cedar Park",
      "Channelview",
      "Childress",
      "Cinco Ranch",
      "Cleburne",
      "Cleveland",
      "Cloverleaf",
      "Clute",
      "College Station",
      "Colleyville",
      "Commerce",
      "Conroe",
      "Converse",
      "Coppell",
      "Copperas Cove",
      "Corinth",
      "Corpus Christi",
      "Corsicana",
      "Crockett",
      "Crowley",
      "Crystal City",
      "Cuero",
      "Dalhart",
      "Dallas",
      "Deer Park",
      "Del Rio",
      "Denison",
      "Denton",
      "DeSoto",
      "Dickinson",
      "Donna",
      "Dumas",
      "Duncanville",
      "Eagle Mountain",
      "Eagle Pass",
      "Edinburg",
      "Eidson Road",
      "El Campo",
      "El Paso",
      "Ennis",
      "Euless",
      "Fabens",
      "Farmers Branch",
      "Flower Mound",
      "Forest Hill",
      "Fort Bliss",
      "Fort Hood",
      "Fort Stockton",
      "Fort Worth",
      "Fredericksburg",
      "Freeport",
      "Fresno",
      "Friendswood",
      "Frisco",
      "Gainesville",
      "Galena Park",
      "Galveston",
      "Garland",
      "Gatesville",
      "Georgetown",
      "Gladewater",
      "Glenn Heights",
      "Gonzales",
      "Graham",
      "Grand Prairie",
      "Grapevine",
      "Greatwood",
      "Greenville",
      "Groves",
      "Haltom City",
      "Harker Heights",
      "Harlingen",
      "Henderson",
      "Hereford",
      "Hewitt",
      "Hidalgo",
      "Highland Park",
      "Highland Village",
      "Highlands",
      "Hillsboro",
      "Hitchcock",
      "Homestead Meadows South",
      "Hondo",
      "Houston",
      "Humble",
      "Huntsville",
      "Hurst",
      "Ingleside",
      "Iowa Park",
      "Irving",
      "Jacinto City",
      "Jacksonville",
      "Jasper",
      "Jersey Village",
      "Jollyville",
      "Katy",
      "Kaufman",
      "Keller",
      "Kerrville",
      "Kilgore",
      "Killeen",
      "Kingsville",
      "Kirby",
      "La Feria",
      "La Homa",
      "La Marque",
      "La Porte",
      "Lackland AFB",
      "Lake Dallas",
      "Lake Jackson",
      "Lakeway",
      "Lamesa",
      "Lampasas",
      "Lancaster",
      "Laredo",
      "League City",
      "Leander",
      "Leon Valley",
      "Levelland",
      "Lewisville",
      "Liberty",
      "Littlefield",
      "Live Oak",
      "Lockhart",
      "Longview",
      "Lubbock",
      "Lufkin",
      "Lumberton",
      "Mansfield",
      "Marlin",
      "Marshall",
      "McAllen",
      "McKinney",
      "Mercedes",
      "Mesquite",
      "Mexia",
      "Midland",
      "Midlothian",
      "Mineral Wells",
      "Mission",
      "Mission Bend",
      "Missouri City",
      "Monahans",
      "Mount Pleasant",
      "Nacogdoches",
      "Navasota",
      "Nederland",
      "New Braunfels",
      "New Territory",
      "North Richland Hills",
      "Odessa",
      "Orange",
      "Palestine",
      "Palmview South",
      "Pampa",
      "Paris",
      "Pasadena",
      "Pearland",
      "Pearsall",
      "Pecan Grove",
      "Pecos",
      "Perryton",
      "Pflugerville",
      "Pharr",
      "Plainview",
      "Plano",
      "Pleasanton",
      "Port Arthur",
      "Port Lavaca",
      "Port Neches",
      "Portland",
      "Raymondville",
      "Rendon",
      "Richardson",
      "Richland Hills",
      "Richmond",
      "Rio Grande City",
      "River Oaks",
      "Robinson",
      "Robstown",
      "Rockport",
      "Rockwall",
      "Roma",
      "Rosenberg",
      "Round Rock",
      "Rowlett",
      "Sachse",
      "Saginaw",
      "San Angelo",
      "San Antonio",
      "San Benito",
      "San Elizario",
      "San Juan",
      "San Marcos",
      "Santa Fe",
      "Schertz",
      "Seabrook",
      "Seagoville",
      "Seguin",
      "Sherman",
      "Silsbee",
      "Slaton",
      "Snyder",
      "Socorro",
      "South Houston",
      "Southlake",
      "Spring",
      "Stafford",
      "Stephenville",
      "Sugar Land",
      "Sulphur Springs",
      "Sweetwater",
      "Taylor",
      "Temple",
      "Terrell",
      "Texarkana",
      "Texas City",
      "The Colony",
      "The Woodlands",
      "Tomball",
      "Trophy Club",
      "Tyler",
      "Universal City",
      "University Park",
      "Uvalde",
      "Vernon",
      "Victoria",
      "Vidor",
      "Waco",
      "Watauga",
      "Waxahachie",
      "Weatherford",
      "Webster",
      "Wells Branch",
      "Weslaco",
      "West Livingston",
      "West Odessa",
      "West University Place",
      "Wharton",
      "White Settlement",
      "Wichita Falls",
      "Windemere",
      "Woodway",
      "Wylie"
    ],
    "UT": [
      "Alpine",
      "American Fork",
      "Bountiful",
      "Brigham City",
      "Canyon Rim",
      "Cedar City",
      "Centerville",
      "Clearfield",
      "Clinton",
      "Cottonwood Heights",
      "Cottonwood West",
      "Draper",
      "East Millcreek",
      "Farmington",
      "Grantsville",
      "Heber",
      "Highland",
      "Holladay",
      "Hurricane",
      "Hyrum",
      "Kaysville",
      "Kearns",
      "Layton",
      "Lehi",
      "Lindon",
      "Little Cottonwood Creek Valley",
      "Logan",
      "Magna",
      "Midvale",
      "Millcreek",
      "Mount Olympus",
      "Murray",
      "North Logan",
      "North Ogden",
      "North Salt Lake",
      "Ogden",
      "Oquirrh",
      "Orem",
      "Park City",
      "Payson",
      "Pleasant Grove",
      "Price",
      "Provo",
      "Richfield",
      "Riverdale",
      "Riverton",
      "Roy",
      "Salt Lake City",
      "Sandy",
      "Smithfield",
      "South Jordan",
      "South Ogden",
      "South Salt Lake",
      "Spanish Fork",
      "Springville",
      "St. George",
      "Summit Park",
      "Syracuse",
      "Taylorsville",
      "Tooele",
      "Vernal",
      "Washington",
      "Washington Terrace",
      "West Jordan",
      "West Point",
      "West Valley City",
      "Woods Cross"
    ],
    "VT": [
      "Barre",
      "Barre",
      "Bennington",
      "Bennington",
      "Brattleboro",
      "Brattleboro",
      "Burlington",
      "Colchester",
      "Essex",
      "Essex Junction",
      "Hartford",
      "Middlebury",
      "Middlebury",
      "Milton",
      "Montpelier",
      "Rutland",
      "Shelburne",
      "South Burlington",
      "Springfield",
      "St. Albans",
      "St. Johnsbury",
      "St. Johnsbury",
      "Swanton",
      "Williston",
      "Winooski"
    ],
    "VA": [
      "Abingdon",
      "Alexandria",
      "Annandale",
      "Aquia Harbour",
      "Arlington",
      "Ashland",
      "Bailey’s Crossroads",
      "Bedford",
      "Belle Haven",
      "Blacksburg",
      "Bon Air",
      "Bristol",
      "Buena Vista",
      "Bull Run",
      "Burke",
      "Cave Spring",
      "Centreville",
      "Chantilly",
      "Charlottesville",
      "Chesapeake",
      "Chester",
      "Christiansburg",
      "Collinsville",
      "Colonial Heights",
      "Covington",
      "Culpeper",
      "Dale City",
      "Danville",
      "Dumbarton",
      "Dunn Loring",
      "East Highland Park",
      "Fairfax",
      "Falls Church",
      "Farmville",
      "Forest",
      "Fort Belvoir",
      "Fort Hunt",
      "Fort Lee",
      "Franconia",
      "Franklin",
      "Fredericksburg",
      "Front Royal",
      "Galax",
      "Glen Allen",
      "Gloucester Point",
      "Great Falls",
      "Groveton",
      "Hampton",
      "Harrisonburg",
      "Herndon",
      "Highland Springs",
      "Hollins",
      "Hopewell",
      "Huntington",
      "Hybla Valley",
      "Idylwood",
      "Jefferson",
      "Lake Barcroft",
      "Lake Monticello",
      "Lake Ridge",
      "Lakeside",
      "Laurel",
      "Leesburg",
      "Lexington",
      "Lincolnia",
      "Linton Hall",
      "Lorton",
      "Lynchburg",
      "Madison Heights",
      "Manassas",
      "Manassas Park",
      "Mantua",
      "Marion",
      "Martinsville",
      "McLean",
      "Mechanicsville",
      "Merrifield",
      "Montclair",
      "Montrose",
      "Mount Vernon",
      "Newington",
      "Newport News",
      "Norfolk",
      "North Springfield",
      "Oakton",
      "Petersburg",
      "Pimmit Hills",
      "Poquoson",
      "Portsmouth",
      "Pulaski",
      "Quantico Station",
      "Radford",
      "Reston",
      "Richmond",
      "Roanoke",
      "Rose Hill",
      "Salem",
      "Seven Corners",
      "Smithfield",
      "South Boston",
      "Springfield",
      "Staunton",
      "Stuarts Draft",
      "Sudley",
      "Suffolk",
      "Timberlake",
      "Tuckahoe",
      "Tysons Corner",
      "Vienna",
      "Vinton",
      "Virginia Beach",
      "Warrenton",
      "Waynesboro",
      "West Gate",
      "West Springfield",
      "Williamsburg",
      "Winchester",
      "Wolf Trap",
      "Woodbridge",
      "Wyndham",
      "Wytheville",
      "Yorkshire"
    ],
    "WA": [
      "Aberdeen",
      "Alderwood Manor",
      "Anacortes",
      "Arlington",
      "Artondale",
      "Auburn",
      "Bainbridge Island",
      "Bangor Trident Base",
      "Battle Ground",
      "Bellevue",
      "Bellingham",
      "Bonney Lake",
      "Bothell",
      "Bremerton",
      "Brier",
      "Bryn Mawr-Skyway",
      "Burien",
      "Burlington",
      "Camano",
      "Camas",
      "Cascade-Fairwood",
      "Centralia",
      "Chehalis",
      "Cheney",
      "Clarkston",
      "Clarkston Heights-Vineland",
      "College Place",
      "Cottage Lake",
      "Covington",
      "Des Moines",
      "Dishman",
      "East Hill-Meridian",
      "East Renton Highlands",
      "East Wenatchee Bench",
      "Edgewood",
      "Edmonds",
      "Elk Plain",
      "Ellensburg",
      "Enumclaw",
      "Ephrata",
      "Everett",
      "Fairwood",
      "Federal Way",
      "Ferndale",
      "Five Corners",
      "Fort Lewis",
      "Gig Harbor",
      "Graham",
      "Grandview",
      "Hazel Dell North",
      "Hazel Dell South",
      "Hobart",
      "Hoquiam",
      "Inglewood-Finn Hill",
      "Issaquah",
      "Kelso",
      "Kenmore",
      "Kennewick",
      "Kent",
      "Kingsgate",
      "Kirkland",
      "Lacey",
      "Lake Forest Park",
      "Lake Morton-Berrydale",
      "Lake Shore",
      "Lake Stevens",
      "Lakeland North",
      "Lakeland South",
      "Lakewood",
      "Lea Hill",
      "Longview",
      "Lynden",
      "Lynnwood",
      "Maltby",
      "Maple Valley",
      "Martha Lake",
      "Marysville",
      "Mercer Island",
      "Midland",
      "Mill Creek",
      "Mill Plain",
      "Minnehaha",
      "Monroe",
      "Moses Lake",
      "Mount Vernon",
      "Mountlake Terrace",
      "Mukilteo",
      "Newcastle",
      "Normandy Park",
      "North Creek",
      "North Marysville",
      "Oak Harbor",
      "Olympia",
      "Opportunity",
      "Orchards",
      "Otis Orchards-East Farms",
      "Paine Field-Lake Stickney",
      "Parkland",
      "Parkwood",
      "Pasco",
      "Picnic Point-North Lynnwood",
      "Port Angeles",
      "Port Orchard",
      "Port Townsend",
      "Poulsbo",
      "Prairie Ridge",
      "Pullman",
      "Puyallup",
      "Redmond",
      "Renton",
      "Richland",
      "Riverton-Boulevard Park",
      "Salmon Creek",
      "Sammamish",
      "SeaTac",
      "Seattle",
      "Seattle Hill-Silver Firs",
      "Sedro-Woolley",
      "Selah",
      "Shelton",
      "Shoreline",
      "Silverdale",
      "Snohomish",
      "South Hill",
      "Spanaway",
      "Spokane",
      "Steilacoom",
      "Summit",
      "Sumner",
      "Sunnyside",
      "Tacoma",
      "Terrace Heights",
      "Toppenish",
      "Tukwila",
      "Tumwater",
      "Union Hill-Novelty Hill",
      "University Place",
      "Vancouver",
      "Vashon",
      "Veradale",
      "Walla Walla",
      "Waller",
      "Walnut Grove",
      "Washington",
      "Washougal",
      "Wenatchee",
      "West Lake Stevens",
      "West Richland",
      "West Valley",
      "White Center",
      "Woodinville",
      "Yakima"
    ],
    "WV": [
      "Beckley",
      "Bluefield",
      "Bridgeport",
      "Charleston",
      "Cheat Lake",
      "Clarksburg",
      "Cross Lanes",
      "Dunbar",
      "Elkins",
      "Fairmont",
      "Huntington",
      "Martinsburg",
      "Morgantown",
      "Moundsville",
      "Nitro",
      "Oak Hill",
      "Parkersburg",
      "Pea Ridge",
      "Princeton",
      "South Charleston",
      "St. Albans",
      "Teays Valley",
      "Vienna",
      "Weirton",
      "Wheeling"
    ],
    "WI": [
      "Allouez",
      "Altoona",
      "Antigo",
      "Appleton",
      "Ashland",
      "Ashwaubenon",
      "Baraboo",
      "Beaver Dam",
      "Bellevue",
      "Bellevue Town",
      "Beloit",
      "Beloit",
      "Brookfield",
      "Brookfield",
      "Brown Deer",
      "Burlington",
      "Burlington",
      "Caledonia",
      "Cedarburg",
      "Chippewa Falls",
      "Cudahy",
      "De Pere",
      "DeForest",
      "Delafield",
      "Delafield",
      "Delavan",
      "Eau Claire",
      "Elkhorn",
      "Elm Grove",
      "Fitchburg",
      "Fond du Lac",
      "Fort Atkinson",
      "Fox Point",
      "Franklin",
      "Genesee",
      "Germantown",
      "Glendale",
      "Grafton",
      "Grand Chute",
      "Grand Rapids",
      "Green Bay",
      "Greendale",
      "Greenfield",
      "Greenville",
      "Hales Corners",
      "Hartford",
      "Hartland",
      "Holmen",
      "Howard",
      "Hudson",
      "Hudson",
      "Janesville",
      "Jefferson",
      "Kaukauna",
      "Kenosha",
      "Kimberly",
      "La Crosse",
      "Lake Geneva",
      "Lisbon",
      "Little Chute",
      "Madison",
      "Madison",
      "Manitowoc",
      "Marinette",
      "Marshfield",
      "McFarland",
      "Menasha",
      "Menasha",
      "Menomonee Falls",
      "Menomonie",
      "Mequon",
      "Merrill",
      "Merton",
      "Middleton",
      "Milwaukee",
      "Monona",
      "Monroe",
      "Mount Pleasant",
      "Mukwonago",
      "Mukwonago",
      "Muskego",
      "Neenah",
      "New Berlin",
      "New London",
      "New Richmond",
      "Norway",
      "Oak Creek",
      "Oconomowoc",
      "Oconomowoc",
      "Onalaska",
      "Oregon",
      "Oshkosh",
      "Pewaukee",
      "Pewaukee",
      "Platteville",
      "Pleasant Prairie",
      "Plover",
      "Plymouth",
      "Port Washington",
      "Portage",
      "Prairie du Chien",
      "Racine",
      "Reedsburg",
      "Rhinelander",
      "Rib Mountain",
      "Rib Mountain",
      "Rice Lake",
      "Richfield",
      "Ripon",
      "River Falls",
      "Salem",
      "Shawano",
      "Sheboygan",
      "Sheboygan Falls",
      "Shorewood",
      "Somers",
      "South Milwaukee",
      "Sparta",
      "St. Francis",
      "Stevens Point",
      "Stoughton",
      "Sturgeon Bay",
      "Suamico",
      "Sun Prairie",
      "Superior",
      "Sussex",
      "Tomah",
      "Two Rivers",
      "Vernon",
      "Verona",
      "Washington",
      "Watertown",
      "Waukesha",
      "Waukesha",
      "Waunakee",
      "Waupun",
      "Wausau",
      "Wauwatosa",
      "West Allis",
      "West Bend",
      "Weston",
      "Whitefish Bay",
      "Whitewater",
      "Wisconsin Rapids"
    ],
    "WY": [
      "Casper",
      "Cheyenne",
      "Cody",
      "Evanston",
      "Gillette",
      "Green River",
      "Jackson",
      "Lander",
      "Laramie",
      "Rawlins",
      "Riverton",
      "Rock Springs",
      "Sheridan"
    ]
  };
  