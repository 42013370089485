import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberWithCommas, getCurrencyPrice } from './../../../../utils/utils';
import { TrendingActivity } from './../../../../api/activity.api';
import { CurrencyTypeEnum } from './../../../../interfaces/interfaces';
import { BaseAvatar } from './../../../../components/common/BaseAvatar/BaseAvatar';
import * as S from './TrendingCollection.styles';

export const TrendingCollection: React.FC<TrendingActivity> = ({ title, owner, usd_value, image, avatar }) => {
  const { t } = useTranslation();

  return (
    <S.Card padding={0} $img={image}>
      <S.CollectionImage src={image} alt="nft" />
      <S.BidButton type="ghost">{'Details'}</S.BidButton>
     
    </S.Card>
  );
};