import axios from "axios";
import { CommunityModel } from "../domain/CcommunityModel";
import { httpApi } from './http.api';




export const getCommunity = (): Promise<undefined> =>
  httpApi.get<undefined>('community').then(({ data }) => data);


const other:CommunityModel = {
  id: 0,
  code: "other-000",
  name: "Not Available",
  createdAt: "string",
  updatedAt: "string"
}

export const getCommunityData = async (city:any): Promise<any> => {
    try {

      var url:string = '';

      if(city && city !== '')
        url ='https://service-to-get-api-4f868ffdd2cb.herokuapp.com/api/v1/community/communityListByCity/'+city;

   //     url ='http://localhost:5000/api/v1/community/communityListByCity/'+city;


    //  else
    //    url = 'https://service-to-get-api-4f868ffdd2cb.herokuapp.com/api/v1/community';

      const response = await axios.get<CommunityModel[]>(url);
      
      const result = response.data || [];
   //   result.push(other);
      return result;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      throw new Error(e);
    }
};


export const getCitiesData = async (): Promise<any> => {
  try {
    const response = await axios.get<CommunityModel>('https://service-to-get-api-4f868ffdd2cb.herokuapp.com/api/v1/community/getCities/tx');
 //   const response = await axios.get<CommunityModel>('http://localhost:5000/api/v1/community/getCities/tx');

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};



  