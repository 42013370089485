import React, { useEffect, useMemo, useState, useRef,  } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from './../../components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from './../../hooks/reduxHooks';
import { doSignUp } from './../../store/slices/authSlice';
import { notificationController } from './../../controllers/notificationController';
import { ReactComponent as GoogleIcon } from './../../assets/icons/google.svg';
import { ReactComponent as FacebookIcon } from './../../assets/icons/facebook.svg';
import * as Auth from './../../components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './ProviderForm.styles';
import { CommunityModel } from '../../domain/CcommunityModel';
import { persistCommunityCode, readCommunityCode } from './../../services/localStorage.service';
import { BaseSelect, Option } from './../../components/common/selects/BaseSelect/BaseSelect';
import { languages } from './../../constants/languages';
import { BaseSpace } from './../../components/common/BaseSpace/BaseSpace';
import { createServiceProviderData } from '../../api/serviceprovider.api';
import { useAppSelector } from './../../hooks/reduxHooks';

interface VendorSignUpFormData {
  name: string;
  contactperson: string;
  providerType: string;
  serviceType: string;
  communityCode: string;
  address: string;
  address1: string;
  city: string;
  state: string;
  postalcode: string;
  countryCode: string;
  phoneNumber: string;
  mobile: string;
  fax: string;
  email: string;
  website: string;
  termofuse:boolean;
  activestatus:boolean;
  code: string;
  latitude: number;
  longitude: number;
  timeZone: string;
  
}

const initValues = {
  name: '',
  contactperson: '',
  providerType: '',
  serviceType: '',
  communityCode: '',
  address: '',
  address1: '',
  city: '',
  state: '',
  postalcode: '',
  countryCode: '',
  phoneNumber: '',
  mobile: '',
  fax: '',
  email: '',
  website: '',
  termofuse: true,
  activestatus: false,
  code: '',
  latitude: 0,
  longitude:0,
  timeZone:''
};

//export const ProviderForm: React.FC<any> = (props:any) => {
  export  const ProviderForm = (props:any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  //const { setIsBusinessModalOpen } = props;

  const [inputValue, setInputValue] = React.useState('');
  const [codeValue, setCodeValue] = React.useState('');
  
  const  onTagsChange = (event:any, values:any) => {
    if(values === null){ 
       // persistCommunityCode(null)
    }else{
      
      setInputValue(values.code);
      setCodeValue(values.code)
      //  persistCommunityCode({code: values.code, name: values.name})
    }

  }

 

  const { t } = useTranslation();
  const user: any = useAppSelector((state: any) => state.user.user);
    const commCode = readCommunityCode();
    const communityCode = commCode?.code || user?.community?.code;

  const handleSubmit = (values: VendorSignUpFormData) => {
    setLoading(true);
    values.termofuse = true;
    values.activestatus = false;
    values.code = values.name+values.phoneNumber+values.postalcode;
    values.communityCode = codeValue;
    values.latitude = 0;
    values.longitude = 0;
    values.timeZone = "CST"; 
    values.serviceType = "other"; 
    values.providerType = "company"; 
    values.communityCode = communityCode;
   
     createServiceProviderData(values)
      .then(() => {
        notificationController.success({
          message: t('provider.signUpSuccessMessage'),
          description: t('provider.signUpSuccessDescription'),
        });
        values = initValues;
        setLoading(false);
        props.handleBusinessDetail(false)
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      }); 
  };

  const [data, setData] = useState([]);

  




  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{'Business Provider Form'}</S.Title>
        
       


        <Auth.FormItem
          name="name"
          label={t('Name')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.firstName')} />
        </Auth.FormItem>

        

        <Auth.FormItem
          name="contactperson"
          label={'Contact Person'}
      //   rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Contact Person'} />
        </Auth.FormItem>


       

        <Auth.FormItem
          name="address"
          label={'Address'}
       //   rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Address'} />
        </Auth.FormItem>


        <Auth.FormItem
          name="address1"
          label={'Address1'}
      //    rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Address1'} />
        </Auth.FormItem>


        <Auth.FormItem
          name="city"
          label={'City'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'City'} />
        </Auth.FormItem>


        <Auth.FormItem
          name="state"
          label={'State'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'State'} />
        </Auth.FormItem>


        <Auth.FormItem
          name="postalcode"
          label={'Postal Code'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Postal Code'} />
        </Auth.FormItem>


        <Auth.FormItem
          name="phoneNumber"
          label={'Phone Number'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Phone Number'} />
        </Auth.FormItem>


        <Auth.FormItem
          name="mobile"
          label={'Mobile #'}
         // rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Mobile #'} />
        </Auth.FormItem>


        <Auth.FormItem
          name="fax"
          label={'Fax #'}
       //   rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Fax #'} />
        </Auth.FormItem>



        <Auth.FormItem
          name="email"
          label={'Email'}
         // rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Email'} />
        </Auth.FormItem>



        <Auth.FormItem
          name="website"
          label={'Website'}
    //      rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Website'} />
        </Auth.FormItem>

        <Auth.ActionsWrapper>
          <BaseForm.Item name="termofuse" rules={[{ required: true, message: t('Please Select Agree Terms and Privacy Policy') }]} valuePropName="checked" noStyle>
            <Auth.FormCheckbox >
              <Auth.Text>
                {t('signup.agree')}{' '}
                <Link to="/privacy/privacyAgree" target={'_blank'}>
                  <Auth.LinkText>{t('signup.termOfUse')}</Auth.LinkText>
                </Link>{' '}
                and{' '}
                <Link to="/privacy/privacyAgree" target={'_blank'}>
                  <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>
                </Link>
              </Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>

        
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Sign Up')}
          </Auth.SubmitButton>
        </BaseForm.Item> 
       
       
        
      </BaseForm>
    </Auth.FormWrapper>
  );
};
