import axios from "axios";

export interface States {
    name: string;
    key: string;
    
  }
  
  export const getStateNamess = (): Promise<any> => {
    return new Promise((res) => {
      setTimeout(() => {
        res([
           
               
                {
                    name: "Texas",
                    key: "TX"
                }
        ]);
      }, 0);
    });
  };


  export const getStateNames = async (): Promise<any> => {
    try {
      const response = await axios.get<any>('https://service-to-get-api-4f868ffdd2cb.herokuapp.com/api/v1/statescities');
   //   const response = await axios.get<any>('http://localhost:5000/api/v1/statescities') ;
    
 //     http://localhost:5000/api/v1/statescities/findCities/TX
      return response.data || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      throw new Error(e);
    }
  };


  export const getStateNamesLater = (): Promise<any> => {
    return new Promise((res) => {
      setTimeout(() => {
        res([
           
                {
                    name: "Alabama",
                    key: "AL"
                },
                {
                    name: "Alaska",
                    key: "AK"
                },
                {
                    name: "American Samoa",
                    key: "AS"
                },
                {
                    name: "Arizona",
                    key: "AZ"
                },
                {
                    name: "Arkansas",
                    key: "AR"
                },
                {
                    name: "California",
                    key: "CA"
                },
                {
                    name: "Colorado",
                    key: "CO"
                },
                {
                    name: "Connecticut",
                    key: "CT"
                },
                {
                    name: "Delaware",
                    key: "DE"
                },
                {
                    name: "District Of Columbia",
                    key: "DC"
                },
                {
                    name: "Federated States Of Micronesia",
                    key: "FM"
                },
                {
                    name: "Florida",
                    key: "FL"
                },
                {
                    name: "Georgia",
                    key: "GA"
                },
                {
                    name: "Guam",
                    key: "GU"
                },
                {
                    name: "Hawaii",
                    key: "HI"
                },
                {
                    name: "Idaho",
                    key: "ID"
                },
                {
                    name: "Illinois",
                    key: "IL"
                },
                {
                    name: "Indiana",
                    key: "IN"
                },
                {
                    name: "Iowa",
                    key: "IA"
                },
                {
                    name: "Kansas",
                    key: "KS"
                },
                {
                    name: "Kentucky",
                    key: "KY"
                },
                {
                    name: "Louisiana",
                    key: "LA"
                },
                {
                    name: "Maine",
                    key: "ME"
                },
                {
                    name: "Marshall Islands",
                    key: "MH"
                },
                {
                    name: "Maryland",
                    key: "MD"
                },
                {
                    name: "Massachusetts",
                    key: "MA"
                },
                {
                    name: "Michigan",
                    key: "MI"
                },
                {
                    name: "Minnesota",
                    key: "MN"
                },
                {
                    name: "Mississippi",
                    key: "MS"
                },
                {
                    name: "Missouri",
                    key: "MO"
                },
                {
                    name: "Montana",
                    key: "MT"
                },
                {
                    name: "Nebraska",
                    key: "NE"
                },
                {
                    name: "Nevada",
                    key: "NV"
                },
                {
                    name: "New Hampshire",
                    key: "NH"
                },
                {
                    name: "New Jersey",
                    key: "NJ"
                },
                {
                    name: "New Mexico",
                    key: "NM"
                },
                {
                    name: "New York",
                    key: "NY"
                },
                {
                    name: "North Carolina",
                    key: "NC"
                },
                {
                    name: "North Dakota",
                    key: "ND"
                },
                {
                    name: "Northern Mariana Islands",
                    key: "MP"
                },
                {
                    name: "Ohio",
                    key: "OH"
                },
                {
                    name: "Oklahoma",
                    key: "OK"
                },
                {
                    name: "Oregon",
                    key: "OR"
                },
                {
                    name: "Palau",
                    key: "PW"
                },
                {
                    name: "Pennsylvania",
                    key: "PA"
                },
                {
                    name: "Puerto Rico",
                    key: "PR"
                },
                {
                    name: "Rhode Island",
                    key: "RI"
                },
                {
                    name: "South Carolina",
                    key: "SC"
                },
                {
                    name: "South Dakota",
                    key: "SD"
                },
                {
                    name: "Tennessee",
                    key: "TN"
                },
                {
                    name: "Texas",
                    key: "TX"
                },
                {
                    name: "Utah",
                    key: "UT"
                },
                {
                    name: "Vermont",
                    key: "VT"
                },
                {
                    name: "Virgin Islands",
                    key: "VI"
                },
                {
                    name: "Virginia",
                    key: "VA"
                },
                {
                    name: "Washington",
                    key: "WA"
                },
                {
                    name: "West Virginia",
                    key: "WV"
                },
                {
                    name: "Wisconsin",
                    key: "WI"
                },
                {
                    name: "Wyoming",
                    key: "WY"
                }
            
        ]);
      }, 0);
    });
  };
  