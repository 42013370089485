import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { BaseModal } from './../../../../../components/common/BaseModal/BaseModal';
import { readCommunityCode } from './../../../../../services/localStorage.service';//services/localStorage.service';
import { notificationController } from './../../../../../controllers/notificationController';//controllers/notificationController';
import CommunityList from './../../../../../components/CommunityList';
import { useAppSelector } from './../../../../../hooks/reduxHooks';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const [isMiddleModalOpen, setIsMiddleModalOpen] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user.user);

  console.log(JSON.stringify(user?.userTypecode));
  // console.log(JSON.stringify(user))

  const handleOk = async (status: any) => {
    const communityCode = readCommunityCode();

    if (communityCode && communityCode?.label) {
      setIsMiddleModalOpen(status);
    } else {
      notificationController.error({ message: 'Please Select Your Community' });
    }

  }



  return (
    <div {...props}>

      <S.Text>
        <Link to="/profile">{t('profile.title')}</Link>
      </S.Text>
      {(user?.userTypecode === 'vendor' || user?.userTypecode === 'admin') && (
        <><S.ItemsDivider />
          <S.Text>
            <Link to="/setting">{t('Setting')}</Link>
          </S.Text></>

      )}

      <S.ItemsDivider />
      <S.Text>
        <Link to="/logout">{t('header.logout')}</Link>
      </S.Text>


      <BaseModal
        title={'Please Select Your Community'}
        centered
        open={isMiddleModalOpen}
        onOk={() => handleOk(false)}
        onCancel={() => handleOk(false)}
        size="medium"
        style={{ background: '#FFF' }}

      >



        <CommunityList />



      </BaseModal>
    </div>
  );
};
