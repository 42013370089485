import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from './../../../../../../hooks/useResponsive';
import { useAppSelector, useAppDispatch } from '../../../../../../hooks/reduxHooks';
import * as S from './DiscountTable.styles';
import { ColumnsType } from 'antd/es/table';
import { BaseCard } from '../../../../../common/BaseCard/BaseCard';
import { BaseButtonsForm } from '../../../../../common/forms/BaseButtonsForm/BaseButtonsForm';
import { Button } from 'antd';
import { getDiscountList } from '../../../../../../api/serviceprovider.api';
import { notificationController } from '../../../../../../controllers/notificationController';
import { removeDiscountId } from '../../../../../../api/auth.api';
import { useNavigate } from 'react-router-dom';



//export const DiscountList: React.FC<DiscountListProps> = ({ activeItem, setActiveItem }) => {
  export const DiscountList: React.FC = () => {
  const user:any = useAppSelector((state) => state.user.user);
  const { isTablet, isDesktop } = useResponsive();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();



  useEffect(() => {
    getDiscountList(user.vendorcode).then((res) => {
      console.log('success', JSON.stringify(res));
      setData(res);
    });
  }, [user.vendorcode]);


  const onRemove = (id:any, vendorcode:any) => {
    
    const req = {
      id: id,
      vendorcode: vendorcode
    }
      setLoading(true);
      removeDiscountId(req)
      .then(() => {
        setLoading(false);
        notificationController.success({ message: t('Deleted') }); 
        window.location.href = '/setting/discount-list';      
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
    
  };

  const columns: ColumnsType<any> = [
   
    {
      title: t('Service Type'),
      dataIndex: 'servicetype',
      render: (isactive, { id,servicetype  }) => (
        <>
           { ''+servicetype?.toString().toUpperCase()+'('+id+')' }
        </>
      ),
      
    },  
    {
      title: t('Discount Value'),
      dataIndex: 'flag',
      render: (isactive, { id,discountvalue, discounttype  }) => (
        <>
           { (discounttype && discounttype === 'percent')? discountvalue+'%': '$'+discountvalue+''}
        </>
      ),
    },
    {
      title: t('Action'),
      dataIndex: 'flag',
      render: (action, { id,vendorcode , is_active }) => (
        <>
           <Button type="primary" onClick={(e) => onRemove(id, vendorcode)}>{
            'Remove'
           }</Button> 
        </>
      ),
    },
    
   

  ];

  return (
    <BaseCard>
     <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{t('Discount List')}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
    <S.Table
      size={'small'}
      pagination={false}
      columns={columns}
      dataSource={data}
      scroll={{ y: isDesktop ? 300 : isTablet ? 620 : 400 }}
      onRow={(record) => {
        return {
          onClick: () => {
            console.log(JSON.stringify(record))
          },
        };
      }} 
    />   
    </BaseCard>
  );
};