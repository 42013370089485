

//import 'typeface-montserrat';
//import 'typeface-lato';

import React from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';

import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
//import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';


const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  //usePWA();

  useAutoNightMode();

  useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deDe}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;






















/* import React from "react";
import { Home } from "./pages/home";
import {
  Routes,
  Route,

} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/header';
import VendorForm from "./pages/vendor";
import Footer from './components/guestfooter';
import Sidebar from './components/Sidebar'
const routeArr = ['/vForm', 'rForm'];

function App() {

  console.log('apath',window.location.pathname);
  var isLoggedIn = false;
  const path = window.location.pathname;
  if(routeArr.includes(path)) isLoggedIn = true;


  return (
    <>
      <CssBaseline />

      {isLoggedIn ? (
         <div />
      ) : (
        <>
        <Header />
        <Sidebar />
        </>
      )}


     
           
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="vForm" element={<VendorForm />} />
      </Routes>

      {isLoggedIn ? (
         <></>
      ) : (
        <Footer />
      )}


     
    </>
  );
}

export default App; */