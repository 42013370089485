export type CategoryType =  'House holds' | 'Health Care' | 'Sports' | 'Entertainment' | 'apps' | 'forms' | 'charts' | 'auth' | 'data tables' | 'maps' | 'Handy Man';



interface Category {
  name: CategoryType;
  title: string;
}

export const categoriesList: Category[] = [
  {
    name: 'House holds',
    title: 'common.households',
  },
  {
    name: 'Health Care',
    title: 'common.healthcare',
  },
  {
    name: 'Sports',
    title: 'common.sports',
  },
  {
    name: 'Entertainment',
    title: 'common.entertainment',
  }
];


export const categoriesListOld: Category[] = [
  {
    name: 'apps',
    title: 'common.apps',
  },
  {
    name: 'auth',
    title: 'common.auth',
  },
  {
    name: 'forms',
    title: 'common.forms',
  },
  {
    name: 'data tables',
    title: 'common.dataTables',
  },
  {
    name: 'charts',
    title: 'common.charts',
  },
  {
    name: 'maps',
    title: 'common.maps',
  },
  {
    name: 'Handy Man',
    title: 'common.handyman',
  },
];
