import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { getSideMenu } from './../../../../../api/sidemenu.api';
import { notificationController } from './../../../../../controllers/notificationController';

import SportsIcon from '@mui/icons-material/Sports';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import { ReactComponent as NftIcon } from './../../../../../assets/icons/nft-icon.svg';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import { BaseSpin } from './../../../../../components/common/BaseSpin/BaseSpin';
import  entertainment  from './../../../../../assets/icons/entertainment.png';
import  sports  from './../../../../../assets/icons/sports.png';
import  doctor  from './../../../../../assets/icons/doctor.jpg';
import  household  from './../../../../../assets/icons/household.jpg';
import  dashboard  from './../../../../../assets/icons/dashboard.jpg';
import  internet  from './../../../../../assets/icons/internet.jpg';
import  warranty  from './../../../../../assets/icons/warranty.png';
import  beautyspa  from './../../../../../assets/icons/beauty-spa.png';
import  privatelessons  from './../../../../../assets/icons/privatelessons.png';
import  events  from './../../../../../assets/icons/events.png';
import  insurance  from './../../../../../assets/icons/insurance.png';
import  legal  from './../../../../../assets/icons/legal.png';
import deals from './../../../../../assets/icons/deals.png';
import realestate from './../../../../../assets/icons/realestate.png';
import restaurant from './../../../../../assets/icons/restaurant.png';
import homedelivery from './../../../../../assets/icons/hdelivery.png';

import { readCommunityCode } from './../../../../../services/localStorage.service';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];
  const [isMiddleModalOpen, setIsMiddleModalOpen] = useState<boolean>(false);
  const communityCode = readCommunityCode();
   

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];


  const menuComponents:any = 
  {
    'nft-dashboard': <img src={dashboard} />  ,
    'households': <img src={household} /> ,
    'healthcare': <img src={doctor}   /> ,
    'sports': <img src={sports} />  ,
    'entertainment': <img src={entertainment} /> ,
    'internet': <img src={internet} /> ,
    'warranty': <img src={warranty} />,
    'beautyspa': <img src={beautyspa} />,
    'privatelessons':<img src={privatelessons} />,
    'events':<img src={events} />,
    'insurance':<img src={insurance} />,
    'legal':<img src={legal} />,
    'deals':<img src={deals} />,
    'realestate':<img src={realestate} />,
    'restaurants':<img src={restaurant} />,
    'homedelivery':<img src={homedelivery} />,
  };


  const [data, setData] = useState([]);
//getSideMenuByCommunityCode
  useEffect(() => {
    getSideMenu()
      .then((res) => setData(res))
      .catch((e) => notificationController.error({ message: e.message }));
      
  }, []);

 
  return isLoading ? <BaseSpin /> : (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={data.map((nav:any) => {
        const isSubMenu = nav.children?.length;

        return {
          key: nav.key,
          title: t(nav.title),
          label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
          icon: menuComponents[nav.key] || <NftIcon />,
          children:
            isSubMenu &&
            nav.children &&
            nav.children.map((childNav:any) => ({
              key: childNav.key,
              label: <Link to={childNav.url+'/'+childNav.key || ''}>{t(childNav.title)}</Link>,
              title: t(childNav.title),
            })),
        };
      })}
    />
  );
};

export default SiderMenu;
