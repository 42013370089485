import React, { useEffect, useMemo, useState, useRef,  } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from './../../components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from './../../hooks/reduxHooks';
import { doPromoCodeSave, doSignUp } from './../../store/slices/authSlice';
import { notificationController } from './../../controllers/notificationController';
import { ReactComponent as GoogleIcon } from './../../assets/icons/google.svg';
import { ReactComponent as FacebookIcon } from './../../assets/icons/facebook.svg';
import * as Auth from './../../components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './ProviderForm.styles';
import { CommunityModel } from '../../domain/CcommunityModel';
import { persistCommunityCode, readCommunityCode } from './../../services/localStorage.service';
import { BaseSelect, Option } from './../../components/common/selects/BaseSelect/BaseSelect';
import { languages } from './../../constants/languages';
import { BaseSpace } from './../../components/common/BaseSpace/BaseSpace';
import { createServiceProviderData } from '../../api/serviceprovider.api';
import { useAppSelector } from './../../hooks/reduxHooks';


interface VendorSignUpFormData {
  name: string;
  email: string;
  vendorcode: string;
  vendoremail: string;
  discounttype: string;
  servicetype: string;
  discountvalue: string;
  description: string;
  end_date: string;
    
}

const initValues = {
  name: '',
  email: '',
  vendorcode: '',
  vendoremail: '',
  discounttype: '',
  servicetype: '',
  discountvalue: '',
  description: '',
  end_date: ''
};

//export const ProviderForm: React.FC<any> = (props:any) => {
  export  const PromoForm = (props:any) => {

  const user:any = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();
  
  const commCode = readCommunityCode();
  const communityCode = commCode?.code || user?.community?.code;


const [data, setData] = useState([]);

  //const { setIsBusinessModalOpen } = props;

  const [inputValue, setInputValue] = React.useState('');
  const [codeValue, setCodeValue] = React.useState('');
  
  /* const  onTagsChange = (event:any, values:any) => {
    if(values === null){ 
       
    }else{
      
      setInputValue(values.code);
      setCodeValue(values.code)
      
    }

  } */

  const handleSubmit = (req: any) => {

    const reqData = {
      discountid : props.serviceDetail.discounts[0].id,
      vendorcode : props.serviceDetail.discounts[0].vendorcode,
      vendoremail : props.serviceDetail.discounts[0].vendoremail,
      discounttype : props.serviceDetail.discounts[0].discounttype,
      servicetype : props.serviceDetail.discounts[0].servicetype,
      discountvalue : props.serviceDetail.discounts[0].discountvalue,
      description : props.serviceDetail.discounts[0].description,
      end_date : props.serviceDetail.discounts[0].end_date,
      name: req.name,
      email: req.email
    }
   
    setLoading(true);
     dispatch(doPromoCodeSave(reqData))
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({ message: t('Promo Code sent to your email successfully') }); 
        props.handleProDetail(false);
        //navigate('/setting/discount-list')      
      })
      .catch((err) => {
        setLoading(false);
        props.handleProDetail(false);
        notificationController.error({ message: err.message });
        
      });
  };

 

  




  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit}  requiredMark="optional" initialValues={initValues}>
               

        <Auth.FormItem
          name="name"
          label={'Name'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Name'} />
        </Auth.FormItem>

       
        <Auth.FormItem
          name="email"
          label={'Email'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'Email'} />
        </Auth.FormItem>

     
        
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Send Promo Code')}
          </Auth.SubmitButton>
        </BaseForm.Item> 
       
       
        
      </BaseForm>
    </Auth.FormWrapper>
  );
};
