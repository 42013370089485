import React, { useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from './../../../hooks/reduxHooks';
import { doLogin } from './../../../store/slices/authSlice';
import { notificationController } from './../../../controllers/notificationController';
import { BaseForm } from './../../common/forms/BaseForm/BaseForm';
import { ReactComponent as FacebookIcon } from './../../../assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from './../../../assets/icons/google.svg';
import * as S from './LoginForm.styles';
import * as Auth from './../../layouts/AuthLayout/AuthLayout.styles';
import { Input } from 'antd';
import { persistClientIp } from '../../../services/localStorage.service';


interface LoginFormData {
  username: string;
  password: string;
}

export const initValues: LoginFormData = {
  username: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

//  const { urlLink } = useParams() || ''; 


  const location = useLocation();

  // Get a specific query parameter
  const urlLink = new URLSearchParams(location.search).get('urlLink');
  const signUpStr = urlLink ? ("/auth/sign-up?urlLink="+urlLink):("/auth/sign-up");
  const redirectUrl = urlLink ? (urlLink):("/");

 
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values: LoginFormData) => {
    setLoading(true);
    const response = await fetch("https://ipapi.co/json/")
    const data = await response.json();

    const reqPayload ={
      username: values.username.toString().toLowerCase(),
      password: values.password
    }
    dispatch(doLogin(reqPayload))
      .unwrap()
      .then(() => {
        persistClientIp(data);
        navigate(redirectUrl)
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  }; 

  return (

    
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={{
            remember: false,
            }} >
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        
        <Auth.FormItem
          name="username"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput autoComplete="off" placeholder={t('common.email')} />
          
        </Auth.FormItem>

        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>

        
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        {/* <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t('login.googleLink')}
          </Auth.SocialButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <FacebookIcon />
            </Auth.SocialIconWrapper>
            {t('login.facebookLink')}
          </Auth.SocialButton>
        </BaseForm.Item> */}
       
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('login.noAccount')}{' '}
            <Link to={signUpStr}>
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper> 
    
  );
};
