import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from './../../../../../../../components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { TwoFactorOptions } from './../../../../../../../components/profile/profileCard/profileFormNav/nav/SecuritySettings/twoFactorAuth/TwoFactorOptions/TwoFactorOptions';
import { TwoFactorSwitch } from './../../../../../../../components/profile/profileCard/profileFormNav/nav/SecuritySettings/twoFactorAuth/TwoFactorSwitch/TwoFactorSwitch';
import { BaseButton } from './../../../../../../../components/common/BaseButton/BaseButton';
import { SecurityCodeForm } from './../../../../../../../components/auth/SecurityCodeForm/SecurityCodeForm';
import { notificationController } from './../../../../../../../controllers/notificationController';
import { setUser } from './../../../../../../../store/slices/userSlice';
import { useAppDispatch, useAppSelector } from './../../../../../../../hooks/reduxHooks';
import { TwoFactorAuthOption } from './../../../../../../../interfaces/interfaces';
import * as S from './TwoFactorAuth.styles';

export interface CurrentOption {
  value: 'phone' | 'email';
  isVerified: boolean;
}

export type TwoFactorAuthOptionState = TwoFactorAuthOption | null;

export const TwoFactorAuth: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

 /*  const isNeedToShowVerifyBtn = useMemo(
    () => (user?.email.name && !user?.email.verified) || (user?.phone.number && !user?.phone.verified),
    [user],
  ); */

//  const [isFieldsChanged, setFieldsChanged] = useState(Boolean(isNeedToShowVerifyBtn));
  const [isLoading, setLoading] = useState(false);

 // const [isEnabled, setEnabled] = useState(Boolean(user?.email.verified || user?.phone.verified));
  const [selectedOption, setSelectedOption] = useState<TwoFactorAuthOptionState>('email');
  const [isClickedVerify, setClickedVerify] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onClickVerify = () => {
    setClickedVerify(true);
  };

  
  return (
    <>
      
    </>
  );
};
