import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  LoginRequest,
  signUp,
  guestLogin,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  discountSave,
  promoCodeSave,
} from './../../api/auth.api';
import { setUser } from './userSlice';
import { deleteToken, deleteUser, persistToken, readToken, persistCommunityCode, deleteCommunityCode } from './../../services/localStorage.service';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res:any) => {
    
    dispatch(setUser(res.user));
    persistToken(res.token);
    const communiCode = {
      code: res.user.communityCode,
      label:  res.user.communityCode
    }
    persistCommunityCode(communiCode);
    return res.token;
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);


export const doDiscountSave = createAsyncThunk('discounts/discountsave', async (signUpPayload: any) =>
   discountSave(signUpPayload),
);

export const doRemoveDiscount = createAsyncThunk('discounts/removeId', async (signUpPayload: any) =>
   discountSave(signUpPayload),
);

export const doPromoCodeSave = createAsyncThunk('promoCode/createPromoCode', async (promocodeReq: any) =>
   promoCodeSave(promocodeReq),
);

/* export const doGuestLogisn = createAsyncThunk('auth/doGuestLogin', async (signUpPayload: any) =>
   guestLogin(signUpPayload),
);
 */

export const doGuestLogin = createAsyncThunk('auth/doGuestLogin', async (loginPayload: any, { dispatch }) =>
guestLogin(loginPayload).then((res:any) => {
    
    dispatch(setUser(res.user));
    persistToken(res.token);
    const communiCode = {
      code: res.user.communityCode,
      label:  res.user.communityCode
    }
    persistCommunityCode(communiCode);
    return res.token;
  }),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  deleteCommunityCode();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
